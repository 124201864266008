import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import tokenStorage from "./store/middlewares/tokenStorage";
import headers from "./store/middlewares/headers";
import api from "./store/middlewares/api";
import toastMiddleware from "./store/middlewares/toastMiddleware";

import authReducer from "./store/reducers/authReducer";
import dashboardReducer from "./store/reducers/dashboardReducer";
import userManagementReducer from "./store/reducers/userManagement";
import layoutReducer from "./store/reducers/layoutReducer";
import themeConfigurationReducer from "./store/reducers/themeConfiguration";
import messageReducer from "./store/reducers/messageReducer";
import dataSettingsReducer from "./store/reducers/dataSettings/dataSettingsReducer";
import fileManagementReducer from "./store/reducers/fileManagement";
import attributesReducer from "./store/reducers/dataSettings/attributesReducer";
import dimensionsReducer from "./store/reducers/dataSettings/dimensionsReducer";
import siteConfigurationReducer from "./store/reducers/siteConfiguration";
import pageManagement from "./store/reducers/dashboardSetup/pageManagement";
import userNotifications from "./store/reducers/userNotifications";
import connectionsReducer from "./store/reducers/dataSettings/connectionsReducer";
import chartsReducer from "./store/reducers/dashboardSetup/chartsReducer";
import etlConfigReducer from "./store/reducers/dataSettings/etlConfigReducer";
import queryExecSettingsReducer from "./store/reducers/queryExecSettings";
import insightsReducer from "./store/reducers/insightsReducer";
import dataSetupReducer from "./store/reducers/dataSetup/dataSetupReducer";
import monitoringReducer from "./store/reducers/monitoringReducer";
import dataDictionaryReducer from "./store/reducers/dataDictionaryReducer";
import { useSelector, shallowEqual } from "react-redux";
import headerKpisReducer from "./store/reducers/headerKpisReducer";
import watchlistReducer from "./store/reducers/watchlistReducer";
import geoMapReducer from "./store/reducers/geoMapReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  dashboard: dashboardReducer,
  themeConfiguration: themeConfigurationReducer,
  userManagement: userManagementReducer,
  dataSettings: dataSettingsReducer,
  fileManagement: fileManagementReducer,
  siteConfiguration: siteConfigurationReducer,
  message: messageReducer,
  attributes: attributesReducer,
  dimensions: dimensionsReducer,
  userNotifications: userNotifications,
  pageManagement: pageManagement,
  connections: connectionsReducer,
  charts: chartsReducer,
  queryExecSettings: queryExecSettingsReducer,
  etlConfigs: etlConfigReducer,
  insights: insightsReducer,
  dataSetup: dataSetupReducer,
  monitoring: monitoringReducer,
  dataDictionary: dataDictionaryReducer,
  headerKpis: headerKpisReducer,
  watchlist: watchlistReducer,
  geoMap: geoMapReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * For when returning an object in the selector callback to save on rerenders.
 */
export function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, tokenStorage, headers, api, toastMiddleware)
  )
);
