import * as actionTypes from "../../actions/actionTypes";
import produce from "immer";

const initialState = {
  list: [],
  loading: false,
  tables: [],
  error: {
    dbName: null,
  },
  dataSources: [],
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONNECTIONS_START:
    case actionTypes.DELETE_CONNECTION_START:
    case actionTypes.CREATE_CONNECTION_START:
    case actionTypes.UPDATE_CONNECTION_START:
    case actionTypes.CREATE_CONNECTION_OVERRIDE_START:
    case actionTypes.UPDATE_CONNECTION_OVERRIDE_START:
    case actionTypes.DELETE_CONNECTION_OVERRIDE_START:
    case actionTypes.GET_CONNECTION_TABLES_START:
      draft.loading = true;
      break;

    case actionTypes.GET_CONNECTIONS_SUCCESS:
      draft.list = action.connections.data;
      draft.loading = false;
      break;

    case actionTypes.GET_CONNECTIONS_FAIL:
    case actionTypes.DELETE_CONNECTION_FAIL:
    case actionTypes.CREATE_CONNECTION_FAIL:
    case actionTypes.UPDATE_CONNECTION_FAIL:
    case actionTypes.CREATE_CONNECTION_OVERRIDE_FAIL:
    case actionTypes.UPDATE_CONNECTION_OVERRIDE_FAIL:
    case actionTypes.DELETE_CONNECTION_OVERRIDE_FAIL:
      draft.loading = false;
      break;

    case actionTypes.DELETE_CONNECTION_SUCCESS:
      draft.list = draft.list.filter((c) => c.uuid !== action.connection.uuid);
      draft.loading = false;
      break;

    case actionTypes.CREATE_CONNECTION_SUCCESS:
      const newList = draft.list;
      newList.push(action.connection.data);
      draft.list = newList;
      draft.loading = false;
      break;

    case actionTypes.UPDATE_CONNECTION_SUCCESS:
      draft.list = draft.list.map((l) =>
        l.uuid === action.connection.data.uuid
          ? { ...l, ...action.connection.data }
          : l
      );
      draft.loading = false;
      break;

    case actionTypes.CREATE_CONNECTION_OVERRIDE_SUCCESS:
      draft.list = draft.list.map((l) => {
        if (l.uuid === action.override.data.connectionUuid) {
          const newOverrides = [...l.overrides];
          newOverrides.push(action.override.data);
          return { ...l, overrides: newOverrides };
        }

        return l;
      });
      draft.loading = false;
      break;

    case actionTypes.UPDATE_CONNECTION_OVERRIDE_SUCCESS:
      for (const l of draft.list) {
        if (!l.overrides) {
          continue;
        }
        for (const [key, override] of Object.entries(l.overrides)) {
          if (override.uuid === action.override.data.uuid) {
            l.overrides[key] = { ...override, ...action.override.data };
          }
        }
      }
      draft.loading = false;
      break;

    case actionTypes.DELETE_CONNECTION_OVERRIDE_SUCCESS:
      draft.list = draft.list.map((l) => ({
        ...l,
        overrides: l.overrides.filter(
          (override) => override.uuid !== action.override.uuid
        ),
      }));
      draft.loading = false;
      break;

    case actionTypes.GET_CONNECTION_TABLES_SUCCESS:
      draft.tables = action.tables.data;
      draft.loading = false;
      draft.error.dbName = null;
      break;

    case actionTypes.GET_CONNECTION_TABLES_RESET:
      draft.tables = [];
      draft.error.dbName = null;
      break;

    case actionTypes.GET_CONNECTION_TABLES_FAIL:
      draft.tables = [];
      draft.loading = false;
      draft.error.dbName = action.error;
      break;

    case actionTypes.GET_ALL_DATA_SOURCES_SUCCESS:
      draft.dataSources = action.results.data;
      break;

    default:
      return draft;
  }
});
