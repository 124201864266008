import Flex from "../../../../../../UI/Flex/Flex";
import Select from "../../../../../../UI/Form/Select/Select";
import ActionButton from "../../../../../../UI/Form/ActionButton/ActionButton";
import { useMemo } from "react";
import { getAggregationPrefix } from "../RegularColumn";
import { debounce, omit } from "lodash-es";
import ValueColorPair from "./ValueColorPair";

export default function ColorsBasedOnDependencyColumn(props) {
  const {
    theme,
    fields,
    selectedColumn,
    setChartState,
    chart,
    useForColumn,
    previewChart,
  } = props;

  const { overrides, isParameterized, enumColorsSettings } = chart;
  const columns = isParameterized ? fields : overrides;

  const { conditionalColumn, values = [] } =
    enumColorsSettings.find(
      (setting) => setting.column === selectedColumn.column
    ) ?? {};

  // column data options
  const dynamicValueOptions = useMemo(
    () =>
      previewChart?.data.map((item) => ({
        label: item[conditionalColumn],
        value: item[conditionalColumn],
      })),
    [conditionalColumn, previewChart?.data]
  );

  const changeDependencyColumn = (option) => {
    const aggregationPrefix = getAggregationPrefix(option?.aggregation);

    setChartState({
      ...chart,
      enumColorsSettings: enumColorsSettings.map((setting) => {
        if (setting.column === selectedColumn.column) {
          const temp = !option
            ? omit(setting, ["conditionalColumn", "values"])
            : {
                ...setting,
                conditionalColumn: aggregationPrefix + option?.name,
              };
          return temp;
        }
        return setting;
      }),
    });
  };

  const addNewValueColorPair = () => {
    setChartState({
      ...chart,
      enumColorsSettings: enumColorsSettings.map((setting) =>
        setting.column === selectedColumn.column
          ? {
              ...setting,
              values: [
                ...(setting.values || []),
                { value: "", color: "", background: "" },
              ],
            }
          : setting
      ),
    });
  };

  const changeDynamicColorOrValue = useMemo(
    () =>
      debounce((value, index, key) => {
        setChartState({
          ...chart,
          enumColorsSettings: enumColorsSettings.map((setting) =>
            setting.column === selectedColumn.column
              ? {
                  ...setting,
                  values: setting.values.map((item, i) =>
                    i === index ? { ...item, [key]: value } : item
                  ),
                }
              : setting
          ),
        });
      }, 500),
    [chart, enumColorsSettings, selectedColumn.column, setChartState]
  );

  const removeDynamicValue = (index) => {
    setChartState({
      ...chart,
      enumColorsSettings: enumColorsSettings.map((setting) =>
        setting.column === selectedColumn.column
          ? {
              ...setting,
              values: setting.values.filter((_, i) => i !== index),
            }
          : setting
      ),
    });
  };

  if (useForColumn) {
    return null;
  }

  return (
    <>
      <hr color={theme.background.secondary} style={{ margin: "10px 0px" }} />
      <Flex
        alignItems="center"
        mt2
        mb2
        title={
          conditionalColumn
            ? "Add More Values"
            : "Select Dependency Column first"
        }
      >
        <span>Colors Based on dependency Column</span>
        <ActionButton
          icon={["fal", "plus-circle"]}
          style={{ marginLeft: 10 }}
          onClick={addNewValueColorPair}
          disabled={!conditionalColumn}
        />
      </Flex>
      <br />
      <div>
        <Select
          label="Dependency column"
          options={columns}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          isClearable
          value={columns.find((field) => field.name === conditionalColumn)}
          onChange={changeDependencyColumn}
        />
        <br />

        {(values || []).map((item, index) => (
          <ValueColorPair
            options={dynamicValueOptions}
            item={item}
            index={index}
            key={item.value}
            selectKey="value"
            inputKey="color"
            onInputChange={changeDynamicColorOrValue}
            onSelectChange={changeDynamicColorOrValue}
            onRemove={removeDynamicValue}
          />
        ))}
      </div>
    </>
  );
}
