import React from "react";
import PropTypes from "prop-types";
import formatter from "../../utils/formatters/formatter";
import styled from "@emotion/styled";

const Label = styled.td`
  font-size: 12px;
  text-align: left;
  padding-bottom: 6px;
`;

export default function ChartTip(props) {
  const { config, tooltip, data, metaFields } = props;

  if (!config) return null;
  let rows;
  if (tooltip.sourceData) {
    // pie version
    rows = Object.entries(config).map((e) => ({
      label: e[1].label,
      value: tooltip.sourceData[e[0]],
      displayValue: formatter(tooltip.sourceData[e[0]], e[1].format),
    }));
  } else if (config.advanced) {
    const { selectedRow } = tooltip;
    if (!selectedRow) {
      return [];
    }

    const formatType = (r) => {
      const metaMatch = metaFields.find((f) => f.name === r.key);
      return r.format || metaMatch?.type;
    };
    const valueKey = (r) => {
      const metaMatch = metaFields.find((f) => f.name === r.key);
      return metaMatch.alias || metaMatch.name;
    };

    const display = (r) => {
      const format = formatType(r);
      const value = selectedRow[valueKey(r)];
      return formatter(value, format);
    };

    rows = config.tooltipRows.map((r) => {
      return {
        label: r.label,
        value: selectedRow[valueKey(r)],
        displayValue: display(r),
      };
    });
  } else {
    // Stacked bars atm

    rows = Object.entries(config)
      .filter((e) => e[1].label)
      .filter((e) => e[0] === tooltip.key) // what is this for?
      .map((e, i) => ({
        label: e[1].label,
        value: data[e[0]],
        displayValue: formatter(data[e[0]], e[1].format),
      }));
  }

  return (
    <div>
      <table>
        <tbody>
          {tooltip.piePercent ? (
            <tr>
              <Label data-cy="chart-tip-label">
                Percent of total
                <br /> <b>{data.tooltip[0].value || "--"}</b>
              </Label>
            </tr>
          ) : null}
          {rows.map(({ label, value, displayValue }) => (
            <tr key={label}>
              <Label data-cy="chart-tip-label">
                {label}
                <br /> <b>{displayValue || value || "--"}</b>
              </Label>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ChartTip.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object,
};
