import produce from "immer";
import { groupBy, keyBy, maxBy, remove } from "lodash-es";
import {
  AGGREGATION_SUM,
  aggregations,
  dateAggregationValues,
} from "../../../../../utils/constants/constants";

export function convertDraftSettingsToEffectiveSettings(draftSettings) {
  if (
    !draftSettings.filters?.length ||
    !draftSettings.overrides?.fields?.length
  ) {
    return draftSettings;
  }

  return produce(draftSettings, (draft) => {
    /*
     * Convert any filters that reference any non-date aggregated fields to use "havings" instead.
     */

    const aggregatedFieldsByName = groupBy(
      draft.overrides.fields.filter(
        (v) =>
          v.aggregation && !dateAggregationValues.includes(v.aggregation.type)
      ),
      "name"
    );

    remove(draft.filters, (filter) => {
      const aggregatedFields = aggregatedFieldsByName[filter.name];
      if (!aggregatedFields) {
        return false;
      }
      const aggregatedField = maxBy(aggregatedFields, (field) =>
        // Prefer one with the SUM type.
        field.aggregation.type === AGGREGATION_SUM ? 1 : 0
      );
      const newHaving = { ...filter, name: getFieldKey(aggregatedField) };
      draft.havings.push(newHaving);
      return true;
    });
  });
}

export function convertEffectiveSettingsToDraftSettings(effectiveSettings) {
  const nextFilters = patchRangeOperator();
  const orders = patchOrder();
  const nextSettings = { ...effectiveSettings, filters: nextFilters };
  if (orders) {
    nextSettings.orders = orders;
  }

  if (
    !effectiveSettings?.havings?.length ||
    !effectiveSettings.overrides?.fields?.length
  ) {
    return nextSettings;
  }

  return produce(nextSettings, (draft) => {
    const aggregatedFieldByAlias = keyBy(
      draft.overrides.fields.filter(
        (v) =>
          v.aggregation && !dateAggregationValues.includes(v.aggregation.type)
      ),
      (v) => getFieldKey(v)
    );

    remove(draft.havings, (having) => {
      const aggregatedField = aggregatedFieldByAlias[having.name];
      if (!aggregatedField) {
        return false;
      }

      const newFilter = { ...having, name: aggregatedField.name };
      if (!draft.filters) {
        draft.filters = [];
      }
      draft.filters.push(newFilter);
      return true;
    });
  });

  function patchOrder() {
    if (!effectiveSettings.order) return null;
    const orderKeys = Object.keys(effectiveSettings.order);
    const orders = orderKeys.map((k) => ({
      name: k,
      sort: effectiveSettings.order[k],
    }));
    delete nextFilters.order;
    return orders;
  }
  function patchRangeOperator() {
    return effectiveSettings.filters
      ? effectiveSettings.filters.map((f) =>
          isDateRange(f) ? { ...f, operator: "range" } : f
        )
      : [];
    function isDateRange(f) {
      return f.values && !f.operator && f.values.length === 2;
    }
  }
}

function getFieldKey(field) {
  if (!field.aggregation?.type) {
    return field.name;
  }

  const aggregation = aggregations.find(
    (v) => v.value === field.aggregation.type
  );

  return aggregation.prefix + field.name;
}
