import styled from "@emotion/styled";
import { Span } from "./ExpandedRowVisualization";

const ExpandAllContainer = styled.div(
  ({ disabled }) => `
  position: relative;
  z-index: 1;
  span {
    position: absolute;
    top: 8px;
  }

  ${
    disabled &&
    `
      pointer-events: none;
      opacity: 0.4;
    `
  };
`
);

export default function ExpandAll(props) {
  const {
    tableRows,
    rowIndexes,
    hasRowExpand,
    onRowClick,
    setRowIndexes,
    totalsPositionTop,
    totals,
    isLoading,
    isDrillable,
  } = props;

  const rowsCount = tableRows.length;
  const expandedRowsCount = (rowIndexes ?? []).length;
  const needCloseRows = expandedRowsCount && expandedRowsCount !== rowsCount;
  const needCloseAllRows = expandedRowsCount && expandedRowsCount === rowsCount;
  const closeLabel = needCloseAllRows || needCloseRows;

  let disabled = false;

  if (!hasRowExpand || isLoading) {
    return null;
  }

  // we have drillable property defined on BE side (not all rows are drillable)
  function getIsDrillable(row) {
    return isDrillable
      ? (row.values ?? []).find((val) => val[isDrillable])
      : true;
  }

  function expandAllRows() {
    let counter = 0;

    const intervalTime = 200;

    if (needCloseRows) {
      return setRowIndexes([]);
    }

    // Set up an interval to expand rows at a regular time interval
    const id = setInterval(sendDataToExpanRowFunction, intervalTime);

    // Function to expand a single row and handle the logic
    function sendDataToExpanRowFunction() {
      const row = tableRows[counter];
      const position = totalsPositionTop ? 0 : rowsCount - 1;
      const isTotal = totals && counter === position;
      const canOpen = getIsDrillable(row);

      if (!isTotal && canOpen) {
        disabled = true; // to prevent multiple clicks while rows data is loading
        onRowClick(row, counter);
      }

      counter++;

      if (counter === rowsCount) {
        disabled = false;
        clearInterval(id);
      }
    }
  }

  return (
    <ExpandAllContainer disabled={disabled}>
      <Span onClick={expandAllRows} className="expand-all">
        {closeLabel ? "Close " : "Expand "}All
      </Span>
    </ExpandAllContainer>
  );
}
