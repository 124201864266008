import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import { debounce } from "lodash-es";
import { castToNumber } from "../../utils/func";
import SliderValues from "./SliderValues";
import Slider from "./Slider";

const Container = styled.div`
  padding: 10px;
  margin: 0 5px 0 10px;
  position: relative;
`;

const inputThumb = `
  border: none;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
`;

const InputRange = styled.input(
  ({ theme, left }) => `  
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  pointer-events: none;
  position: absolute;
  height: 0;
  width: 98%;
  outline: none;
  left: 0;

  z-index: ${left ? 3 : 4};

  &::-webkit-slider-thumb {
    background: ${theme.primary};
    ${inputThumb}
  }
  &:hover::-webkit-slider-thumb {
    background: ${lightenDarkenColor(theme.primary, -30)};
  }

  &:hover::-moz-range-thumb {
    background: ${lightenDarkenColor(theme.primary, -30)};
  }

  &::-moz-range-thumb {
    background: ${theme.primary};
    ${inputThumb}
  }
`
);

export default function MultiRangeSlider({
  onChange,
  multiRangeFilters,
  // type,
  format,
  min,
  max,
}) {
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);

  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const rangeRef = useRef(null);

  // width of range in percent to show active range colored
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // send a request
  const debouncedChange = useMemo(
    () =>
      debounce((min, max, value) => {
        onChange(min, max, value);
      }, 1000),
    [onChange]
  );

  // range config for min value (css thing)
  useEffect(() => {
    const minPercent = getPercent(minValue);
    const maxPercent = getPercent(maxValRef.current);

    if (rangeRef.current) {
      rangeRef.current.style.left = `${minPercent}%`;
      rangeRef.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minValue, getPercent]);

  // range config for max value (css thing)
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxValue);

    if (rangeRef.current) {
      rangeRef.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxValue, getPercent]);

  const handleRangeChange = (minValue, maxValue) => {
    if (!isNaN(minValue) && !isNaN(maxValue)) {
      debouncedChange(minValue, maxValue);
    }
  };

  // reset the range filter to default values
  useEffect(() => {
    if (!multiRangeFilters) {
      setMinValue(min);
      setMaxValue(max);
      const minPercent = getPercent(min);
      const maxPercent = getPercent(max);

      if (rangeRef.current) {
        rangeRef.current.style.left = `${minPercent}%`;
        rangeRef.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [getPercent, max, min, multiRangeFilters]);

  return (
    <Container>
      <InputRange
        type="range"
        min={min}
        max={max}
        left={true}
        value={minValue}
        onChange={(e) => {
          const value = Math.min(castToNumber(e.target.value), maxValue - 1);
          setMinValue(value);
          minValRef.current = value;
          handleRangeChange(value, maxValue);
        }}
        style={{ zIndex: minValue > maxValue - 2 && 5 }}
      />
      <InputRange
        type="range"
        min={min}
        max={max}
        value={maxValue}
        onChange={(e) => {
          const value = Math.max(castToNumber(e.target.value), minValue + 1);
          setMaxValue(value);
          maxValRef.current = value;
          handleRangeChange(minValue, value);
        }}
      />
      <Slider rangeRef={rangeRef} />
      <SliderValues minValue={minValue} maxValue={maxValue} format={format} />
    </Container>
  );
}

MultiRangeSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
};

MultiRangeSlider.defaultValues = {
  filter: { values: {} },
};
