import styled from "@emotion/styled";
import Switcher from "../../../../../UI/Switcher/Switcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldWrapper } from "../GlobalSettings";
import Input from "../../../../../UI/Form/Input/Input";
import Checkbox from "../../../../../UI/Form/Checkbox/Checkbox";

const DynamicRowsContainer = styled.div`
  margin: 25px 0px;
`;

const Italic = styled.span(
  ({ theme }) => `
  font-style: italic;
  color: ${theme.primary};
`
);

export default function DisplayRows({ chart, setChartState, Note }) {
  const { displayRows, connectWithQueryParameter } = chart;

  const enableDisableDisplayRowsLimit = () => {
    setChartState({
      ...chart,
      displayRows: displayRows || displayRows === "" ? null : 20,
    });
  };

  const setDisplayRowsLimit = (e) => {
    const value = e.target.value.trim();
    if (Number.isSafeInteger(+value)) {
      setChartState({ ...chart, displayRows: value });
    }
  };

  const enableDisableConnectWithQueryParameter = () => {
    setChartState({
      ...chart,
      connectWithQueryParameter: !connectWithQueryParameter,
    });
  };

  const hasDisplayedRows = displayRows || displayRows === "";

  return (
    <>
      <DynamicRowsContainer>
        <Switcher
          rightLabel="Display Rows Limit"
          checked={!!displayRows || displayRows === ""}
          handleSwitchChange={enableDisableDisplayRowsLimit}
          containerStyle={{ marginBottom: 20 }}
        />
        {hasDisplayedRows && (
          <FieldWrapper>
            <Input
              value={displayRows ?? ""}
              onChange={setDisplayRowsLimit}
              label="Visibale Rows"
              pattern="[0-9]{10}"
            />
          </FieldWrapper>
        )}
        <Note color="infoDark">
          Change number to manually control the quantity of displayed rows
          <FontAwesomeIcon icon={["fas", "info-circle"]} />
        </Note>
      </DynamicRowsContainer>

      {hasDisplayedRows && (
        <>
          <Checkbox
            label="Connect with Query parameter"
            checked={connectWithQueryParameter}
            onChange={enableDisableConnectWithQueryParameter}
          />

          {connectWithQueryParameter && (
            <Note color="warningDark">
              Parameterized Query should accept <Italic>perPage</Italic> and{" "}
              <Italic>page</Italic> Parameters
              <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
            </Note>
          )}
        </>
      )}
    </>
  );
}
