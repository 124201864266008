import { isNumber } from "lodash-es";

// non strict compare filter value
// function partialNoIndexEqual(rowIndexes, keyValue) {
//   return rowIndexes?.find((item) => (item || "").includes(keyValue));
// }

// non strict compare filter value + row index
// function partialEqual(rowIndexes, keyValue, tableRowIndex) {
//   const partialExistWithRowIndex = rowIndexes?.find((item) =>
//     (item || "").includes(keyValue + tableRowIndex)
//   );

//   return partialExistWithRowIndex ?? partialNoIndexEqual(rowIndexes, keyValue);
// }

// strict compare filter value + row index
export function rowIndexStrictComparison(rowIndexes, keyValue, tableRowIndex) {
  const number = rowIndexes?.every((rowIndex) => isNumber(rowIndex));

  const strictExist = rowIndexes?.find(
    (item) => item === keyValue + (number ? 0 : tableRowIndex) // do not add index to number because you will got new number
  );

  return strictExist;
}
