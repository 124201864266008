import React, { useEffect, useState, useMemo, useCallback } from "react";
import RangeArrow from "../RangeArrow";
import { dateIsAfter } from "./explorerDateUtils";
import ExplorerDateInputField from "./ExplorerDateInputField";
import DateInputWrapper from "./DateInputWrapper";

export default function ExplorerDateInput(props) {
  const { sampleResults, operator, handleAddValue, values } = props;
  const [isFocus, setIsFocus] = useState(false);
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const [validationError, setValidationError] = useState(false);

  const handleDateRangeEntries = useCallback(
    (first, second) => {
      if (first && second) {
        if (dateIsAfter(second, first)) {
          setValidationError(false);
          handleAddValue([first, second]);
        } else {
          setValidationError(true);
        }
      }
    },
    [handleAddValue]
  );

  const applyChange = useCallback(
    (first, second) => {
      if (operator !== "range") {
        handleAddValue([first]);
      } else {
        handleDateRangeEntries(first, second);
      }
    },
    [handleAddValue, handleDateRangeEntries, operator]
  );

  const handleChangeValue = useCallback(
    (e) => {
      setFirstValue(e.target.value);
      applyChange(e.target.value, secondValue);
    },
    [applyChange, secondValue]
  );

  const handleChangeSecondValue = useCallback(
    (e) => {
      setSecondValue(e.target.value);
      applyChange(firstValue, e.target.value);
    },
    [applyChange, firstValue]
  );

  const deps = useMemo(() => ({ applyChange, values }), [applyChange, values]);
  useEffect(() => {
    if (operator !== "range" && deps.values[1]) {
      setSecondValue("");
      deps.applyChange(deps.values[0]);
    }
  }, [operator, deps]);

  const inputConfigs = useMemo(
    () => ({
      first: {
        value: values[0],
        localValue: firstValue,
        set: setFirstValue,
        update: handleChangeValue,
      },
      second: {
        value: values[1],
        localValue: secondValue,
        set: setSecondValue,
        update: handleChangeSecondValue,
      },
    }),
    [
      firstValue,
      handleChangeSecondValue,
      handleChangeValue,
      secondValue,
      values,
    ]
  );

  useEffect(() => {
    setValueFromLoad("first");
    setValueFromLoad("second");

    function setValueFromLoad(position) {
      const { value, localValue, set } = inputConfigs[position];
      if (!localValue && value) {
        set(value);
      }
    }
  }, [inputConfigs]);

  const dateInput = (position) => (
    <ExplorerDateInputField
      value={inputConfigs[position].localValue}
      onChange={inputConfigs[position].update}
      setIsFocus={setIsFocus}
      validationError={validationError}
    />
  );

  const isRangeType = operator === "range";

  return (
    <DateInputWrapper
      operator={operator}
      sampleResults={sampleResults}
      isFocus={isFocus}
      isRangeType={isRangeType}
    >
      {operator ? dateInput("first") : null}
      {isRangeType ? (
        <>
          <RangeArrow />
          {dateInput("second")}
        </>
      ) : null}
    </DateInputWrapper>
  );
}
