import { scaleLinear } from "d3-scale";
import { unique } from "../../../utils/func";
import tableTotal, { clearIfSameKeys, getSubTotalRow } from "../tableTotal";
import { hexToRgba } from "../../../styles/colorConvertor";
import { groupBy } from "lodash-es";
import { setFiscalQuearterOffset } from "../../../utils/formatters/formatter";
import { getMappingType } from "../../../utils/getVisualizationLabel";
import { rightAligns } from "../../../utils/constants/constants";

export function getPinnedRows(dataRow, pinnedRows = []) {
  return pinnedRows.find(
    (pin) => dataRow[pin.column] && dataRow[pin.column] === pin.value
  );
}

export default function tableMapper(
  aTableCollection,
  rowMainKey,
  groupingKey,
  uniqueValueKey,
  valueKeyAsSubTitle, // suppresses header column duplicate render
  totals,
  totalsAverages,
  pinnedRows,
  groupingDataConfig,
  subTotalRow,
  totalsPositionTop,
  totalsCharts,
  groupingExtraColumns,
  rawRowsValueKey,
  totalColumnsStatic,
  excludeRowFromTotalCalculation,
  totalsOverride,
  progressBarsSettings,
  noCalcKeys,
  rowGroupKeys,
  totalLabel
) {
  const data = aTableCollection.data;

  const result = {
    headers: aTableCollection.headers,
    rows: [],
    totals: null,
  };

  function getUnPinnedRows(dataRow) {
    return !pinnedRows.find(
      (pin) => dataRow[pin.column] && dataRow[pin.column] === pin.value
    );
  }

  const pinned = pinnedRows
    ? data.filter((dataRow) => getPinnedRows(dataRow, pinnedRows))
    : null;

  const useRawValues = !rowMainKey && !uniqueValueKey;

  let groupedData = data;

  if (groupingDataConfig && data.length > 0) {
    const { key, groups, includeOthers, exclude } = groupingDataConfig;
    const includeConditions = groups.map((group) => group.include).flat();
    const others = includeOthers
      ? data.filter(
          (d) =>
            !includeConditions.filter((ic) => ic !== exclude).includes(d[key])
        )
      : [];

    groupedData = others;
    groups.forEach((group) => {
      const { position, label, include } = group;
      const range = data.filter((d) => include.includes(d[key]));
      const grouped = tableTotal(
        range,
        rowMainKey,
        totalsAverages,
        label,
        null,
        totalColumnsStatic,
        undefined,
        totalsOverride,
        noCalcKeys
      );

      if (range.length === 0) {
        return;
      }

      if (position === "top") {
        groupedData.unshift(grouped);
      } else {
        groupedData.push(grouped);
      }
    });
  }

  if (subTotalRow) {
    const { groupingKey, getKey, setKey } = subTotalRow;
    const groups = groupBy(groupedData, groupingKey);

    groupedData = Object.values(groups)
      .map((group) => getSubTotalRow(group, getKey, setKey, groupingKey))
      .map((group) => clearIfSameKeys(group, getKey, setKey, groupingKey))
      .flat();
  }

  const mainColumnValues = unique(
    groupedData
      .filter((d) => (pinnedRows ? getUnPinnedRows(d) : true))
      .map((d) => d[uniqueValueKey || rowMainKey])
      .filter(Boolean)
  );

  function setRowKeyValue(row, item) {
    if (valueKeyAsSubTitle) {
      return {};
    }

    return {
      [rowMainKey]: row[0][rowMainKey],
      ...(rowGroupKeys || []).reduce((acc, curr) => {
        acc[curr] = row[0][curr];
        return acc;
      }, {}),
    };
  }

  mainColumnValues.filter(Boolean).forEach((item, i) => {
    const row = groupedData.filter(
      (d) => d[uniqueValueKey || rowMainKey] === item
    );

    const rowKeyValue = setRowKeyValue(row, item);

    result.rows[i] = {
      ...rowKeyValue,
      values: row,
    };

    if (groupingKey) {
      setGroupingKeyStyleValues(
        groupedData,
        result,
        groupingKey,
        i,
        groupingExtraColumns
      );
    }
  });

  if (useRawValues) {
    result.rows = groupedData
      .filter((d) => (pinnedRows ? getUnPinnedRows(d) : true))
      .map((d) => ({ values: [d] }));
  }

  if (pinnedRows && pinned.length) {
    const keepOrdered =
      pinnedRows[0].position === "top"
        ? pinnedRows.slice().reverse()
        : pinnedRows;

    keepOrdered.forEach((pinnedRow) => {
      const { position } = pinnedRow;

      if (pinnedRow.label) {
        const index = pinned.findIndex((pin) => pin.label === pinnedRow.label);
        pinned[index][pinnedRow.column] = pinnedRow.label;
      }

      function sortPinned() {
        const uniqueGroupingKeys = unique(data.map((d) => d[groupingKey]));
        return uniqueGroupingKeys.reduce((acc, curr) => {
          const pin = pinned.find((p) => p[groupingKey] === curr);
          acc.push(pin);
          return acc;
        }, []);
      }

      // we need to keep order of pinned row because when we cut it from original data we loose the order
      // order is based on headers (uniqueGroupingKeys) see above
      const orderedPinned = groupingKey
        ? sortPinned()
        : pinned.filter((pin) => pin[pinnedRow.column] === pinnedRow.value);

      if (!orderedPinned.length) {
        return;
      }

      if (position === "bottom") {
        result.rows.push({
          values: orderedPinned,
        });
      } else {
        result.rows.unshift({
          values: orderedPinned,
        });
      }
    });
  }

  // calculate totals for regular tables
  function setRegularTotals() {
    const withSkipOnTotals = groupingDataConfig?.skipOnTotals
      ? data
      : groupedData;

    const totalRow = getTableTotal(withSkipOnTotals);

    return [totalRow];
  }

  // calculate totals for static/dynamic groupings
  function setGroupingTotals() {
    if (!groupedData.length) {
      return [];
    }

    const uniqueKeys = [
      ...new Set(groupedData.map((item) => item[groupingKey])),
    ];

    return uniqueKeys.map((key) => {
      const sectionData = groupedData.filter(
        (item) => item[groupingKey] === key
      );

      const total = getTableTotal(sectionData);

      return { ...total, [groupingKey]: key };
    });
  }
  if (totals) {
    const totals = groupingKey ? setGroupingTotals() : setRegularTotals();

    const total = {
      values: totals,
    };

    // this is where totals come from
    if (totalsPositionTop) {
      result.rows.unshift(total);
    } else {
      result.rows.push(total);
    }

    // for some tables we need to use total value to get correct percentage of progress bars
    const { calculateFromTotals } = progressBarsSettings ?? {};
    if (calculateFromTotals) {
      result.totals = totals[0];
    }
  }

  aTableCollection.setRows(result.rows);
  aTableCollection.setProgressBarsTotals(result.totals);

  function getTableTotal(data) {
    return tableTotal(
      data,
      uniqueValueKey || rowMainKey || rawRowsValueKey,
      totalsAverages,
      totalLabel,
      totalsCharts,
      totalColumnsStatic,
      excludeRowFromTotalCalculation,
      totalsOverride,
      noCalcKeys
    );
  }
}

function setGroupingKeyStyleValues(
  data,
  result,
  groupingKey,
  i,
  groupingExtraColumns
) {
  const uniqueGroupingKeys = unique(data.map((d) => d[groupingKey]));
  const tempValues = [...result.rows[i].values];

  uniqueGroupingKeys.forEach((key, j) => {
    const exists = valueExists();
    const notExists = valueNotExists();
    result.rows[i].values[j] = exists || { ...notExists, [groupingKey]: key };

    if (groupingExtraColumns) {
      caclExtraColumns(result.rows[i].values, groupingExtraColumns, j);
    }

    function valueExists() {
      return tempValues.find((t) => t[groupingKey] === key);
    }

    function valueNotExists() {
      const dataKeys = Object.keys(data[0]);
      return dataKeys.reduce((acc, curr) => {
        return { ...acc, [curr]: null };
      }, {});
    }
  });
}

export function getCellBackground(keys, currentKey, value, theme) {
  if (
    !keys.includes(currentKey) ||
    ["--", null, undefined, NaN].includes(value)
  ) {
    return null;
  }

  if (isNaN(+value) && ["yes", "no"].includes(value.toLowerCase().trim())) {
    const type =
      value.toLowerCase().trim() === "no" ? "errorMain" : "successMain";

    return theme.notification[type];
  }

  const color = theme.notification[value < 0 ? "errorMain" : "successMain"];
  const linear = scaleLinear().domain([0, 10]).range([0, 1]);

  return hexToRgba(color, linear(Math.min(10, value)));
}

export function getTextAlign(value, flexbox) {
  if (!isNaN(value)) {
    return flexbox ? "flex-end" : "right";
  }

  return flexbox ? "flex-start" : "left";
}

function caclExtraColumns(values, config, j) {
  if (j === 0) {
    return;
  }

  const { column, rowKeys, operator } = config;
  const [key1, key2] = rowKeys;

  switch (operator) {
    case "PERCENT_DIFFERENCE": {
      const prev = values[j - 1][key1] / values[j - 1][key2];
      const current = values[j][key1] / values[j][key2];
      values[j][column] = (current - prev) / prev;
      break;
    }

    case "PERCENT_DIFFERENCE_ONE_KEY": {
      const prev = values[j - 1][key1] / values[j - 1][key2];
      const current = values[j][key1] / values[j][key2];
      values[j][column] = (current - prev) / prev;
      break;
    }

    case "DIFFERENCE": {
      const prev = values[j - 1][key1];
      const current = values[j][key1];
      values[j][column] = current - prev;
      break;
    }

    default:
      break;
  }
}

export function setParentHeadersMapping(
  item,
  mapping,
  key,
  fiscalQuarterStartOffset
) {
  if (mapping === "[WEEK_RANGE_TO_US_DATE]") {
    const options = { month: "2-digit", day: "2-digit", year: "numeric" };
    const formatted = item[key]
      .split(" - ")
      .map((splitted) =>
        new Date(splitted).toLocaleDateString("en-US", options)
      )
      .join(" - ");

    return { ...item, [key]: formatted };
  }

  if (mapping === "quarter-to-fiscal") {
    return {
      ...item,
      [key]: setFiscalQuearterOffset(item[key], fiscalQuarterStartOffset),
    };
  }

  return item;
}

/* example how you can hide section in config
  "hiddenGroupings": [
    "Section 1",
    "Section 2"
  ],
  note: section will also make hidden columns under itself
*/
export const hideTableColumnsBySectionName = (
  headers,
  subTitles,
  hiddenGroupings,
  hiddenColumns = []
) => {
  const index = headers.findIndex((header) => hiddenGroupings.includes(header));
  return (subTitles || [])
    .filter((_, i) => i !== index)
    .map((subTitle) =>
      subTitle.filter((name) => !hiddenColumns.includes(name))
    );
};

/* example how you can hide columns in config 
  "hiddenColumns": [
    "Column1",
    "Column2"
  ],
*/
export const hideTableColumnsByColumnName = (subTitles, hiddenColumns) => {
  return subTitles.map((subTitle) =>
    subTitle.filter((sub) => !hiddenColumns.includes(sub))
  );
};

export const getTextAlignByColumnType = (
  column,
  isFormula,
  dynamicHeadersAlign = {},
  meta
) => {
  if (dynamicHeadersAlign[column]) {
    return dynamicHeadersAlign[column];
  }

  if (isFormula) {
    return "right";
  }

  const type = getMappingType(meta?.fields, column);
  return rightAligns.includes(type) ? "right" : "left";
};

export const setLimitValue = (value, rowsQuantity) => {
  if (value > rowsQuantity) {
    return rowsQuantity;
  }

  if (value < 0) {
    return 0;
  }

  return value;
};

export const getEnumColors = ({
  enumColorsSettings,
  cellKey,
  cellValueObject,
  row,
  groupingIndex,
}) => {
  const settings = (enumColorsSettings || []).find(
    (setting) => setting.column === cellKey
  );

  if (!settings) {
    return {};
  }

  const { asBackground, useForColumn, conditionalColumn } = settings;

  if (useForColumn) {
    return setForAllColumn(settings);
  }

  if (conditionalColumn) {
    return setConditionalColors(settings, row, groupingIndex);
  }

  // simple color config
  const value = settings[cellValueObject?.value];
  if (asBackground) {
    return setAsBackground(value);
  }

  return { color: value };
};

function setAsBackground(value) {
  return {
    background: value,
    color: "white",
  };
}

function setForAllColumn(settings) {
  const { asTextColor, asBackground, color } = settings;

  return {
    ...(asTextColor && { color }),
    ...(asBackground && {
      background: color,
      color: asTextColor ? color : "white",
    }),
  };
}

function getColorByDymanicOperator(values, value) {
  return (values || []).find((v) => v.value === value)?.color;
}

function getBackgroundByDymanicOperator(values, value) {
  return (values || []).find((v) => v.value === value)?.background;
}

function setConditionalColors(settings, row, groupingIndex) {
  if (!row.values) {
    return null;
  }
  const clusterValuesIndex = groupingIndex !== -1 ? groupingIndex - 1 : 0;

  const { conditionalColumn, values } = settings;
  const rowData = row.values[clusterValuesIndex || 0];
  const value = rowData[conditionalColumn];

  return {
    color: getColorByDymanicOperator(values, value),
    background: getBackgroundByDymanicOperator(values, value),
  };
}

export function replaceToGroupDisplayKey(row, rowGroupKey, rowGroupDisplayKey) {
  if (!rowGroupDisplayKey) {
    return (
      row[rowGroupKey] ||
      (row.values && row.values[0] ? row.values[0][rowGroupKey] : null)
    );
  }

  // the regular data structure usually stays the same and we always have a grouped column that repeats and only the values change,
  // for such data we can use index 0 but for P&L table any data group can have an undefined anchor element that can be defined either at index 0 or at 10,
  // so here we take 1 existing
  const index = row.values.findIndex((value) => value[rowGroupDisplayKey]);
  return row.values[index < 0 ? 0 : index][rowGroupDisplayKey];
}
