import Select from "../../../../../UI/Form/Select/Select";
import { getUpdatedOverrides } from "../helper";

export default function RowGroupKeyContainer(props) {
  const { chart, setChartState, removeAnyColumnType, repeatingOptions } = props;
  const { rowGroupKey, dynamicSubTitleKeys, subTitles } = chart;

  function updateChartOverridesOnChange(newOption) {
    const overrides = removeAnyColumnType(
      chart,
      rowGroupKey || "",
      dynamicSubTitleKeys ?? subTitles,
      true
    );

    const options = newOption ? [newOption] : [];

    return getUpdatedOverrides(options, overrides, "value");
  }

  function setGroupingColumn(option) {
    setChartState({
      ...chart,
      overrides: updateChartOverridesOnChange(option),
      rowGroupKey: option?.value,
      rawRows: false,
    });
  }

  return (
    <>
      <Select
        options={repeatingOptions}
        value={repeatingOptions.find((ro) => ro.value === rowGroupKey)}
        label="Grouping column (Rows)"
        onChange={setGroupingColumn}
        isClearable
      />
      <br />
      <br />
    </>
  );
}
