import * as actionTypes from "../../actions/actionTypes";
import { sales, customer } from "./structure";

export const initialState = {
  connection: null,
  stagingTables: [],
  dwTables: [],
  loading: [],
  updating: false,
  updateError: null,
  sample: null,
  column: {
    values: null,
  },
  structure: {
    sales, // hardcoded
    customer,
    dbName: null,
    pipeline: {},
    product: {},
  },
  sourceValues: {},
  loadingComplete: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_STAGING_TABLES_COMPLETE:
      const { dwTables, stagingTables, connection } = action;
      return { ...state, stagingTables, dwTables, connection };

    case actionTypes.GET_SAMPLE_VALUES_FROM_STAGING_START:
      return {
        ...state,
        loading: [...state.loading, actionTypes.GET_SAMPLE_VALUES_FROM_STAGING],
        sample: null,
      };

    case actionTypes.COMPLETE_WAREHOUSE_CREATE:
      return { ...state, connection: action.connection };

    case actionTypes.GET_SAMPLE_VALUES_FROM_STAGING_SUCCESS:
      return {
        ...state,
        sample: action.results.data,
        loading: state.loading.filter(
          (l) => l !== actionTypes.GET_SAMPLE_VALUES_FROM_STAGING
        ),
      };

    case actionTypes.GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_START:
      return { ...state, column: { values: null } };

    case actionTypes.GET_UNIQUE_COLUMN_VALUES_FROM_STAGING_SUCCESS:
      return { ...state, column: { values: action.results.data } };
    case actionTypes.CLEAR_SOURCE_DATA:
      return { ...state, column: { values: null }, sample: null };
    case actionTypes.POST_DATA_STRUCTURE_START: {
      return { ...state, updating: true, updateError: null };
    }
    case actionTypes.POST_DATA_STRUCTURE_SUCCESS: {
      return { ...state, updating: false };
    }
    case actionTypes.POST_DATA_STRUCTURE_FAIL: {
      return { ...state, updateError: action.error.message, updating: false };
    }

    case actionTypes.LOAD_DATA_STRUCTURE_START: {
      return { ...state, loadingComplete: false };
    }
    case actionTypes.LOAD_DATA_STRUCTURE_SUCCESS: {
      // console.log(action.results.data);
      const { product, pipeline, dbName } = action.results.data[0].settings;

      return {
        ...state,
        structure: {
          ...state.structure,
          product,
          pipeline,
          dbName,
        },
        loadingComplete: true,
      };
    }

    case actionTypes.LOAD_SEED_STAGING_DATA: {
      // This will need to be expanded to have an updatable list in the near future
      return { ...state, sourceValues: action.values };
    }

    default:
      return state;
  }
};

export default reducer;
