import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";

import AdminLeftMenu from "../../Pages/DataManagement/DataMenu";
import TopMenu from "../TopMenu";
import { adminPanelMenuList } from "../../utils/constants/constants";
import { changeThemeConfiguration } from "../../store/actions";
import { usePathName } from "../../hooks/usePathName";
import { useShallowEqualSelector } from "../../store";
import { css } from "../../utils/ide";
import { hasAccessToNavEntry } from "../../utils/access";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import { dataMenuWidth } from "../../Pages/DataManagement/DataMenu";
import AdminRouting from "../../Pages/AdminSection/AdminRouting";

const AdminWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.background.secondary};
  overflow: hidden;
`;

const AdminContentContainer = styled.div(
  ({ theme, showSidebar }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${theme.background.admin};
    color: ${theme.text.primary};
    margin-left: ${showSidebar ? dataMenuWidth : 0};
    overflow-y: auto;
    box-sizing: border-box;
    transition: 0.3s;
  `
);

const AdminContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 1.5rem;
`;

const TopMenuContainer = styled.div(
  ({ theme }) => css`
    position: sticky;
    flex: 0 0 auto;
    padding-left: 1.5rem;
    top: 0;
    right: 0;
    background: ${theme.background.primary};
    z-index: 505;
    background: ${(props) => props.theme.background.primary};
    border-bottom: 1px solid
      ${({ theme }) => (theme.type === "light" ? theme.gray.gray300 : "none")};
    transition: all 0.3s;
  `
);

const AdminLayout = ({ location }) => {
  const [active, setActive] = useState(adminPanelMenuList[0].link);
  const dispatch = useDispatch();
  const { logo, theme, role, permissions } = useShallowEqualSelector(
    (state) => ({
      logo: state.layout.logo,
      theme: state.layout.theme,
      role: state.auth.role,
      permissions: state.auth.role,
    })
  );
  const isMobile = useMediaQuery({
    query: `(max-width: ${mobileBreakpoints.mobile})`,
  });

  const defaultDashboardTab = usePathName();

  useEffect(() => {
    if (!location.pathname.includes(active)) {
      setActive(location.pathname);
    }
  }, [active, location]);

  const handleMenuClick = (menu) => {
    setActive(menu.link);
  };

  const [showSidebar, setShowSidebar] = useState(!isMobile);
  const handleThemeChange = () =>
    dispatch(
      changeThemeConfiguration(theme.name === "light" ? "dark" : "light")
    );

  return (
    <AdminWrapper data-cy="admin-wrapper">
      <div id="select-menu-portal" />
      <AdminLeftMenu
        handleClick={handleMenuClick}
        active={active}
        list={adminPanelMenuList.filter((i) =>
          hasAccessToNavEntry(i, role, permissions)
        )}
        logo={logo}
        show={showSidebar}
        setShow={() => setShowSidebar(false)}
        handleThemeChange={handleThemeChange}
      />
      <AdminContentContainer showSidebar={showSidebar}>
        <TopMenuContainer showSidebar={showSidebar}>
          <TopMenu
            show={() => setShowSidebar(!showSidebar)}
            hasLink={defaultDashboardTab}
          />
        </TopMenuContainer>

        <AdminContent>
          <AdminRouting />
        </AdminContent>
      </AdminContentContainer>
    </AdminWrapper>
  );
};

export default withRouter(AdminLayout);
