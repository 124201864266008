import React, { useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { exportXlsx } from "../../utils/exportTableToExcel";
import Modal from "../../UI/Modal/Modal";
import ExportAllDrillDown from "../../charts/TableView/ExportAllDrillDown/ExportAllDrillDown";
import { useDispatch } from "react-redux";
import {
  apiExport,
  clearExportAllObject,
} from "../../store/actions/dashboard/dashboard";
import { css } from "../../utils/ide";
import useVisualizationNavigation from "../../hooks/useVisualizationNavigation";

const ActionsContainer = styled.div(
  ({ theme, marginTop = 0, isTableVisualization }) => css`
    display: flex;
    gap: 20px;
    margin-top: ${marginTop || 18}px;
    justify-content: flex-end;
    margin-bottom: 8px;
    position: relative;
    // this need for hideDetailLink: true
    min-height: 14.4px;
    // this z-index need to make clickable full details for charts with legend
    // but for table charts it will break expand all modal
    z-index: ${isTableVisualization ? 0 : 1};
    svg {
      margin-left: 4px;
    }

    & a,
    & > span {
      font-size: 12px;
      text-decoration: none;
      color: ${theme.text.secondary};
      font-style: italic;
      &:hover {
        color: ${theme.text.primary};
      }
    }

    & > span {
      cursor: pointer;
    }

    :not(:hover) .hide-unless-hover-over-container {
      display: none;
    }
  `
);

export default function Details({
  dashboardName,
  visualizationId,
  exportId,
  hideDetailLink,
  rowIndexes,
  setRowIndexes,
  nonConvertPercentsBack,
  title,
  marginTop,
  chart,
  activeTab,
  menuFilters,
  dateFilters,
  isTableVisualization,
  domain,
}) {
  const { linksNode } = useVisualizationNavigation();

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { exportType = "FE", exportFormat } = chart ?? {};
  const isApiExort = exportType === "API";

  async function handleExport(exportId, convertPercentsBack, title) {
    if (isApiExort) {
      return exportFromApi(false);
    }

    if (rowIndexes.length) {
      await setRowIndexes([]);
    }

    exportXlsx(
      exportId,
      convertPercentsBack,
      title,
      activeTab,
      dateFilters,
      menuFilters,
      domain
    );
  }

  /**
   * Exports data from API using the apiExport function with the specified chart type and format.
   *
   * @param {Boolean} allRecords
   */
  function exportFromApi(allRecords) {
    dispatch(apiExport(chart, allRecords, exportFormat));
  }

  function closeModal() {
    dispatch(clearExportAllObject());
    setShowModal(null);
  }

  return (
    <ActionsContainer
      className="details"
      data-cy="full-details-link"
      marginTop={marginTop}
      isTableVisualization={isTableVisualization}
    >
      {linksNode}

      {chart?.rowExpandVisualizationParams?.expandAllconfig && (
        <span onClick={() => setShowModal(true)}>Complete List</span>
      )}

      {exportId && (
        <span
          onClick={() => handleExport(exportId, !nonConvertPercentsBack, title)}
        >
          Export
        </span>
      )}
      {isApiExort && (
        <span onClick={() => exportFromApi(true)}>Export with all columns</span>
      )}
      {!hideDetailLink && (
        <Link to={`${dashboardName}/details/${visualizationId}`}>
          Full Details <FontAwesomeIcon icon={["fas", "caret-right"]} />
        </Link>
      )}

      {showModal && (
        <Modal close={closeModal} title={`${title}`} fullScreen>
          <ExportAllDrillDown
            chart={chart}
            handleExport={handleExport}
            onClose={closeModal}
            title={title}
          />
        </Modal>
      )}
    </ActionsContainer>
  );
}
