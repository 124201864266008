import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import produce from "immer";
import { useDispatch } from "react-redux";
import { removeByKey } from "../../../../../utils/func";
import * as actions from "../../../../../store/actions";
import usePrevious from "../../../../../utils/usePrevious";
import SelectedQueryContext from "../../SelectedQuery/SelectedQueryContext";
import shouldRunQuery from "./shouldRunQuery";
import explorerRowOrdering from "./explorerRowOrdering";
import {
  convertDraftSettingsToEffectiveSettings,
  convertEffectiveSettingsToDraftSettings,
} from "./convertDraftSettings";
import { useFilters } from "./useFilters";

const emptyObject = { overrides: { fields: [] } };

export const DEFAULT_PER_PAGE = 50;

export default (lastSavedExecQuerySettings) => {
  const dispatch = useDispatch();
  const [lastApplied, setLastApplied] = useState(lastSavedExecQuerySettings);
  const [draftSettings, setDraftSettings] = useState(emptyObject);
  const [name, setName] = useState("");
  const { selectedQuery, setSelectedQuery, allQueryFields } =
    useContext(SelectedQueryContext);
  const [savedSettingsUuid, setSavedSettingsUuid] = useState(null);

  const filters = useFilters(draftSettings.filters);
  const prevDraftSettings = usePrevious(draftSettings);
  const prevMuted = usePrevious(filters.muted);
  const prevFilters = usePrevious(filters.draftFilters);

  useEffect(() => {
    const changedMuted = prevMuted && prevMuted !== filters.muted;
    const doRun =
      shouldRunQuery(
        draftSettings,
        prevDraftSettings,
        selectedQuery,
        filters.draftFilters,
        prevFilters
      ) || changedMuted;
    if (doRun) {
      const unMutedSettingsFilters = filters.draftFilters.filter(
        (d, i) => !filters.isMuted(i)
      );
      const unMutedDraftSettings = {
        ...effectiveSettings,
        filters: unMutedSettingsFilters,
      };
      dispatch(actions.runQuery(selectedQuery, unMutedDraftSettings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    draftSettings,
    selectedQuery,
    prevDraftSettings,
    filters.muted,
    filters.draftFilters,
    prevFilters,
    prevMuted,
    lastApplied,
    // initialLastSettings,
  ]);

  function handleConvert(settings) {
    const nextSettings = convertEffectiveSettingsToDraftSettings(
      settings ?? emptyObject
    );
    setDraftSettings({
      ...nextSettings,
      // filters: nextSettings.filters ?? [], // This should not be here
      havings: nextSettings.havings ?? [],
      overrides: {
        fields: nextSettings.overrides?.fields ?? [],
      },
      orders: nextSettings.orders || [],
      perPage: nextSettings.perPage || 50,
    });
    filters.setDraftFilters(nextSettings.filters ?? []);
    setName(lastApplied?.name ?? "Untitled");
  }

  function applyLastSettings(selectedQuery, settings) {
    setSelectedQuery(selectedQuery.uuid);
    setLastApplied(settings);
  }

  useEffect(() => {
    handleConvert(lastApplied?.settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastApplied]);

  const addOverride = (field) => {
    const matchedField =
      allQueryFields && allQueryFields.find((f) => f.name === field);
    const nextState = produce(draftSettings, (draftState) => {
      draftState.overrides.fields.push({
        name: field,
        mapping: { ...matchedField?.mapping },
      });
    });
    return setDraftSettings(nextState);
  };

  const setOverride = (position, fieldName) => {
    const nextState = produce(draftSettings, (draftState) => {
      draftState.overrides.fields[position].name = fieldName;
    });
    return setDraftSettings(nextState);
  };

  const removeOverride = (position) => {
    const nextFields = removeByKey(position)(draftSettings.overrides.fields);
    return setDraftSettings({
      ...draftSettings,
      overrides: { ...draftSettings.overrides, fields: nextFields },
    });
  };

  const setAggregation = (position, value) => {
    const nextState = produce(draftSettings, (draftState) => {
      draftState.overrides.fields[position].aggregation = { type: value };
    });
    return setDraftSettings(nextState);
  };

  const removeAggregation = (position) => {
    const nextState = produce(draftSettings, (draftState) => {
      delete draftState.overrides.fields[position].aggregation;
    });
    return setDraftSettings(nextState);
  };

  // Column Order
  const setOrder = (from, to) => {
    const fields = draftSettings.overrides.fields.filter((s, i) => i !== from);
    fields.splice(to, 0, draftSettings.overrides.fields[from]);
    return setDraftSettings({
      ...draftSettings,
      overrides: { ...draftSettings.overrides, fields },
    });
  };

  const setRowOrdering = (field) => {
    return explorerRowOrdering(field, draftSettings, setDraftSettings);
  };

  const setMapping = (position, options) => {
    const nextState = produce(draftSettings, (draftState) => {
      draftState.overrides.fields[position].mapping = options;
    });
    return setDraftSettings(nextState);
  };

  const loadFromSettings = useCallback(
    (settingsUuid) => {
      if (savedSettingsUuid === settingsUuid) {
        return;
      } else {
        setSavedSettingsUuid(settingsUuid);
      }
      function handleInit(data) {
        setLastApplied(data);
        setSelectedQuery(data.queryUuid);
      }

      return dispatch(
        actions.initTable("settings", null, settingsUuid, handleInit)
      );
    },
    [dispatch, setSelectedQuery, savedSettingsUuid]
  );

  const clearAll = useCallback(() => {
    return setLastApplied({
      settings: { perPage: DEFAULT_PER_PAGE, filters: draftSettings.filters },
    });
  }, [draftSettings]);

  const createFromQuery = useCallback(
    (queryUuid) => {
      clearAll();
      setSavedSettingsUuid(null);
      setSelectedQuery(queryUuid);
      return dispatch(
        actions.initTable("new", queryUuid, null, completeCreateFromQuery)
      );
    },
    [clearAll, dispatch, setSelectedQuery]
  );

  function clearColumns() {
    return setLastApplied({
      settings: { perPage: DEFAULT_PER_PAGE, filters: filters.draftFilters },
    });
  }

  function completeCreateFromQuery(settings) {
    setLastApplied(settings);
  }

  const setPerPage = (perPage) => {
    setDraftSettings({ ...draftSettings, perPage });
  };

  function setOrderPriority(nextOrders) {
    const draftOrders = nextOrders.map((o) =>
      draftSettings.orders.find((currentO) => currentO.name === o)
    );
    return setDraftSettings({ ...draftSettings, orders: draftOrders });
  }

  function removeSortingKey(name) {
    const nextOrders = draftSettings.orders.filter((o) => o.name !== name);
    return setDraftSettings({ ...draftSettings, orders: nextOrders });
  }

  const overrides = draftSettings?.overrides?.fields;

  const effectiveSettings = useMemo(
    () => convertDraftSettingsToEffectiveSettings(draftSettings),
    [draftSettings]
  );

  const getSettingsUnmutedFilters = () => {
    const unMutedSettingsFilters = filters.draftFilters.filter(
      (f, i) => !filters.isMuted(i)
    );

    return {
      ...effectiveSettings,
      filters: unMutedSettingsFilters,
    };
  };

  return {
    name,
    setName,
    effectiveSettings,
    settings: draftSettings,
    filters: filters.draftFilters,
    overrides,
    muted: filters.muted,
    setMuted: filters.setMuted,
    isMuted: filters.isMuted,
    addFilter: filters.addFilter,
    setOperator: filters.setOperator,
    setField: filters.setField,
    setValues: filters.setValues,
    removeRow: filters.removeRow,
    addOverride,
    setOverride,
    removeOverride,
    setAggregation,
    removeAggregation,
    setOrder,
    setRowOrder: setRowOrdering,
    setOrderPriority,
    removeSortingKey,
    setMapping,
    clearAll,
    clearColumns,
    perPage: draftSettings.perPage,
    setPerPage,
    selectedQuery,
    setSelectedQuery,
    loadFromSettings,
    createFromQuery,
    applySettings: setLastApplied,
    applyLastSettings,
    getSettingsUnmutedFilters,
  };
};
