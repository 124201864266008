import React, { useContext, useEffect } from "react";
import Loading from "../../../../UI/Loading/Loading";
import SelectedQueryContext from "../SelectedQuery/SelectedQueryContext";

export default function InitExplorerTable(props) {
  const {
    activeQuery,
    params,
    filterApi,
    lastSettings,
    activeSettings,
    explorerMode,
  } = props;
  const { selectedQuery } = useContext(SelectedQueryContext);
  const { queryId, settingsUuid } = params;
  const { createFromQuery, loadFromSettings } = filterApi;

  useEffect(() => {
    // Switch mode
    if (firstRun()) handleFirstRun();
    else if (switchToQueryFromSettings()) initQuery();
    else if (switchToSettingsMode()) initReport();
    else if (changedQuery()) initQuery();
    else if (changedReport()) initReport();

    function initReport() {
      loadFromSettings(params.settingsUuid);
    }

    function initQuery() {
      createFromQuery(params.queryId);
    }

    function changedQuery() {
      return queryId && activeQuery?.uuid && activeQuery.uuid !== queryId;
    }

    function handleFirstRun() {
      params.queryId ? initQuery() : initReport();
    }

    function changedReport() {
      return (
        activeSettings && settingsUuid && settingsUuid !== activeSettings.uuid
      );
    }

    function firstRun() {
      return !explorerMode;
    }

    function switchToQueryFromSettings() {
      return params.queryId && explorerMode === "report";
    }

    function switchToSettingsMode() {
      return params.settingsUuid && explorerMode === "query";
    }
    /* eslint-disable */
  }, [queryId, settingsUuid, explorerMode]);

  if (
    (!activeQuery || !selectedQuery || activeQuery.uuid !== selectedQuery) &&
    !lastSettings
  ) {
    return <Loading />;
  } else {
    return props.children;
  }
}

InitExplorerTable.defaultProps = {
  params: {},
};
