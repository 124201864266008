import {
  GET_THEME_CONFIGURATION_START,
  GET_THEME_CONFIGURATION_SUCCESS,
  GET_THEME_CONFIGURATION_FAIL,
  CHANGE_THEME_CONFIGURATION_START,
  CHANGE_THEME_CONFIGURATION_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  theme: "light",
  globalOverride: null,
  localOverrides: null,
  chartOverrides: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_THEME_CONFIGURATION_START:
      return {
        ...state,
        loading: true,
      };

    case GET_THEME_CONFIGURATION_SUCCESS:
      return {
        ...state,
        chartOverrides: action.config.chartOverrides,
        globalOverride: action.config.globalOverride,
        localOverrides: action.config.localOverrides,
        theme: action.config.theme,
        loading: false,
      };

    case GET_THEME_CONFIGURATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case CHANGE_THEME_CONFIGURATION_START:
      return {
        ...state,
        // loading: true
      };

    case CHANGE_THEME_CONFIGURATION_SUCCESS:
      return {
        ...state,
        // loading: false,
        globalOverride: action.config.globalOverride,
        theme: action.config.theme,
      };

    default:
      return {
        ...state,
      };
  }
}
