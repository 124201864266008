import React from "react";
import { useSelector } from "react-redux";
import MultiRangeSlider from "./MultiRangeSlider";
import { castToNumber } from "../../utils/func";

// @todo, this needs to be broken out into reusable component for slider
export default function MultiRangeFilter({ onChange, filter }) {
  const multiRangeFilters = useSelector(
    (state) => state.layout.multiRangeFilters
  );
  const { type, format, values } = filter;

  const { min, max } = {
    min: Math.min(...values.map((v) => castToNumber(v.value))),
    max: Math.max(...values.map((v) => castToNumber(v.value))),
  };

  function handleChange(min, max) {
    if (filter.min !== min || filter.max !== max) {
      onChange(
        {
          ...filter,
          min,
          max,
          type: values[0]?.type,
        },
        type
      );
    }
  }

  return (
    <MultiRangeSlider
      onChange={handleChange}
      type={type}
      format={format}
      values={values}
      min={min}
      max={max}
      multiRangeFilters={multiRangeFilters}
    />
  );
}
