import { useMemo, useState } from "react";
import FilterItem from "./FilterItem";
import styled from "@emotion/styled";
import Input from "../../UI/Form/Input/Input";
import {
  checkboxOrRadio,
  filterValuesLimit,
  FILTER_TYPE_BUCKET_RANGE,
} from "../../utils/constants/constants";
import ComparisonSelectAll from "./ComparisonSelectAll/ComparisonSelectAll";
import FilterValuesPagination from "./FilterValuesPagination";
import useFilterPagination from "../../hooks/useFilterPagination";
import GeneralSelectAll from "./GeneralSelectAll";

const SearchWrapper = styled.div(
  ({ theme }) => `
  & > input {
    margin-bottom: 10px;
    font-size: 14px;
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, .1);
  }

  & > input::placeholder {
    font-size: 12px;
  }
`
);

const perPage = 125;

export default function GeneralFilters(props) {
  const {
    searchValue,
    setSearchValue,
    select,
    finalFiltersBeforePagination,
    type,
    comparisonModeItem,
    selectPowerEditorModeFilter,
    filter,
    searchFilterLoading,
    additionalTheme,
  } = props;

  const [limit, setLimit] = useState(perPage);

  const { page, setPage, fetchFilterValues, usePagination } =
    useFilterPagination(filter);

  const { totalCount } = filter ?? {};
  const quantityInStore = finalFiltersBeforePagination.length;

  const selectedFilters = useMemo(() => {
    return finalFiltersBeforePagination.filter((filter) => filter.checked);
  }, [finalFiltersBeforePagination]);

  const currentPageOfFilters = useMemo(() => {
    return finalFiltersBeforePagination.slice(0, limit);
  }, [finalFiltersBeforePagination, limit]);

  const onSearch = (value) => {
    if (usePagination) {
      fetchFilterValues(value);
    }
    setSearchValue(value);
  };

  const currentPageFilterValues = useMemo(() => {
    return currentPageOfFilters.map((filter) => filter.value);
  }, [currentPageOfFilters]);

  const selectedFiltersNotOnCurrentPage = useMemo(() => {
    return selectedFilters.filter(
      (filter) => !currentPageFilterValues.includes(filter.value)
    );
  }, [currentPageFilterValues, selectedFilters]);

  if (
    ![...checkboxOrRadio, FILTER_TYPE_BUCKET_RANGE].find(
      (item) => item === type
    )
  ) {
    return null;
  }

  const filters = [...selectedFiltersNotOnCurrentPage, ...currentPageOfFilters]
    .filter(filterEmpty)
    .filter(filterUnknown);

  return (
    <>
      {totalCount > filterValuesLimit && (
        <SearchWrapper>
          <Input
            placeholder="Find..."
            onChange={(e) => onSearch(e.target.value)}
            loading={searchFilterLoading}
            value={searchValue}
          />
        </SearchWrapper>
      )}

      <ComparisonSelectAll
        filters={filters}
        type={type}
        comparisonModeItem={comparisonModeItem}
        selectPowerEditorModeFilter={selectPowerEditorModeFilter}
      />

      <GeneralSelectAll
        select={select}
        type={type}
        filters={filters}
        filterUuid={filter.uuid}
        additionalTheme={additionalTheme}
      />

      {filters.map((filter) => (
        <FilterItem
          key={
            filter.value && typeof filter.value === "object"
              ? JSON.stringify(filter.value)
              : filter.value
          }
          name={filter.label}
          checked={filter.checked ? "checked" : ""}
          onChange={() => select(filter, type)}
          cy="filter-menu-input"
          type={type}
        />
      ))}

      {quantityInStore > limit ? (
        <div
          className="clickable"
          onClick={() => setLimit((limit) => limit + perPage)}
        >
          ...
          {quantityInStore - limit} More
        </div>
      ) : null}

      <FilterValuesPagination
        totalCount={totalCount}
        quantityInStore={quantityInStore}
        page={page}
        setPage={setPage}
        fetchFilterValues={fetchFilterValues}
        searchValue={searchValue}
      />
    </>
  );
}

export const filterUnknown = ({ value }) => {
  return value?.toString().toLowerCase() !== "unknown";
};

export const filterEmpty = (v) => v.min || v.max || v.value;
