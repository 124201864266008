import { useDispatch, shallowEqual } from "react-redux";
import { useMemo, useEffect } from "react";
import { checkAuth, loadLanding, listQueries } from "../store/actions";
import theme from "../styles/theme";
import { useAppDomainConfig } from "./useAppDomainConfig";
import { useHistory } from "react-router";
import { useShallowEqualSelector } from "../store";

export const useAppStateConfig = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    themeConfiguration,
    loading,
    landing,
    siteName,
    favicon,
    invalidDomain,
    queries,
  } = useShallowEqualSelector(
    (state) => ({
      themeConfiguration: state.layout.theme,
      loading: state.layout.loading,
      landing: state.layout.landing,
      siteName: state.layout.siteName,
      favicon: state.layout.favicon,
      invalidDomain: state.layout.invalidDomain,
      queries: state.dataSettings.tinyQueries,
    }),
    shallowEqual
  );

  const th = useMemo(() => theme(themeConfiguration), [themeConfiguration]);
  const domain = useAppDomainConfig(props.location);

  useEffect(() => {
    if (!queries.length) {
      // we calling tiny queries to make sure that we have the uuids and types for query builder calls
      // it need for drilldown tables with different query uuid
      dispatch(listQueries(true));
    }
  }, [dispatch, queries.length]);

  useEffect(() => {
    if (domain) {
      dispatch(checkAuth(domain, history));
    } else {
      dispatch(loadLanding());
    }
  }, [dispatch, domain, history]);

  return {
    domain,
    landing,
    loading,
    siteName,
    favicon,
    theme: th,
    themeName: themeConfiguration.name,
    invalidDomain,
  };
};
