import {
  addDays,
  addQuarters,
  addMonths,
  format,
  min,
  parse,
  parseISO,
  startOfWeek,
} from "date-fns";

export default function (data, dates, zeroPad) {
  if (!zeroPad || !data.length || (data.length && !data[0][zeroPad.dateKey])) {
    return data;
  }

  let requiredDates = [];
  let startDateWeekly = startOfWeek(parseISO(dates.start.value), {
    weekStartsOn: 1,
  });
  let tempDate =
    zeroPad.dateTerm === "weekly"
      ? startDateWeekly
      : parseISO(dates.start.value);
  const toDate = min([parseISO(dates.end.value), new Date()]);

  let dateFormat =
    zeroPad.dateTerm === "weekly"
      ? "yyyy-MM-dd"
      : zeroPad.dateTerm === "monthly"
      ? "yyyy 'M'MM"
      : "yyyy QQQ";

  while (tempDate <= toDate) {
    requiredDates.push(format(tempDate, dateFormat));
    tempDate =
      zeroPad.dateTerm === "weekly"
        ? addDays(tempDate, 7)
        : zeroPad.dateTerm === "monthly"
        ? addMonths(tempDate, 1)
        : addQuarters(tempDate, 1);
  }

  const result = requiredDates.map((td) => {
    const match = data.find((d) => d[zeroPad.dateKey] === td);
    const obj = {
      [zeroPad.dateKey]: td,
      [zeroPad.amountKey]: 0,
    };

    if (zeroPad.xKey)
      obj[zeroPad.xKey] = format(
        parse(td, "yyyy QQQ", new Date()),
        zeroPad.xKeyFormat
      );

    return match || obj;
  });

  return zeroPad.reverse ? result.reverse() : result;
}
