import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import { useTheme } from "emotion-theming";
import { navigation } from "../utils/tableEditorHelper";
import { Link } from "react-router-dom";

const Menu = styled.div(
  ({ theme }) => `
  background: ${lightenDarkenColor(theme.background.secondary, -10)};
  max-width: 70px;
  min-width: 70px;
  position: relative;
  z-index: 2;
`
);

const NavigationItem = styled.div(
  ({ theme, isActive }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      font-size: 26px;
      margin-bottom: 5px;
    }
    span {
      font-size: 10px;
      text-align: center;
    }

    margin: 30px 0px;

    ${
      isActive &&
      `
       color: ${lightenDarkenColor(theme.primary, 60)};
    `
    };

    &:hover {
      color: ${theme.primary};
      cursor: pointer;
    }
`
);

export default function NavigationMenu({
  navigationState,
  setNavigationState,
  selectedColumn,
  setSelectedColumn,
  pageUuid,
  blockUuid,
  visualizationUuid,
}) {
  const theme = useTheme();

  const onNavigationClick = (navigation) => {
    if (selectedColumn) {
      setSelectedColumn(null);
    }

    setNavigationState(navigation);
  };

  return (
    <Menu>
      <Link
        to={`/admin/page-management/${pageUuid}/blocks/${blockUuid}/visualizations/${visualizationUuid}`}
      >
        <NavigationItem>
          <FontAwesomeIcon icon={["far", "long-arrow-left"]} />
          <span>Back to Chart Editor</span>
        </NavigationItem>
      </Link>

      <hr color={theme.background.secondary} />
      {navigation.map(({ name, icon, hasDivider, cy }) => (
        <React.Fragment key={name}>
          <NavigationItem
            onClick={() => onNavigationClick(name)}
            isActive={navigationState === name}
            data-cy={cy}
          >
            <FontAwesomeIcon icon={icon} />
            <span>{name}</span>
          </NavigationItem>
          {hasDivider && <hr color={theme.background.secondary} />}
        </React.Fragment>
      ))}
    </Menu>
  );
}
