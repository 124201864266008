import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import useVisualizationNavigation from "../../hooks/useVisualizationNavigation";

const ProblemMessageContainer = styled.div`
  padding-top: 48px;
  opacity: 0.35;
  font-size: 22px;
  color: ${(props) => props.theme.text.secondary};
  path {
    fill: ${(props) =>
      props.isError ? props.theme.notification.errorMain : props.theme.primary};
  }
  div {
    margin-bottom: 8px;
    text-align: center;
  }

  :not(:hover) .hide-unless-hover-over-container {
    display: none;
  }
`;

const NavigationOuterContainer = styled.div`
  :not(:hover) .hide-unless-hover-over-container {
    visibility: hidden;
  }
`;

export default function DataNotAvailable({
  text,
  restricted,
  noResultsMessageOverride,
}) {
  const { linksNode } = useVisualizationNavigation();

  const errorMessage = restricted ? (
    text.message ?? text
  ) : text ? (
    <div data-cy="block-load-error">
      There was a problem retrieving your data
    </div>
  ) : (
    <div data-cy="block-no-results">
      {noResultsMessageOverride || "No results"}
    </div>
  );

  const content = (
    <ProblemMessageContainer isError={restricted ? false : !!text}>
      <div>
        <FontAwesomeIcon
          icon={[
            "fas",
            `${
              restricted ? "lock" : text ? "exclamation-circle" : "info-circle"
            }`,
          ]}
          size="2x"
        />
      </div>
      {errorMessage}
    </ProblemMessageContainer>
  );

  if (linksNode) {
    return (
      <NavigationOuterContainer>
        {content}
        <div>{linksNode}</div>
      </NavigationOuterContainer>
    );
  }

  return content;
}
