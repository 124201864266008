import React from "react";
import NavigationCell from "./NavigationCell";
import styled from "@emotion/styled";

const layoutPadding = 158;
const TableCellsContainer = styled.div`
  max-width: calc(
    100vw - ${(props) => props.subtractWidth}px - ${layoutPadding}px
  );
  box-sizing: border-box;
  overflow-x: auto;
  color: ${(props) => props.theme.text.secondary};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
`;

const NavigationTableBody = React.forwardRef((props, ref) => {
  const { data, columns, columnWidths, showActive, activeKey } = props;
  return (
    <TableCellsContainer ref={ref} data-cy="navigation-table-body">
      {data.map((d, dataRowIndex) => (
        <Row key={dataRowIndex}>
          {columns.map((column, i) => (
            <NavigationCell
              key={i}
              cellWidth={columnWidths[i] || 140}
              d={d}
              column={column}
              active={showActive && activeKey === i}
            />
          ))}
        </Row>
      ))}
    </TableCellsContainer>
  );
});

export default NavigationTableBody;
