export const permissionName = {
  DATA_SOURCE_READ: "data_sources-read",
  DATA_SOURCE_UPDATE: "data_sources-update",
  INSIGHTS_READ: "insights-read",
  QUERIES_READ: "queries-read",
  QUERIES_UPDATE: "queries-update",
  DATA_EDITOR: "data-editor",
  DATA_IMPORT: "data-import",
  USERS_UPDATE: "users-update",
  VISUALIZATIONS_READ: "visualizations-read",
};
