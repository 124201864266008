import { summed } from "../../utils/func";

export default function (
  names,
  mappedWithTotals,
  allXKeys,
  sortedData,
  mappedXKey,
  yValueKeys,
  breakdownKey,
  yKey,
  yKeys
) {
  // breakdown segments in order for single yValueKey
  const keys =
    yKeys ||
    names ||
    mappedWithTotals.map((a) => a.segmentKey).filter((a) => a); // ['LocationA', 'LocationB', 'LocationC']

  const convertedArray = allXKeys.reduce((acc, curr) => {
    const atDate = sortedData.filter((d) => d[mappedXKey] === curr);

    const obj = {
      first: atDate.reduce(
        getDataReducer(yValueKeys && yValueKeys[0], breakdownKey, yKeys),
        {}
      ),
      xKey: curr,
    };
    if (yValueKeys && yValueKeys[1]) {
      obj.second = atDate.reduce(
        getDataReducer(yValueKeys[1], breakdownKey, null),
        {}
      );
    }
    return [...acc, obj];
  }, []);

  // totals for yScale
  const totals = allXKeys.map((k) => ({
    x: k,
    total: getTotals(k, allXKeys, sortedData, mappedXKey, yValueKeys, yKeys),
    labelTotal: getTotals(
      k,
      allXKeys,
      sortedData,
      mappedXKey,
      yValueKeys,
      yKeys
    ),
    // labelTotal:
    //   yKey &&
    //   summed(sortedData.filter((l) => l[mappedXKey] === k).map((d) => d[yKey])),
  }));
  return { keys, convertedArray, totals };
}

function getTotals(k, allXKeys, sortedData, mappedXKey, yValueKeys, yKeys) {
  if (yKeys) {
    const row = sortedData.find((d) => d[mappedXKey] === k);
    return yKeys.reduce((acc, curr) => {
      return acc + +row[curr] || 0;
    }, 0);
  } else {
    return summed(
      sortedData
        .filter((l) => l[mappedXKey] === k)
        .map((d) =>
          (() => {
            let ret = 0;
            const toIterate = totalIterated(yValueKeys);
            for (const keyOrObject of toIterate) {
              const key =
                typeof keyOrObject === "object"
                  ? keyOrObject.source
                  : keyOrObject;
              ret += d[key];
            }
            return ret;
          })()
        )
    );
  }
}

function totalIterated(yValueKeys) {
  if (!yValueKeys) return []; // temp only
  return !Array.isArray(yValueKeys[0]) ? [yValueKeys[0]] : yValueKeys[0];
}

/**
 * Gets the reducer function for gathering numbers or totals to appear on each stack component.
 *
 * @param yGroupValues
 * @param breakdownKey
 * @param yKeys
 * @returns {(function(*, *): (*))|*}
 */
export function getDataReducer(yGroupValues, breakdownKey, yKeys) {
  function dataReducer(acc, curr) {
    const ret = { ...acc };
    if (yKeys) {
      return multiKeyMode(acc, curr, yKeys);
    } else {
      return singleValueMode(acc, curr, ret, breakdownKey, yGroupValues);
    }
  }

  return dataReducer;
}

function multiKeyMode(acc, curr, yKeys) {
  const yKeyValues = yKeys.reduce((a, c) => {
    return { ...a, [c]: curr[c] };
  }, {});
  return { ...acc, ...yKeyValues };
}

function singleValueMode(acc, curr, ret, breakdownKey, yGroupValues) {
  if (Array.isArray(yGroupValues)) {
    for (const groupValue of yGroupValues) {
      const isObj = typeof groupValue === "object";
      const source = isObj ? groupValue.source : groupValue;
      const target = isObj ? groupValue.target : groupValue;

      ret[target] = (acc[source] || 0) + curr[source];
    }
    return ret;
  }

  const currKey = curr[breakdownKey];
  return { ...acc, [currKey]: (acc[currKey] || 0) + curr[yGroupValues] };
}
