import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../../../UI/Flex/Flex";
import IOButton from "../../../../UI/Form/Button/IOButton";
import Select from "../../../../UI/Form/Select/Select";
import Modal from "../../../../UI/Modal/Modal";
import {
  confirmation,
  getFilteredByOptions,
  getFilteredByOptionValues,
  // getFilteredByValueOption,
  getIsDisabled,
  getNestedDrilldownConfig,
} from "../../utils/tableEditorHelper";
import useConfirmDelete from "../../../../hooks/useConfirmDelete";
import Input from "../../../../UI/Form/Input/Input";
import { Note } from "../General/LimitRows/LimitRows";

const Container = styled.div`
  height: ${(props) => (props.extended ? 450 : 350)}px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SelectGroup = styled.div`
  height: ${(props) => (props.extended ? 65 : 62)}%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 25px;
`;

const RemoveContainer = styled.div(
  ({ theme }) => `
  color: ${theme.notification.errorMain};
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  svg {
    margin-right: 10px;
  }
  `
);

export default function EditDrilldown({
  level,
  setEditLevel,
  queries,
  chart,
  deleteDrilldown,
  saveDrilldown,
  onDynamicFiltersChange,
  setTemporaryChartConfig,
  fields,
  drilldownParent,
  setParent,
  parent,
  dropDynamicLevelIndicators,
  setDrilldownParent,
}) {
  const { title, message, rounded, zIndexOverride } = confirmation;

  const confirm = useConfirmDelete(
    deleteDrilldown,
    title,
    message,
    rounded,
    zIndexOverride
  );

  if (!level) {
    return null;
  }

  const currentChart = getNestedDrilldownConfig(chart, level, drilldownParent);

  const parentChart = getNestedDrilldownConfig(
    chart,
    level - 1,
    drilldownParent
  );
  const showParentKey = parentChart.dynamicDrilldowns && drilldownParent[level];

  const currentQuery = queries.find(
    (query) => query.uuid === currentChart.queryId
  );

  const filteredByOptions = getFilteredByOptions(
    currentQuery,
    currentChart.parameterizedFilterPrefix
  );

  const filteredByOptionValues = getFilteredByOptionValues(
    filteredByOptions,
    currentChart.dynamicFilters
  );

  const disabled = getIsDisabled(
    filteredByOptionValues,
    currentQuery,
    parent,
    showParentKey
  );

  // const filteredByValueOption = getFilteredByValueOption(
  //   fields,
  //   currentChart.dynamicFilterValue
  // );

  const changeParentKey = (e) => {
    const val = e.target.value;
    if (!val) {
      return;
    }
    setParent(val);
  };

  const closeModal = () => {
    setEditLevel(0);
    setTemporaryChartConfig(null);

    if (chart.addAction) {
      setDrilldownParent({});
    }

    if (level === 1 && chart.addAction) {
      dropDynamicLevelIndicators();
    }
  };

  return (
    <>
      <Modal rounded width={"400px"} showClose close={closeModal}>
        <Container extended={showParentKey}>
          <Title data-cy="modal-title-drilldown">
            {chart.addAction ? "Add " : "Edit "}Level {level}
          </Title>
          <SelectGroup extended={showParentKey}>
            <Select
              label="Query"
              options={queries}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.uuid}
              value={currentQuery}
              menuPlacement="top"
              menuHeight={menuHeight}
              onChange={(options) =>
                onDynamicFiltersChange(options, "queryId", "uuid")
              }
              cy="edit-drill-down-query"
            />

            <Select
              label="Filtered by"
              options={filteredByOptions}
              isMulti
              closeMenuOnSelect={false}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              value={filteredByOptionValues}
              menuHeight={menuHeight}
              onChange={(options) =>
                onDynamicFiltersChange(options, "dynamicFilters", "name")
              }
              isClearable={false}
              cy="edit-drill-down-filtering"
            />

            {/* <Select
              label="Filter key (optional)"
              options={[{ name: "None" }, ...fields]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              value={filteredByValueOption}
              menuHeight={menuHeight}
              onChange={(option) =>
                onDynamicFiltersChange(option, "dynamicFilterValue", "name")
              }
              isClearable={false}
            /> */}

            {showParentKey && (
              <div>
                <Input
                  value={parent}
                  onChange={changeParentKey}
                  label="Parent Key (Expand Label)"
                />
                <Note color={"infoDark"}>example: "Location..."</Note>
              </div>
            )}
          </SelectGroup>

          <Input
            type="color"
            value={currentChart.expandedRowBackground ?? ""}
            label="Level color"
            style={{ padding: 0, marginBottom: 20 }}
            onChange={(e) =>
              onDynamicFiltersChange(
                { color: e.target.value },
                "expandedRowBackground",
                "color"
              )
            }
          />

          <Flex
            justifyContent={chart.addAction ? "flex-end" : "space-between"}
            alignItems="center"
            mt2
          >
            {!chart.addAction && (
              <RemoveContainer onClick={confirm.setConfirming}>
                <FontAwesomeIcon icon={["fas", "trash"]} />
                Remove
              </RemoveContainer>
            )}
            <IOButton
              smallPadding
              onClick={() => saveDrilldown(level)}
              disabled={disabled}
              cy="save-drilldown-level"
            >
              Save
            </IOButton>
          </Flex>
        </Container>
      </Modal>
      {confirm.confirming}
    </>
  );
}

const menuHeight = 150;
