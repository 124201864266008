import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { Fragment, useCallback } from "react";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import { FILTER_TYPE_DATE_RANGE } from "../../utils/constants/constants";
import { absoluteDate } from "../../utils/dates/dateFunc";
import { isMultiRangeFilterType } from "../../utils/func";
import { useSelector } from "react-redux";
import menuFilterSettingsFormatConverter from "../../utils/formatConverters/menuFilterSettingsFormatConverter";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterButton = styled.div(
  ({ theme }) => `
  background: ${theme.menuPrimary ? theme.gray.gray100 : theme.gray.gray300};
  color: black;
  display: flex;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px;
  align-items: center;
  margin-right: 7px;
  margin-bottom: 5px;
  position: relative;
`
);

const CloseIcon = styled.span`
  margin: 2px 0 0 10px;
  &:hover {
    color: ${(props) => props.theme.notification.errorDark};
    cursor: pointer;
  }
`;

const Label = styled.span`
  overflow-wrap: anywhere;
`;

const InStoreIcon = styled.div(
  ({ theme }) => `
  position: absolute;
  left: -4px;
  color: ${
    theme.menuPrimary ? lightenDarkenColor(theme.primary, 50) : theme.primary
  };
`
);

const RangeDash = styled.span`
  margin-right: 5px;
`;

export default function SelectedFilters({
  filter = {},
  select,
  showFilters,
  additionalTheme,
}) {
  const { required = {} } = additionalTheme || {};
  const menuFiltersFromApi = useSelector((state) =>
    menuFilterSettingsFormatConverter.response.toLocal(
      state.auth.menuFilterSettings.data
    )
  );

  const renderRangeDash = useCallback(
    (index) => {
      if (isMultiRangeFilterType(filter.type) && index === 0) {
        return <RangeDash>-</RangeDash>;
      }

      return null;
    },
    [filter.type]
  );

  if (showFilters) {
    return null;
  }

  function getLabel(item) {
    if (filter.type === FILTER_TYPE_DATE_RANGE) {
      return format(absoluteDate(item.value), "MM/dd/yyyy");
    }

    return item.label;
  }

  function hasBookmarkIcon(item, uuid) {
    return (menuFiltersFromApi[uuid] || []).includes(item.value);
  }

  return (
    <Container>
      {filter.values
        ?.filter((item) => item.checked)
        .map((item, i) => (
          <Fragment key={(item.value ?? item.key ?? item) + i}>
            <FilterButton>
              {hasBookmarkIcon(item, filter.uuid) && (
                <InStoreIcon>
                  <FontAwesomeIcon icon={["fas", "bookmark"]} />
                </InStoreIcon>
              )}
              <Label>{getLabel(item)}</Label>
              {!required[filter.uuid] && (
                <CloseIcon>
                  <FontAwesomeIcon
                    icon={["fa", "times"]}
                    onClick={() => select(item, item.type)}
                  />
                </CloseIcon>
              )}
            </FilterButton>
            {renderRangeDash(i)}
          </Fragment>
        ))}
    </Container>
  );
}
