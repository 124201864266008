import {
  visualizationFormatConverter,
  shouldVisualizationFormItemBeIncluded,
} from "./charts/visualizationFormat";

export const blockFormatConverter = {
  apiResponse: {
    toForm(data) {
      return {
        title: data.title || data.segmentTitle || "",
        segmentTitle: "",
        displayName: data.displayName ?? "",
        width: data.width ?? "",
        hide: data.hide ?? false,
        ttg: data.ttg ?? false,
        styles: data.styles ? JSON.stringify(data.styles) : "",
        visualizations: data.visualizations.map(
          visualizationFormatConverter.response.toForm
        ),
      };
    },
    toLocal(data) {
      const { visualizations, ...restData } = data;

      return {
        ...restData,
        charts: visualizations.map(
          visualizationFormatConverter.response.toLocal
        ),
      };
    },
  },
  form: {
    toRequest: convertFormToRequest,
  },
};

function convertFormToRequest(formData) {
  const { visualizations, ...ret } = formData;
  ret.visualizations = formData.visualizations
    .filter(shouldVisualizationFormItemBeIncluded)
    .map(visualizationFormatConverter.form.toRequest);
  return ret;
}
