import React from "react";
import { Route, Switch } from "react-router";
import QueryExplorer from "./QueryExplorer";
import useExplorerFilters from "./hooks/useExplorerFilters/useExplorerFilters";

export default function ExplorerHomeOptions(props) {
  const {
    runQuery,
    lastSettings,
    allFields,
    activeQuery,
    dispatch,
    saveQuery,
    activeSettings,
    // setShowSavedList,
    setShowPanel,
    showPanel,
    selectedQuery,
    setSelectedQuery,
    explorerMode,
  } = props;

  let filterApi = useExplorerFilters(activeSettings?.data);

  const queryExplorer = (
    <QueryExplorer
      {...runQuery}
      lastSettings={lastSettings}
      execSettingsForm={saveQuery}
      allFields={allFields}
      activeQuery={activeQuery}
      dispatch={dispatch}
      settings={activeSettings}
      setShowPanel={setShowPanel}
      showPanel={showPanel}
      // showSavedList={() => setShowSavedList(true)}
      selectedQuery={selectedQuery}
      setSelectedQuery={setSelectedQuery}
      filterApi={filterApi}
      explorerMode={explorerMode}
    />
  );
  return (
    <Switch>
      {/*<Route path="/admin/data-explorer/chart-builder" exact>*/}
      {/*  <ChartBuilderHome*/}
      {/*    selectedQuery={selectedQuery}*/}
      {/*    setSelectedQuery={setSelectedQuery}*/}
      {/*    filterApi={filterApi}*/}
      {/*  />*/}
      {/*</Route>*/}

      <Route path="/admin/data-explorer/new/:queryId">{queryExplorer}</Route>

      <Route path="/admin/data-explorer/:settingsUuid">{queryExplorer}</Route>
    </Switch>
  );
}
