import React from "react";
import styled from "@emotion/styled";
import Input from "../../../../UI/Form/Input/Input";
import { getRidOfAggregation } from "../EditableMenu";

const Container = styled.div`
  label {
    color: black;
  }
  input {
    height: 38px;
    width: 100%;
  }
`;

function DisplayName(props) {
  const { visualization, setVisualization, column, children } = props;

  const changeDisplayName = (e) => {
    setVisualization((prev) => {
      return {
        ...prev,
        overrides: prev.overrides.map((override) =>
          override.name === getRidOfAggregation(column)
            ? {
                ...override,
                mapping: { ...override.mapping, displayName: e.target.value },
              }
            : override
        ),
      };
    });
  };

  const override = visualization.overrides.find(
    (override) => override.name === getRidOfAggregation(column)
  );

  return (
    <Container>
      <Input
        value={override?.mapping?.displayName ?? children}
        onChange={changeDisplayName}
        inPlaceEdit
        whiteBg
        label="Display Name"
      />
    </Container>
  );
}

export default DisplayName;
