export const writeBacksFormatConverter = {
  response: {
    toForm(value, metaField = {}) {
      if (metaField.type === "date" || metaField.type === "date-iso") {
        return value && String(value).substring(0, 10);
      }

      return value === null ? "" : value;
    },
  },
};
