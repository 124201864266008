import React, { useEffect } from "react";
import * as actions from "../../../../store/actions/index";
import ExplorerLeftTray from "../DataExplorerUI/ExplorerLeftTray";
import SavedExplorerItem from "./SavedExplorerItem";
import styled from "@emotion/styled";
import { scrollbarConfig } from "../../../../styles/styledSnippets";

const ListContainer = styled.div`
  height: calc(100vh - 140px);
  overflow: auto;
  padding-right: 24px;
  ${scrollbarConfig({ thumbBorder: false, size: ".4em" })};
`;
export default function SavedExplorerList(props) {
  const {
    handleClose,
    handleSelect,
    dispatch,
    data,
    loading,
    errors,
    deleting,
  } = props;
  const list = data;
  useEffect(() => {
    dispatch(actions.loadQueryExecSettings());
  }, [dispatch]);

  const handleClick = (uuid) => {
    handleSelect(uuid);
    handleClose();
  };

  const handleDelete = (uuid) => {
    dispatch(actions.deleteQueryExec(uuid));
  };

  return (
    <ExplorerLeftTray
      cy="saved-explorer-list"
      title="Saved Settings"
      loading={loading}
      handleClose={handleClose}
      list
    >
      <ListContainer>
        {list?.length ? (
          list.map((item) => (
            <SavedExplorerItem
              onClick={() => handleClick(item.uuid)}
              handleDelete={() => handleDelete(item.uuid)}
              deleting={deleting === item.uuid}
              active={props.activeSettings?.uuid === item.uuid}
              {...item}
              key={item.uuid}
            />
          ))
        ) : (
          <div>No settings have been saved (yet)</div>
        )}
        {errors ? <div>Something went wrong</div> : null}
      </ListContainer>
    </ExplorerLeftTray>
  );
}
