export default (fields = [], name) => {
  const { splitByLabel } = fields.find((f) => f.splitByAlias === name) ?? {};

  const field = fields.find((f) => f.alias === name || f.name === name);
  return splitByLabel || field?.label || name;
};

export const mapping = (fields = [], name) => {
  const splitBy = fields.find((f) => f.splitByAlias === name);
  const field = fields.find((f) => f.alias === name || f.name === name);

  if (splitBy) {
    return { ...splitBy, label: splitBy.splitByLabel };
  }

  return field || name; // @todo do we make this act as a default here?
};

export const getFieldNameByLabel = (fields = [], label) => {
  const { alias, name } = fields.find((f) => f.label === label);

  return alias || name;
};

export const getFieldTypeByAlias = (fields = [], alias) =>
  fields.find((f) => f.alias === alias)?.type || "";

export const isFieldHidden = (fields = [], name) => {
  return !!mapping(fields, name)?.hideOnTables;
};

export const getMappingType = (fields = [], name) => {
  return fields.find((f) => f.alias === name || f.name === name)?.type;
};

export const getVisualizationHeaderDescription = (fields = [], name) => {
  const field = fields.find((f) => f.alias === name || f.name === name);
  return field ? field.description : name;
};
