import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import DataNotAvailable from "../DataNotAvailable/DataNotAvailable";
import Loading from "../../UI/Loading/Loading";
import Refreshing from "./Refreshing";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import { scrollbar } from "../../styles/styledSnippets";
import { REQUEST_CANCELLED } from "../../utils/errorHandling";

const ChartContainerContainer = styled.div`
  height: ${(props) => props.height}px;
  margin: 0 auto;
  width: ${(props) => props.width || "99%"};
  min-width: 80px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    margin: 0;
    width: 100%;
  }
  ${scrollbar}
  ${(props) =>
    props.fixedHeight
      ? `
    max-height: ${props.fixedHeight}px;
    overflow-y: auto
    `
      : ""}
`;

export default function ChartContainer(props) {
  const loaderComponent = <Loading />;

  const loading =
    props.loading || props.error?.type === REQUEST_CANCELLED
      ? loaderComponent
      : null;
  const hasNoData = props.refreshing ? (
    ""
  ) : !props.hasData || props.restricted ? (
    <DataNotAvailable
      text={props.error}
      restricted={props.restricted}
      noResultsMessageOverride={props.noResultsMessageOverride}
    />
  ) : null;

  const cy = loading
    ? "container-loading"
    : props.refreshing
    ? "container-refreshing"
    : hasNoData
    ? "container-no-data"
    : "active-container";

  return (
    <ChartContainerContainer
      width={props.width}
      height={props.autoHeight ? "auto" : props.height}
      data-cy={cy}
      data-cy-2="chart-container"
      fixedHeight={props.fixedHeight}
    >
      {loading || hasNoData || props.children}
      <Refreshing
        show={!!props.refreshing && !loading}
        comp={loaderComponent}
      />
    </ChartContainerContainer>
  );
}

ChartContainer.defaultProps = {
  height: 444,
};

ChartContainer.propTypes = {
  height: PropTypes.number,
  hasData: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  refreshing: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
};
