import Select from "../../../../../UI/Form/Select/Select";
import { columnFormats } from "../../../../../utils/constants/constants";

export default function Format({ chart, setChartState }) {
  const { groupingExtraColumns } = chart;
  const { format } = groupingExtraColumns;

  const nestedFormat = columnFormats.find((group) =>
    group.options.find((option) => option.value === format)
  );

  function onFormatChange(option) {
    setChartState({
      ...chart,
      groupingExtraColumns: { ...groupingExtraColumns, format: option.value },
    });
  }

  return (
    <Select
      options={columnFormats}
      value={nestedFormat}
      onChange={onFormatChange}
      label="Format"
      menuPlacement="top"
    />
  );
}
