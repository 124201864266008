import styled from "@emotion/styled";
import FilterPanel from "../../../../Editors/BlockEdit/VisualizationGuiEditSection/FilterPanel";
import convertFiltersToOperatorsMode, {
  convertFiltersToConfigFormat,
} from "../../../../Editors/BlockEdit/VisualizationGuiEditSection/convertFiltersToOperatorsMode";
import { useFilters } from "../../../DataManagement/DataExplorer/hooks/useExplorerFilters/useFilters";
import shouldRunQuery from "../../../DataManagement/DataExplorer/hooks/useExplorerFilters/shouldRunQuery";
import usePrevious from "../../../../utils/usePrevious";
import { useEffect } from "react";

const Container = styled.div`
  padding: 10px;
`;

const settings = { filters: [] };

export default function Filtering(props) {
  const { chart, setChartState } = props;
  const converted = convertFiltersToOperatorsMode(chart.filters ?? []);
  const filterApi = useFilters(converted);
  const draftFilters = filterApi.draftFilters;
  const prevFilters = usePrevious(draftFilters);

  const run = shouldRunQuery(
    settings,
    settings,
    chart.queryId,
    draftFilters,
    prevFilters
  );

  useEffect(() => {
    if (run) {
      const filters = convertFiltersToConfigFormat(draftFilters);
      setChartState({ ...chart, filters });
    }
  }, [draftFilters, setChartState, run, chart]);

  return (
    <Container>
      <FilterPanel filterApi={filterApi} queryId={chart.queryId} />
    </Container>
  );
}
