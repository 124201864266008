import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGlobalStickyHeaders,
  updateTablePagination,
} from "../../store/actions";
import Pagination from "../../UI/Pagination/Pagination";
import { setLimitValue } from "./functions/tableMapper";
import TableVisualization from "./TableVisualization";
import { debounce } from "lodash-es";

const layoutSelectorCallback = (state) => state.layout;

export default function TableParentVisualization(props) {
  const dispatch = useDispatch();
  const {
    dateFilters,
    menuFilters,
    comparisonMode,
    activeTab,
    dateFiltersConfig,
    domain,
    isGlobalSticky,
  } = useSelector(layoutSelectorCallback);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState(null);

  const [sticky, setSticky] = useState(
    props.chart.stickyHeaders && !props.fromFullDetails
  );

  const {
    meta,
    data = [],
    showTotalRows,
    displayRows,
    connectWithQueryParameter,
    globalStickyHeaders,
    loading,
    refreshing,
    queryLevelPaginatedSorting,
  } = props.chart;

  // how many rows user can see based on dynamic display Rows
  const [limit, setLimit] = useState(displayRows ?? props.chart.limit);

  const { pagination } = meta ?? {};
  const pages = pagination?.last_page ?? data[0]?.LastPage;
  const totalRows = pagination?.total ?? data[0]?.TotalRows;

  useEffect(() => {
    if (displayRows) {
      setLimit(displayRows);
    }
  }, [displayRows]);

  useEffect(() => {
    if (isGlobalSticky !== !!globalStickyHeaders && !loading) {
      dispatch(setGlobalStickyHeaders(!!globalStickyHeaders));
    }
  }, [dispatch, isGlobalSticky, globalStickyHeaders, loading]);

  const setSortFilters = (sortBy, sortASCDESC) => {
    if (!sortBy) {
      return props.chart;
    }

    const configFilters = props.chart.filters || [];

    // CONVENTION: for parameterized query with pagination
    // we should use @sortBy and @sortASCDESC as filter types
    // this need to do not make any additional props in chart config
    const sortFilters = [
      { type: "sortBy", value: sortBy },
      { type: "sortASCDESC", value: sortASCDESC },
    ];

    const chart = {
      ...props.chart,
      filters: [...configFilters, ...sortFilters],
    };

    setSort({ sortBy, sortASCDESC });
    return chart;
  };

  const sortPaginated = (sortBy, sortASCDESC) => {
    const chart = setSortFilters(sortBy, sortASCDESC);
    dispatch(updateTablePagination(chart, page));
  };

  const onPaginationChange = (page) => {
    setPage(page);
    const chart = setSortFilters(sort?.sortBy, sort?.sortASCDESC);
    dispatch(updateTablePagination({ ...chart, limit }, page));
  };

  const datesConfig = {
    ...dateFilters,
    hideOnPages: dateFiltersConfig.hideOnPages,
  };

  const rowsQuantity = connectWithQueryParameter ? totalRows : data.length;

  const updateTableWithDelay = useMemo(() => {
    return debounce((chart) => dispatch(updateTablePagination(chart, 1)), 500);
  }, [dispatch]);

  const setDisplayRowsLimit = (e) => {
    const limit = setLimitValue(+e.target.value, +rowsQuantity);
    setLimit(limit);
    setPage(1);

    if (connectWithQueryParameter) {
      const chart = {
        ...props.chart,
        limit,
      };

      updateTableWithDelay(chart);
    }
  };

  const stickyInPowerEditor =
    props.chart.stickyHeaders && !props.fromFullDetails;

  return (
    <>
      <TableVisualization
        {...props}
        dateFilters={datesConfig}
        sortPaginated={queryLevelPaginatedSorting ? sortPaginated : null}
        sticky={sticky ?? stickyInPowerEditor}
        setSticky={setSticky}
        menuFilters={menuFilters}
        comparisonMode={comparisonMode}
        activeTab={activeTab}
        limit={limit}
        setLimit={setDisplayRowsLimit}
        rowsQuantity={rowsQuantity}
        domain={domain}
        isLoading={loading || refreshing}
      />

      {pages > 1 && (
        <Pagination
          currentPage={page}
          first={page === 1 ? null : () => onPaginationChange(1)}
          last={page === pages ? null : () => onPaginationChange(pages)}
          previous={page === 1 ? null : () => onPaginationChange(page - 1)}
          next={page === pages ? null : () => onPaginationChange(page + 1)}
          totalRows={totalRows}
          showTotalRows={showTotalRows}
        />
      )}
    </>
  );
}
