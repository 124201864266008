import Flex from "../../../../../UI/Flex/Flex";
import Select from "../../../../../UI/Form/Select/Select";
import { getRidOfAggregation } from "../../../../../charts/TableView/Elements/EditableMenu";
import { aggregations } from "../../../../../utils/constants/constants";
import { getOverrideByName } from "../helper";

export default function GroupingKeyContainer(props) {
  const { isParameterized, chart, setChartState, repeatingOptions } = props;
  const { groupingKey, overrides } = chart;

  const nonAggregatedGroupingKey = getRidOfAggregation(groupingKey);
  // set groupingKey
  function setGroupingKeyColumn(option) {
    const inOverrides = getOverrideByName(overrides, option.value);

    setChartState({
      ...chart,
      groupingKey: option.value,
      ...(!inOverrides && {
        overrides: [
          ...overrides.filter(
            (override) => override.name !== nonAggregatedGroupingKey
          ),
          { name: option.value },
        ],
      }),
    });
  }

  // get aggregation
  function getGroupingKeyAggregation() {
    const { aggregation } =
      getOverrideByName(overrides, nonAggregatedGroupingKey) ?? {};

    return (
      aggregations.find(
        (aggr) => aggr.value === aggregation || aggr.label === aggregation
      ) || {
        label: "None",
        prefix: "",
      }
    );
  }

  // set aggregation
  function setGroupingKeyAggregation(option) {
    setChartState({
      ...chart,
      groupingKey: option.prefix + nonAggregatedGroupingKey,
      overrides: overrides.map((override) =>
        override.name === nonAggregatedGroupingKey
          ? { ...override, aggregation: option.value }
          : override
      ),
    });
  }

  return (
    <>
      <Flex justifyContent="space-between">
        <div style={{ width: "60%" }}>
          <Select
            options={repeatingOptions}
            value={repeatingOptions.find(
              (ro) => ro.value === nonAggregatedGroupingKey
            )}
            label="Repeat on column (A, B, ...)"
            onChange={setGroupingKeyColumn}
            placeholder={groupingKey}
          />
        </div>

        {!isParameterized && (
          <div style={{ width: "35%" }}>
            <Select
              options={[{ label: "None", prefix: "" }, ...aggregations]}
              value={getGroupingKeyAggregation()}
              label="Aggregate"
              onChange={setGroupingKeyAggregation}
              cy="select-column-aggregation"
              isDisabled={!groupingKey}
            />
          </div>
        )}
      </Flex>
      <br />
    </>
  );
}
