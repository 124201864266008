import React, { useLayoutEffect, useRef, useState, forwardRef } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import useLabel from "../LabelWrapper/useLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const border = ({ invalid, theme, transparent }) => {
  if (transparent) {
    return "transparent";
  }

  if (invalid) {
    return theme.notification.errorMain;
  }

  if (theme.type === "light") {
    return theme.gray.gray300;
  }

  return theme.gray.gray800;
};

const getBackground = ({ transparent, theme }) => {
  if (transparent) {
    return "none";
  }

  if (theme.type === "light") {
    return "white";
  }

  return theme.gray.gray800;
};

const InputField = styled.input`
  padding: ${(props) => (props.small ? "5px" : "6px 12px")};
  border-radius: 2px;
  border: 1px solid ${border};
  box-sizing: border-box;
  font-size: ${(props) =>
    props.large ? "1.2em" : props.small ? "14px" : "16px"};
  color: ${(props) => props.theme.text.primary};
  background: ${(props) => getBackground(props)};
  &::placeholder {
    color: ${(props) => props.theme.gray.gray500};
  }
  &:focus {
    outline: none;
    border-color: ${({ theme, transparent }) =>
      transparent ? "transparent" : lightenDarkenColor(theme.primary, 10)};
    ${({ theme, transparent }) =>
      transparent ? `color: ${theme.text.secondary};` : ""}
  }
  ${(props) => (props.transparent ? `color: ${props.theme.text.primary};` : "")}
  ${(props) => (props.fit ? "width: 100%;" : "")}
`;

const DisplayField = styled.div`
  padding: 5px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${(props) => (props.small ? 14 : 16)}px;
  font-weight: ${(props) => (props.small ? 300 : 700)};
  color: ${(props) => props.theme.text.primary};
  color: ${(props) => (props.whiteBg ? "black" : props.theme.text.primary)};
`;

export const ErrorField = styled.div(
  ({ theme }) => `
    font-size: 12px;
    color: ${theme.notification.errorMain};
    display: flex;
    justify-content: flex-end;
    padding: 5px 20px;
`
);

const IconField = styled.span`
  margin-left: -24px;
  color: ${(props) => props.theme.text.secondary};
  font-size: 12px;
`;

const Loader = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: -32px;
  }
`;

const Input = forwardRef((props, ref) => {
  const {
    focus,
    inPlaceEdit,
    ignoreValue,
    cy,
    onBlur,
    onFocus,
    label,
    errorMessage,
    required,
    hideEditIcon,
    overPrimary,
    search,
    whiteBg,
    tooltip,
    small,
    setFocus,
    hookFormValue,
    loading,
    ...rest
  } = props;

  let inputRef = useRef(null);
  const activeValue = ref ? hookFormValue : props.value;
  if (ref) {
    inputRef = ref;
  }
  const [editing, setEditing] = useState(false);
  const withLabel = useLabel(label, required, overPrimary, tooltip);

  useLayoutEffect(() => {
    if (focus || editing) {
      if (ref) {
        setFocus && setFocus();
      } else {
        inputRef.current && inputRef.current.focus();
      }
    }
  }, [focus, editing, setFocus, ref]);

  const handleBlur = (evt) => {
    if (onBlur) {
      onBlur(evt);
    }
    setEditing(false);
  };

  const handleKeyPress = (e) => {
    if (editing && e.key === "Enter") {
      setEditing(false);
      if (onBlur) onBlur(e);
    }
  };

  function handleDisplayClick() {
    if (onFocus) onFocus();
    setEditing(true);
  }

  function handleFocus() {
    setEditing(true);
  }

  const displayMode =
    inPlaceEdit &&
    (props.disabled || ((activeValue || ignoreValue) && !editing));

  const el = displayMode ? (
    <DisplayField
      onClick={handleDisplayClick}
      data-cy={cy}
      whiteBg={whiteBg}
      small={small}
      style={rest.style}
    >
      {activeValue}
      <span
        style={{
          paddingLeft: 8,
          fontSize: 13,
          opacity: 0.5,
          cursor: "pointer",
        }}
      >
        {!hideEditIcon && <FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
      </span>
    </DisplayField>
  ) : (
    <>
      <InputField
        onKeyPress={handleKeyPress}
        data-cy={cy}
        ref={inputRef}
        onBlur={handleBlur}
        required={required}
        small={small}
        onFocus={handleFocus}
        {...rest}
      />
      {errorMessage &&
        (!Array.isArray(errorMessage) ? [errorMessage] : errorMessage).map(
          (message, index) => (
            <ErrorField key={index}>{message.message ?? message}</ErrorField>
          )
        )}
      {search ? (
        <IconField>
          <FontAwesomeIcon icon={["fas", "search"]} />
        </IconField>
      ) : null}
      {loading && (
        <Loader>
          <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
        </Loader>
      )}
    </>
  );

  return withLabel(el);
});

Input.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  invalid: PropTypes.bool,
  focus: PropTypes.bool,
  fit: PropTypes.bool,
  inPlaceEdit: PropTypes.bool,
  ignoreValue: PropTypes.bool,
  transparent: PropTypes.bool,
  large: PropTypes.bool,
  cy: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  required: PropTypes.bool,
  search: PropTypes.bool,
  whiteBg: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default Input;
