import * as actions from "../actions/actionTypes";
import produce from "immer";

const initialState = {
  queryMonitoring: {
    loading: false,
    list: [],
  },
  salesforceReports: {
    loading: false,
    list: [],
  },
  salesforceReportColumns: {
    loading: false,
    columns: [],
  },
  redirect: false,
  charts: {},
  summaryLoading: false,
  drilldownLoading: false,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case actions.MONITORING_QUERY_LIST_INIT:
      draft.redirect = false;
      break;

    case actions.MONITORING_QUERY_LIST_START:
      draft.queryMonitoring.loading = true;
      draft.queryMonitoring.list = initialState.queryMonitoring.list;
      break;

    case actions.MONITORING_QUERY_LIST_SUCCESS:
      draft.queryMonitoring.loading = false;
      draft.queryMonitoring.list = action.results.data;
      break;

    case actions.MONITORING_QUERY_LIST_FAIL:
      draft.queryMonitoring.loading = false;
      break;

    case actions.MONITORING_QUERY_GET_SALESFORCE_REPORTS_START:
      draft.salesforceReports.loading = true;
      draft.salesforceReports.list = [];
      break;

    case actions.MONITORING_QUERY_GET_SALESFORCE_REPORTS_SUCCESS:
      draft.salesforceReports.loading = false;
      draft.salesforceReports.list = action.results.data;
      break;

    case actions.MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_SUCCESS:
      draft.salesforceReportColumns.columns = action.results.data;
      break;

    case actions.MONITORING_QUERY_CREATE_SUCCESS:
      draft.queryMonitoring.list.push(action.results.data);
      draft.redirect = true;
      break;

    case actions.MONITORING_QUERY_DELETE_SALESFORCE_REPORT_SUCCESS:
      draft.queryMonitoring.list = draft.queryMonitoring.list.filter(
        (q) => q.uuid !== action.meta.api.payload.uuid
      );
      break;

    case actions.CHARTS_MONITORING_START: {
      draft[action.loadingType] = !action.isLast;
      break;
    }

    case actions.CHARTS_MONITORING_SUCCESS: {
      const {
        results: { duration, data },
        visualizationId,
      } = action;

      const time = duration / 1000;

      draft.charts[visualizationId] = {
        duration: time,
        data,
        loaded: true,
        type: getExecutionTimeType(time, true),
      };
      break;
    }

    case actions.CHARTS_MONITORING_FAIL: {
      const { duration, visualizationId } = action;
      const time = duration / 1000;

      draft.charts[visualizationId] = {
        duration: time,
        loaded: false,
        data: [],
        type: getExecutionTimeType(time, false),
      };
      break;
    }

    default:
      return draft;
  }
});

const FAST = 1.5; // second
const OK = 3; // seconds

function getExecutionTimeType(time, loaded) {
  if (!loaded) {
    // not loaded
    return "FAILED";
  }

  if (time < FAST) {
    // fast
    return "FAST";
  }

  if (time > FAST && time < OK) {
    // ok
    return "OK";
  }

  return "SLOW"; // slow
}
