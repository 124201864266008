import * as actionTypes from "../../actions/actionTypes";
import produce from "immer";
import { get, set } from "lodash-es";

function getRunQueryInitialState() {
  return {
    loading: false,
    error: null,
    results: [],
    fields: [],
  };
}

export const initialState = {
  loading: {
    queries: false,
    queryables: false,
    dataSourceData: false,
    dataSourceDataSaving: false,
    dataSourceFields: false,
    query: false,
    updateDataSource: false,
  },
  itemLoading: null,
  queryables: [],
  active: null,
  queries: [],
  tinyQueries: [],
  rescanFields: [],
  missingFields: [],
  rescanLoading: false,
  addMissingFieldsLoading: false,
  runQuery: getRunQueryInitialState(),
  runQueryByKey: {},
  dataSourceFields: {},
  blendedQueryLoading: false,
  sampleRun: [],
  sampleRunError: null,
  sampleRunLoading: false,
  sampleRunMeta: null,
  dataEditor: {
    dataSourceData: [],
    fields: [],
    nextUrl: null,
    currentPage: null,
    totalPages: null,
    errors: [],
  },
};

const pendingControllers = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LIST_QUERYABLES_START: {
      return {
        ...state,
        loading: {
          ...state.loading,
          queryables: true,
        },
      };
    }

    case actionTypes.LIST_QUERYABLES_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          queryables: false,
        },
        queryables: action.results.data,
      };
    }

    case actionTypes.GET_QUERYABLE_START: {
      return { ...state, itemLoading: action.id };
    }

    case actionTypes.GET_QUERYABLE_SUCCESS: {
      return {
        ...state,
        itemLoading: null,
        active: action.results.data,
      };
    }

    case actionTypes.LIST_QUERIES_START: {
      return { ...state, loading: { ...state.loading, queries: true } };
    }

    case actionTypes.LIST_QUERIES_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, queries: false },
        queries: action.results.data.map(decorateParameterizedQuery),
        queriesLoaded: true,
      };
    }

    case actionTypes.LIST_QUERIES_TINY_SUCCESS: {
      return {
        ...state,
        tinyQueries: action.results.data.map(decorateParameterizedQuery),
      };
    }

    case actionTypes.LIST_QUERIES_FAIL: {
      return {
        ...state,
        loading: { ...state.loading, queries: false },
      };
    }

    case actionTypes.GET_QUERY_SUCCESS: {
      const activeQuery = decorateParameterizedQuery(action.results.data);

      return {
        ...state,
        activeQuery,
        loading: {
          ...state.loading,
          query: false,
        },
      };
    }

    case actionTypes.GET_QUERY_RESET: {
      return produce(state, (state) => {
        delete state.activeQuery;
      });
    }

    case actionTypes.SET_ACTIVE_EXEC_SETTINGS: {
      const activeQuery = state.queries.find(
        (q) => q.uuid === action.queryUuid
      );
      return { ...state, activeQuery };
    }

    case actionTypes.CLOSE_QUERYABLE: {
      return { ...state, active: null };
    }

    case actionTypes.DELETE_QUERY_SUCCESS: {
      return {
        ...state,
        queries: state.queries.filter((query) => query.uuid !== action.results),
        active: null,
      };
    }

    case actionTypes.RESCAN_DATA_SOURCE_SUCCESS: {
      return {
        ...state,
        rescanFields: action.results.data.newFields,
        missingFields: action.results.data.missingFields,
        typeChanged: action.results.data.typeChanged,
        rescanLoading: false,
      };
    }

    // By providing a "key", you can execute and store multiple queries
    // (and their results) with different settings.
    case actionTypes.RUN_QUERY_START: {
      const key = action.key ?? "";
      if (pendingControllers[key]) {
        pendingControllers[key].abort();
        delete pendingControllers[key];
      }
      if (action.pendingController) {
        pendingControllers[key] = action.pendingController;
      }
      const path = getRunQueryPathByKey(key);
      return produce(state, (draft) => {
        let runQueryDraft;
        if (!get(state, path)) {
          runQueryDraft = getRunQueryInitialState();
          set(draft, path, runQueryDraft);
        } else {
          runQueryDraft = get(draft, path);
        }
        runQueryDraft.loading = true;
        runQueryDraft.error = null;
      });
    }

    case actionTypes.RUN_QUERY_SUCCESS: {
      const key = action.key ?? "";
      const path = getRunQueryPathByKey(key);
      return produce(state, (draft) => {
        const runQueryDraft = get(draft, path);
        if (!action?.results?.meta) {
          // @todo this should not run without meta info...
          // @todo how to get a message to the developer what happened
          runQueryDraft.loading = false;
          return;
        }

        runQueryDraft.loading = false;
        runQueryDraft.results = action.results.data;
        runQueryDraft.fields = action.results.meta.fields;
        runQueryDraft.totalRecords = action.results?.meta?.pagination?.total;
        if (key === "") {
          draft.lastSettings = action.settings;
        }
      });
    }

    case actionTypes.RUN_QUERY_FAIL: {
      const key = action.key ?? "";
      const path = getRunQueryPathByKey(key);
      return produce(state, (draft) => {
        const runQueryDraft = get(draft, path);
        runQueryDraft.error = action.error?.message;
        runQueryDraft.loading = false;
        runQueryDraft.results = null;
      });
    }

    case actionTypes.RUN_QUERY_EXPORT_START:
    case actionTypes.RUN_QUERY_EXPORT_SUCCESS:
    case actionTypes.RUN_QUERY_EXPORT_FAIL:
      return { ...state };

    case actionTypes.RUN_QUERY_RESET: {
      return { ...state, runQuery: getRunQueryInitialState() };
    }

    case actionTypes.GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          dataSourceFields: true,
        },
      };

    case actionTypes.GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_SUCCESS:
      const temp = {};
      action.results.sourceFields.forEach(
        (dsf) => (temp[dsf.uuid] = dsf.fields)
      );

      return {
        ...state,
        dataSourceFields: temp,
        loading: {
          ...state.loading,
          dataSourceFields: false,
        },
      };

    case actionTypes.CREATE_QUERY_START:
      return {
        ...state,
        blendedQueryLoading: true,
      };

    case actionTypes.CREATE_QUERY_SUCCESS:
    case actionTypes.CREATE_QUERY_FAIL:
      return {
        ...state,
        blendedQueryLoading: false,
      };

    case actionTypes.UPDATE_QUERY_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          queries: true,
        },
      };

    case actionTypes.UPDATE_QUERY_SUCCESS:
    case actionTypes.UPDATE_QUERY_FAIL:
      return {
        ...state,
        loading: {
          ...state.loading,
          queries: false,
        },
      };

    case actionTypes.RESCAN_DATA_SOURCE_START:
      return {
        ...state,
        rescanLoading: true,
      };

    case actionTypes.RESCAN_DATA_SOURCE_FAIL:
      return {
        ...state,
        rescanLoading: false,
      };

    case actionTypes.ADD_MISSING_FIELDS_START:
      return {
        ...state,
        addMissingFieldsLoading: true,
      };

    case actionTypes.ADD_MISSING_FIELDS_SUCCESS:
    case actionTypes.ADD_MISSING_FIELDS_FAIL:
      return {
        ...state,
        addMissingFieldsLoading: false,
        rescanFields: [],
      };

    case actionTypes.GET_UNIQUE_SUCCESS:
      return {
        ...state,
        explore: { uniques: action.results },
      };

    case actionTypes.GET_DATASOURCE_DATA_INIT:
      return {
        ...state,
        dataEditor: initialState.dataEditor,
      };

    case actionTypes.GET_DATASOURCE_DATA_START:
      return {
        ...state,
        loading: { ...state, dataSourceData: true },
      };

    case actionTypes.GET_DATASOURCE_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          ...state,
          dataSourceData: action.results.links.next !== null,
        },
        dataEditor: {
          ...state.dataEditor,
          dataSourceData: [
            ...state.dataEditor.dataSourceData,
            ...action.results.data,
          ],
          fields: action.results.fields,
          nextUrl: action.results.links.next,
          currentPage: action.results.meta.current_page,
          totalPages: action.results.meta.last_page,
        },
      };

    case actionTypes.UPLOAD_DATASOURCE_DATA_START:
      return {
        ...state,
        loading: { ...state.loading, dataSourceDataSaving: true },
        dataSourceErrors: [],
      };

    case actionTypes.UPLOAD_DATASOURCE_DATA_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, dataSourceDataSaving: false },
      };

    case actionTypes.UPLOAD_DATASOURCE_DATA_FAIL:
      return {
        ...state,
        loading: { ...state.loading, dataSourceDataSaving: false },
        dataSourceErrors: action.errors,
      };

    case actionTypes.DELETE_DATA_SOURCE_START:
      return {
        ...state,
        loading: { ...state.loading, queryables: true },
      };

    case actionTypes.DELETE_DATA_SOURCE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, queryables: false },
        queryables: state.queryables.filter((q) => q.uuid !== action.uuid),
      };

    case actionTypes.DELETE_DATA_SOURCE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, queryables: false },
      };

    case actionTypes.GET_QUERY_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          query: true,
        },
      };

    case actionTypes.RUN_PARAMETERIZED_SAMPLE_START:
      return {
        ...state,
        sampleRunLoading: true,
        sampleRun: [],
        sampleRunMeta: null,
      };

    case actionTypes.RUN_PARAMETERIZED_SAMPLE_SUCCESS:
      const endTime = new Date();

      const sampleRunTime = endTime.getTime() - action.startTime.getTime();
      return {
        ...state,
        sampleRun: action.results.data,
        sampleRunLoading: false,
        sampleRunMeta: action.results.meta,
        sampleRunTime: sampleRunTime / 1000,
        sampleRunError: null,
      };

    case actionTypes.RUN_PARAMETERIZED_SAMPLE_FAIL:
      const sampleRunError = {
        message: action.error.message,
        code: action.error.code,
      };
      return {
        ...state,
        sampleRun: [],
        sampleRunError,
        sampleRunLoading: false,
        sampleRunMeta: null,
      };

    case actionTypes.UPDATE_DATA_SOURCE_FIELD_START:
    case actionTypes.DELETE_DATA_SOURCE_FIELD_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateDataSource: true,
        },
      };

    case actionTypes.UPDATE_DATA_SOURCE_FIELD_SUCCESS:
    case actionTypes.UPDATE_DATA_SOURCE_FIELD_FAIL:
    case actionTypes.DELETE_DATA_SOURCE_FIELD_SUCCESS:
    case actionTypes.DELETE_DATA_SOURCE_FIELD_FAIL:
      return {
        ...state,
        loading: {
          ...state.loading,
          updateDataSource: false,
        },
      };

    default:
      return state;
  }
}

function decorateParameterizedQuery(query) {
  if (query.type === "parameterized") {
    return {
      ...query,
      parameterizedFields: query.parameterizedFields || [],
    };
  } else return query;
}

function getRunQueryPathByKey(key) {
  return key !== "" ? ["runQueryByKey", key] : "runQuery";
}
