import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Checkbox from "../../../../UI/Form/Checkbox/Checkbox";
import RadioButton from "../../../../UI/RadioButton/RadioButton";

export default function Totals({
  RadioGroup,
  Group,
  theme,
  chart,
  setChartState,
}) {
  return (
    <div style={{ marginTop: 20 }}>
      <span>Totals</span>
      <RadioGroup>
        <RadioButton
          checked={!chart.totals}
          onChange={() =>
            setChartState({
              ...chart,
              totals: false,
              totalsOnly: false,
            })
          }
        />
        <label>No Totals</label>
      </RadioGroup>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Group width="30%" background={theme.divider} />
        <Group width="30%" background={theme.divider} />
        <Group width="30%" background={theme.divider} />
      </div>
      <Group background={theme.divider}>
        <FontAwesomeIcon
          icon={["far", "times-circle"]}
          style={{ color: theme.notification.errorMain }}
        />
      </Group>

      <hr color={theme.background.secondary} />

      <RadioGroup>
        <RadioButton
          checked={!!chart.totals}
          onChange={() => setChartState({ ...chart, totals: true })}
        />
        <label>Include Totals</label>

        <Checkbox
          label="Totals Only"
          checked={!!chart.totalsOnly}
          color={theme.text.secondary}
          disabled={!chart.totals}
          onChange={() =>
            setChartState({
              ...chart,
              totalsOnly: !chart.totalsOnly,
            })
          }
        />
      </RadioGroup>

      {!chart.groupingKey && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Group width="30%" background={theme.divider}>
            {chart.totalsOnly && (
              <FontAwesomeIcon
                icon={["far", "times-circle"]}
                style={{ color: theme.notification.errorMain }}
              />
            )}
          </Group>
          <Group width="30%" background={theme.divider}>
            {chart.totalsOnly && (
              <FontAwesomeIcon
                icon={["far", "times-circle"]}
                style={{ color: theme.notification.errorMain }}
              />
            )}
          </Group>
          <Group width="30%" background={theme.divider}>
            {chart.totalsOnly && (
              <FontAwesomeIcon
                icon={["far", "times-circle"]}
                style={{ color: theme.notification.errorMain }}
              />
            )}
          </Group>
        </div>
      )}
      {!chart.groupingKey && <Group background={theme.primary} />}
    </div>
  );
}
