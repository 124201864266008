import * as actionTypes from "./actionTypes";
import * as actions from "./actionTypes";
import axios from "../../axios";
import qs from "qs";
import { hideActionToast } from "./message";
import { getToken } from "../../utils/getToken";
import { loadEtlConfigs } from "./etlConfigs";
import { handleError } from "../../utils/errorHandling";

export const loadEtlProcessings = (filters, link, reload) => (dispatch) => {
  const page = link ? new URL(link).searchParams.get("page") : null; // parse pagination link to get page number
  const objectQuery = Object.fromEntries(
    Object.entries({ ...filters, ...(page && { page }) }).filter(
      ([, val]) => val
    )
  ); // removing all (false, undefined, null, 0) values

  dispatch({
    type: reload
      ? actionTypes.RELOAD_ETL_PROCESSINGS_START
      : actionTypes.GET_ETL_PROCESSINGS_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/processings${qs.stringify(objectQuery, {
          addQueryPrefix: true,
        })}`,
      },
    },
  });
};

export const uploadFile =
  (etlConfigUuid, file, notes, fullLoad) => (dispatch) => {
    dispatch({ type: actionTypes.UPLOAD_ETL_FILE_START });
    const formData = new FormData();

    formData.append("etlConfigUuid", etlConfigUuid);
    formData.append("file", file);
    formData.append("isFullLoad", fullLoad ? 1 : 0);

    if (notes) {
      formData.append("notes", notes);
    }

    return axios
      .post(`api/v1/etl_configs/${etlConfigUuid}/processings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_ETL_FILE_SUCCESS,
          results: res.data,
        });
        setTimeout(() => {
          dispatch({ type: actionTypes.RESET_ETL_FILE_UPLOAD });
        });
      })
      .catch((err) => {
        handleError(dispatch, err);
        dispatch({
          type: actionTypes.UPLOAD_ETL_FILE_FAIL,
          errors: err.response.data.errors,
        });
      });
  };

export const scheduleExport = (link, settings) => async (dispatch) => {
  dispatch({
    type: actionTypes.SCHEDULE_EXPORT_START,
    meta: {
      api: {
        method: "POST",
        endpoint: link,
        payload: settings,
      },
      toasts: [
        {
          title: "Success",
          message:
            "Your export has been scheduled and will be emailed to you soon as it is completed",
          type: "success",
          condition: "SCHEDULE_EXPORT_SUCCESS",
        },
      ],
    },
  });

  dispatch(hideActionToast());
};

export const actionWithApprover =
  (etlConfigUuid, approverUuid, isDelete) => async (dispatch) => {
    // Add or Delete approver

    dispatch({
      type: isDelete
        ? actionTypes.REMOVE_APPROVER_USER_START
        : actionTypes.ADD_APPROVER_USER_START,
    });

    try {
      await axios({
        method: isDelete ? "DELETE" : "POST",
        url: `api/v1/etl_configs/${etlConfigUuid}/${
          isDelete ? "delete_approver" : "add_approver"
        }/${approverUuid}`,
      });

      dispatch({
        type: isDelete
          ? actionTypes.REMOVE_APPROVER_USER_SUCCESS
          : actionTypes.ADD_APPROVER_USER_SUCCESS,
      });
      dispatch(loadEtlConfigs());
    } catch (err) {
      handleError(dispatch, err);
      dispatch({
        type: isDelete
          ? actionTypes.REMOVE_APPROVER_USER_FAIL
          : actionTypes.ADD_APPROVER_USER_FAIL,
      });
    }
  };

export const approveProcessing =
  (processingUuid, isApproved) => async (dispatch) => {
    dispatch({
      type: isApproved
        ? actionTypes.APPROVE_PROCESSING_STATUS_START
        : actionTypes.REJECT_PROCESSING_STATUS_START,
      meta: {
        api: {
          method: "POST",
          endpoint: `api/v1/processings/${processingUuid}/approve`,
          payload: {
            isApproved,
          },
        },
      },
    });
  };

export const previewProcessingFile = (processingUuid) => {
  return {
    type: actionTypes.PREVIEW_PROCESSING_FILE_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/processings/${processingUuid}/text-content`,
      },
    },
  };
};

export const downloadProcessingFile = (processingUuid) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_PROCESSING_FILE_START });

  try {
    downloadFile(
      `${
        axios.defaults.baseURL
      }/api/v1/processings/${processingUuid}/contents?token=${getToken()}`
    );
    dispatch({ type: actionTypes.DOWNLOAD_PROCESSING_FILE_SUCCESS });
  } catch (err) {
    dispatch({ type: actionTypes.DOWNLOAD_PROCESSING_FILE_FAIL });
  }
};

export const updateProcessing = (processing) => {
  return {
    type: actions.UPDATE_ETL_PROCESSING,
    payload: processing,
  };
};

const downloadFile = (href, fileName) => {
  const link = document.createElement("a");
  fileName && link.setAttribute("download", fileName);

  link.href = href;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
