import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertIcons } from "../../utils/constants/constants";

const Container = styled.div(
  ({ theme, status, iconPosition }) => `
    display: flex;
    flex-direction: ${iconPosition === "top" ? "column" : "row"};
    background: ${theme.notification[status + "Main"]};
    padding: 10px 10px;
    align-items: center;
    margin: 5px 0px;
    // color: ${
      theme.notification[status + "Dark"]
    }; //@todo need to confirm colors
    color: white;
`
);

const IconBox = styled.div`
  margin: 0 10px;
`;

export default function AlertMessage({ status, message, iconPosition, cy }) {
  return (
    <Container status={status} iconPosition={iconPosition} data-cy={cy}>
      <IconBox>
        <FontAwesomeIcon icon={alertIcons[status]} size="sm" />
      </IconBox>
      <span>{message}</span>
    </Container>
  );
}

AlertMessage.defaultProps = {
  status: "info",
  message: "",
  iconPosition: "left",
};

AlertMessage.propTypes = {
  status: PropTypes.string,
  message: PropTypes.node,
  iconPosition: PropTypes.oneOf(["top", "left"]),
  width: PropTypes.string,
  cy: PropTypes.string,
};
