import styled from "@emotion/styled";
import { scrollbarDe } from "../../../../../styles/styledSnippets";
import Select from "../../../../../UI/Form/Select/Select";
import {
  aggregations,
  columnFormats,
} from "../../../../../utils/constants/constants";
import Flex from "../../../../../UI/Flex/Flex";
import Input from "../../../../../UI/Form/Input/Input";
import { useState } from "react";
import IOButton from "../../../../../UI/Form/Button/IOButton";
import ButtonGroup from "../../../../../UI/ButtonGroup/ButtonGroup";

const DisplayFormula = styled.div(
  ({ theme }) => `
  border: 1px solid ${theme.divider};
  border-radius: 5px;
  padding: 10px 5px;
  ${scrollbarDe(theme)};
  margin-top: 20px;
  width: 265px;
  min-height: 16px;
`
);

const MathOperation = styled.div(
  ({ theme }) => `
    padding: 5px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background: ${theme.primary};
      color: white;
    }
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.divider};
    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
`
);

const mathOperations = ["+", "-", "*", "/", "(", ")"];

export default function OpenMathFormulaColumn({
  selectedColumn,
  fields,
  setChartState,
  setSelectedColumn,
  chart,
}) {
  const { column, subIndex, index } = selectedColumn;
  const formulaItems = column.split("::");
  const [omf, format, ...rest] = formulaItems;
  const label = rest.pop();

  function getNestedFormat() {
    const { options } =
      columnFormats.find((category) =>
        category.options.find((nFormat) => nFormat.value === format)
      ) ?? {};

    return options?.find((nFormat) => nFormat.value === format);
  }

  const [mathActions, setMathActions] = useState(rest);
  const [header, setHeader] = useState(label ?? "");
  const [localFormat, setLocalFormat] = useState(getNestedFormat());

  function convertActionsToString() {
    return `${omf}::${localFormat.value}::${mathActions.join("::")}::${header}`;
  }

  function updateChartSubTitles(formulaColumn) {
    setChartState({
      ...chart,
      subTitles: chart.subTitles.map((subTitle, i) => {
        return subTitle.map((sub, j) =>
          i === subIndex && j === index && column === sub ? formulaColumn : sub
        );
      }),
    });
  }

  function applyChanges() {
    const formulaColumn = convertActionsToString();
    updateChartSubTitles(formulaColumn);
    setSelectedColumn({ ...selectedColumn, column: formulaColumn });
  }

  function addOverrideIfNotExist(option) {
    const isExist = chart.overrides.find(
      (override) => override.name === option.name
    );

    if (!chart.isParameterized && !isExist) {
      setChartState({
        ...chart,
        overrides: [...(chart.overrides ?? []), option],
      });
    }
  }

  function addFieldToExpression(option) {
    setMathActions((mathActions) => [...mathActions, option.name]);
    addOverrideIfNotExist(option);
  }

  function aggregateLastColumn(option) {
    const lastIndex = mathActions.length - 1;
    const column = mathActions[lastIndex];

    setMathActions(
      mathActions.map((action, index) =>
        index === lastIndex ? option.prefix + action : action
      )
    );

    setChartState({
      ...chart,
      overrides: (chart.overrides ?? []).map((override) =>
        override.name === column
          ? { ...override, aggregation: option?.value }
          : override
      ),
    });
  }

  return (
    <Flex direction="column" alignItems="center">
      <Input
        label="Column Header Label"
        inPlaceEdit
        value={header}
        hideEditIcon
        onChange={(e) => setHeader(e.target.value)}
      />
      <Select
        options={columnFormats}
        value={localFormat}
        onChange={(option) => setLocalFormat(option)}
        label="Format"
      />

      <DisplayFormula>{mathActions.join(" ")}</DisplayFormula>
      <Flex wrap={"wrap"} style={{ margin: "10px 0px 20px 0px" }}>
        {mathOperations.map((ops) => (
          <MathOperation
            onClick={() =>
              setMathActions((mathActions) => [...mathActions, ops])
            }
            key={ops}
          >
            {ops}
          </MathOperation>
        ))}
      </Flex>

      <Select
        options={fields}
        getOptionLabel={(option) => option.mapping?.displayName ?? option.name}
        getOptionValue={(option) => option.name}
        value={false}
        onChange={addFieldToExpression}
        label="Add column to expression"
      />
      <br />
      {!chart.isParameterized && (
        <Select
          options={[{ label: "None", prefix: "" }, ...aggregations]}
          value={false}
          label="Aggregate column in expression"
          onChange={aggregateLastColumn}
        />
      )}

      <br />
      <ButtonGroup spacing={20} position="center">
        <IOButton smallPadding onClick={applyChanges}>
          Apply
        </IOButton>
        <IOButton
          smallPadding
          error
          onClick={() => setMathActions((state) => state.slice(0, -1))}
        >
          Clear
        </IOButton>
      </ButtonGroup>
    </Flex>
  );
}
