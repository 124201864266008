import * as actionTypes from "../actionTypes";
import axios from "../../../axios";
import { showToast } from "../message";
import prepareSettings from "./prepareSettings";
import { handleError } from "../../../utils/errorHandling";
import { stringify, parse } from "qs";
import { writeModes } from "../../../utils/constants/constants";
import queryBuilder from "../queryBuilder/queryBuilder";
import { encodeURIMinimal } from "../../../utils/browser";

export const listQueryables = () => {
  const query = {
    includeFields: 1,
    perPage: 100,
  };

  return {
    type: actionTypes.LIST_QUERYABLES_START,
    meta: {
      api: {
        endpoint:
          "api/v1/data_sources" + stringify(query, { addQueryPrefix: true }),
        method: "get",
        toastOnFailure: true,
      },
    },
  };
};

export const getQueryable = (id) => {
  return {
    type: actionTypes.GET_QUERYABLE_START,
    meta: {
      api: {
        endpoint: `api/v1/data_sources/${id}`,
        method: "get",
      },
    },
  };
};

export const closeQueryable = () => ({ type: actionTypes.CLOSE_QUERYABLE });

export const listQueries = (isTiny) => {
  const actionType = isTiny
    ? actionTypes.LIST_QUERIES_TINY_START
    : actionTypes.LIST_QUERIES_START;

  return {
    type: actionType,
    meta: {
      api: {
        endpoint: `api/v1/queries${isTiny ? "?responseFormat=tiny" : ""}`,
        method: "get",
        toastOnFailure: true,
      },
    },
  };
};

function moveToQuery(res, history) {
  if (res.data?.data.uuid) {
    history.push(`/admin/data-settings/mapping/queries/${res.data.data.uuid}`);
  }
}

export const saveQuery = (payload, history) => {
  const action = {
    type: actionTypes.CREATE_QUERY_START,
    meta: {
      api: {
        endpoint: `/api/v1/queries`,
        method: "post",
        payload,
      },
      toasts: [
        {
          type: "success",
          title: "Query!",
          message: "Query successfully created",
          condition: actionTypes.CREATE_QUERY_SUCCESS,
        },
        {
          type: "danger",
          title: "Query!",
          apiMessages: true,
          condition: actionTypes.CREATE_QUERY_FAIL,
        },
      ],
    },
  };
  return async (dispatch) => {
    let res;
    try {
      res = await dispatch(action).unwrap();
    } catch (error) {
      // do something
    } finally {
      if (res) {
        await dispatch(listQueries());
        moveToQuery(res, history);
      }
    }
  };
};

export const updateQuery = (id, payload) => {
  const action = {
    type: actionTypes.UPDATE_QUERY_START,
    meta: {
      api: {
        endpoint: `/api/v1/queries/${id}`,
        method: "put",
        payload,
      },
      toasts: [
        {
          type: "success",
          title: "Query!",
          message: "Query successfully updated",
          condition: actionTypes.UPDATE_QUERY_SUCCESS,
        },
        {
          type: "danger",
          title: "Query!",
          apiMessages: true,
          condition: actionTypes.UPDATE_QUERY_FAIL,
        },
      ],
    },
  };
  return async (dispatch) => {
    await dispatch(action).unwrap();
    dispatch(listQueries());
  };
};

export const resetQuery = () => (dispatch) => {
  dispatch({ type: actionTypes.GET_QUERY_RESET });
  dispatch({ type: actionTypes.RUN_QUERY_RESET });
};

export const addMissingFields = (uuid) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADD_MISSING_FIELDS_START });
    await axios.post(`/api/v1/data_sources/${uuid}/add-missing-fields`);

    showToast(
      actionTypes.ADD_MISSING_FIELDS_SUCCESS,
      dispatch,
      ["New fields was successfully added"],
      "success"
    );
  } catch (error) {
    if (!error.response) {
      showToast(
        actionTypes.ADD_MISSING_FIELDS_FAIL,
        dispatch,
        ["Something went wrong"],
        "danger"
      );
    }

    const handleErrors = error.response.data.errors
      ? Object.values(error.response.data.errors).map((error) => error[0])
      : [error.response.data.message];
    showToast(
      actionTypes.ADD_MISSING_FIELDS_FAIL,
      dispatch,
      handleErrors,
      "danger"
    );
  }
};

export const deleteQuery = (uuid) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_QUERY_START });
    await axios.delete(`api/v1/queries/${uuid}`);

    showToast(
      actionTypes.DELETE_QUERY_SUCCESS,
      dispatch,
      ["Query was successfully deleted"],
      "success"
    );
    dispatch({ type: actionTypes.DELETE_QUERY_SUCCESS, results: uuid });
  } catch (error) {
    const handleErrors = error.response.data.errors
      ? Object.values(error.response.data.errors).map((error) => error[0])
      : [error.response.data.message];
    showToast(actionTypes.CREATE_QUERY_FAIL, dispatch, handleErrors, "danger");
  }
};

export const previewDataSource = (uuid) => {
  return {
    type: actionTypes.PREVIEW_DATA_SOURCE_START,
    meta: {
      api: {
        endpoint: `api/v1/data_sources/${uuid}/fields`,
        method: "get",
      },
    },
  };
};

export function updateDataSourceField({
  dataSourceUuid,
  dataSourceFieldUuid,
  filterable,
  typeOverride,
  loadDataSource,
}) {
  const action = {
    type: actionTypes.UPDATE_DATA_SOURCE_FIELD_START,
    meta: {
      api: {
        endpoint: `api/v1/data_sources/${dataSourceUuid}/fields/${dataSourceFieldUuid}`,
        method: "put",
        payload: { filterable, typeOverride },
      },
      toasts: [
        {
          type: "success",
          title: "Data Source!",
          message: "Field successfully updated",
          condition: actionTypes.UPDATE_DATA_SOURCE_FIELD_SUCCESS,
        },
        {
          type: "danger",
          title: "Data Source!",
          message: "Field update failed",
          condition: actionTypes.UPDATE_DATA_SOURCE_FIELD_FAIL,
        },
      ],
    },
  };
  return async (dispatch) => {
    await dispatch(action);
    if (loadDataSource) {
      await dispatch(getQuery(dataSourceUuid));
    }
  };
}

export function deleteDataSourceField(
  dataSourceUuid,
  dataSourceFieldUuid,
  loadDataSource
) {
  const action = {
    type: actionTypes.DELETE_DATA_SOURCE_FIELD_START,
    meta: {
      api: {
        endpoint: `api/v1/data_sources/${dataSourceUuid}/fields/${dataSourceFieldUuid}`,
        method: "delete",
      },
      toasts: [
        {
          type: "success",
          title: "Data Source!",
          message: "Field successfully deleted",
          condition: actionTypes.DELETE_DATA_SOURCE_FIELD_SUCCESS,
        },
        {
          type: "danger",
          title: "Data Source!",
          message: "Field delete failed",
          condition: actionTypes.DELETE_DATA_SOURCE_FIELD_FAIL,
        },
      ],
    },
  };
  return async (dispatch) => {
    await dispatch(action);
    if (loadDataSource) {
      await dispatch(getQuery(dataSourceUuid));
    }
  };
}

export const rescanDataSource = (uuid, writeMode) => {
  const toasts = [
    {
      type: "danger",
      title: "Data Source!",
      message: "Rescan failed",
      condition: actionTypes.RESCAN_DATA_SOURCE_FAIL,
    },
  ];

  if (writeMode === writeModes.all) {
    toasts.push({
      type: "success",
      title: "Data Source!",
      message: "New Fields added, Deleted Fields removed from data source",
      condition: actionTypes.RESCAN_DATA_SOURCE_SUCCESS,
    });
  }

  return {
    type: actionTypes.RESCAN_DATA_SOURCE_START,
    meta: {
      api: {
        endpoint: `api/v1/data_sources/${uuid}/rescan?writeMode=${writeMode}`,
        method: "get",
      },
      toasts,
    },
  };
};

export const deleteDataSource = (uuid) => {
  return {
    type: actionTypes.DELETE_DATA_SOURCE_START,
    meta: {
      api: {
        endpoint: `api/v1/data_sources/${uuid}`,
        method: "delete",
      },
    },
    uuid,
  };
};

export const createDataSource = (params) => async (dispatch) => {
  const requestBody = {
    typeId: params.typeId,
    connectionUuid: params.connectionUuid,
    displayName: params.displayName,
    db: params.database,
    tbl: params.viewName,
  };

  try {
    dispatch({ type: actionTypes.CREATE_DATA_SOURCE_START });
    const response = await axios.post("/api/v1/data_sources", requestBody);

    showToast(
      actionTypes.CREATE_DATA_SOURCE_SUCCESS,
      dispatch,
      ["Data Source was successfully created"],
      "success"
    );
    dispatch(listQueryables());

    return response.data.data;
  } catch (error) {
    handleError(dispatch, error);
    throw error;
  }
};

export const createQuery =
  ({ dataSources, name }, history) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_QUERY_START });

    try {
      const promises = dataSources.map(({ uuid }) =>
        axios.get("/api/v1/data_sources/" + uuid)
      );
      const res = await Promise.all(promises);

      const isMoreThenOne = dataSources.length > 1;

      const payload = {
        name,
        dataSources: [],
        ...(isMoreThenOne && { dataSourceJoinConditions: [] }),
      };
      dataSources.forEach((item, index) => {
        payload.dataSources.push({
          ...item,
          fields: res[index].data.data.fields,
        });
        if (isMoreThenOne) {
          // @todo need to think about dataSourceJoinConditions logic
          payload.dataSourceJoinConditions.push({});
        }
      });

      const result = await axios.post(`/api/v1/queries`, payload);
      dispatch({ type: actionTypes.CREATE_QUERY_SUCCESS });
      createQuerySuccess(dispatch);
      await dispatch(listQueries());
      moveToQuery(result, history);
    } catch (error) {
      const handleErrors = error.response.data.errors
        ? Object.values(error.response.data.errors).map((error) => error[0])
        : [error.response.data.message];
      showToast(
        actionTypes.CREATE_QUERY_FAIL,
        dispatch,
        handleErrors,
        "danger"
      );
    }
  };

export const addDataSourceFields = (uuid) => (dispatch) =>
  dispatch(addMissingFields(uuid));

export const getQuery = (id) => {
  return {
    type: actionTypes.GET_QUERY_START,
    meta: {
      api: {
        endpoint: `api/v1/queries/${id}`,
        method: "get",
      },
    },
  };
};

export function runQuery(uuid, settings = {}) {
  const controller = new AbortController();
  const preparedSettings = prepareSettings(settings);
  const query = {
    ...preparedSettings,
    format: "chart",
    perPage: preparedSettings?.perPage || 15,
  };

  const api = {
    endpoint: `api/v1/queries/${uuid}/exec`,
    method: "post",
    payload: query,
    signal: controller.signal,
  };

  return async (dispatch) => {
    const commonAction = {
      type: actionTypes.RUN_QUERY_START,
      meta: { api },
      settings,
      pendingController: controller,
    };
    await dispatch(commonAction).unwrap();
    return dispatch({
      ...commonAction,
      key: "pagination",
      meta: { api: { ...api, payload: { ...query, page: 1 } } },
    });
  };
}

export const runParameterizedSample = (query, filters) => {
  return (dispatch, getState) => {
    const startTime = new Date();
    const qs = queryBuilder({ isParameterized: true, filters }, getState);

    dispatch({
      type: actionTypes.RUN_PARAMETERIZED_SAMPLE_START,
      meta: {
        api: {
          method: "POST",
          endpoint: `/api/v1/queries/${query.uuid}/exec`,
          payload: parse(qs, { depth: 10 }),
        },
      },
      startTime,
    });
  };
};

export const exportExcel = (uuid, settings = {}) => {
  const { perPage, page, ...preparedSettings } = prepareSettings(settings);
  const query = {
    ...preparedSettings,
    format: "excel",
  };

  const api = {
    endpoint: `api/v1/queries/${uuid}/exec`,
    method: "post",
    payload: query,
    file: true,
    fileName: "Data Explorer",
    format: "xlsx",
  };

  return {
    type: actionTypes.RUN_QUERY_EXPORT_START,
    meta: { api },
    settings,
  };
};

export const getDataSourceFields = (dataSourceUuids) => async (dispatch) => {
  dispatch({
    type: actionTypes.GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_START,
  });

  try {
    const promises = dataSourceUuids.map(({ uuid }) =>
      axios.get("/api/v1/data_sources/" + uuid)
    );
    const res = await Promise.all(promises);

    dispatch({
      type: actionTypes.GET_DATA_SOURCE_FIELDS_FOR_BLENDED_QUERY_SUCCESS,
      results: { sourceFields: res.map((res) => res.data.data) },
    });
  } catch (error) {
    handleError(dispatch, error);
  }
};

export const createBlendedQuery = (settings, history) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_QUERY_START });

  const requestBody = {
    name: settings.name,
    dataSources: settings.dataSources.map((dataSource) => ({
      uuid: dataSource.uuid,
      alias: settings.aliases[dataSource.uuid],
      isPrimary: dataSource.uuid === settings.primary.uuid,
      joinType: "left",
      fields: settings.dataSourceFields[dataSource.uuid],
    })),
    dataSourceJoinConditions: settings.joinConditions,
  };

  try {
    const res = await axios.post("/api/v1/queries", requestBody);

    dispatch({ type: actionTypes.CREATE_QUERY_SUCCESS });
    createQuerySuccess(dispatch);
    moveToQuery(res, history);
  } catch (err) {
    handleError(dispatch, err);
  }
};

function createQuerySuccess(dispatch) {
  showToast(
    actionTypes.CREATE_QUERY_SUCCESS,
    dispatch,
    ["Query created"],
    "success"
  );
}

export const getUniqueValues = (queryId, field) => {
  const qs = `&overrides[0][name]=${encodeURIMinimal(
    field
  )}&overrides[other][isDistinct]=1`;
  return {
    type: actionTypes.GET_UNIQUE_START,
    meta: {
      api: {
        endpoint: `api/v1/queries/${queryId}/exec?${qs}`,
      },
    },
  };
};

export const getDatasourceDataInit = () => (dispatch) => {
  dispatch({ type: actionTypes.GET_DATASOURCE_DATA_INIT });
};

export const getDatasourceData = (datasourceId, nextUrl) => {
  return {
    type: actionTypes.GET_DATASOURCE_DATA_START,
    meta: {
      api: {
        endpoint: nextUrl
          ? nextUrl
          : `api/v1/data_sources/${datasourceId}/get-data`,
      },
    },
  };
};

export const uploadDatasourceData = (etlConfigId, json) => (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_DATASOURCE_DATA_START });

  axios
    .post(`api/v1/etl_configs/${etlConfigId}/processings`, {
      isFullLoad: 1,
      json: json,
    })
    .then(() => {
      showToast(
        actionTypes.UPLOAD_DATASOURCE_DATA_SUCCESS,
        dispatch,
        ["Datasource changes queued for update"],
        "success"
      );
    })
    .catch((err) => {
      if (err.response.status === 422) {
        Object.keys(err.response.data.errors).map((errorKey) =>
          showToast(
            actionTypes.UPLOAD_DATASOURCE_DATA_FAIL,
            dispatch,
            err.response.data.errors[errorKey][0],
            "danger"
          )
        );

        dispatch({
          type: actionTypes.UPLOAD_DATASOURCE_DATA_FAIL,
          errors: err.response.data.errors,
        });
      } else {
        const handleErrors = err.response.data.errors
          ? Object.values(err.response.data.errors).map((error) => error[0][0])
          : [err.response.data.message];

        showToast(
          actionTypes.UPLOAD_DATASOURCE_DATA_FAIL,
          dispatch,
          handleErrors,
          "danger"
        );
      }
    });
};
