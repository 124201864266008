export function compareByOperator(a, b, operator) {
  switch (operator) {
    case ">":
      return a > b;

    case "<":
      return a < b;

    case "=":
      return a === b;

    case "!=":
      return a !== b;

    default:
      return true;
  }
}
