import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { scrollbar, scrollbarConfig } from "../../../../styles/styledSnippets";
import produce from "immer";
import NavigationTableHeader from "./NavigationTableHeader";
import usePrevious from "../../../../utils/usePrevious";
import TableLoader from "./TableLoader";
import HeaderTooltip from "./HeaderTooltip";
import { lightenDarkenColor } from "../../../../styles/colorConvertor";
import useShowOptions from "../hooks/useShowOptions";
import AddOverrideColumn from "../DataExplorerUI/AddOverrideColumn";
import NavigationTableBody from "./NavigationTableBody";
import BottomScrollSection from "./BottomScrollSection";
import Portal from "../../../../UI/Portal/Portal";

const layoutPadding = 158;
const NavigationTableContainer = styled.div`
  position: relative;
  max-width: calc(
    100vw - ${(props) => props.subtractWidth}px - ${layoutPadding}px
  );
  font-size: 13px;
  ${(props) => scrollbar(props)}
`;

const HeadersContainer = styled.div`
  position: sticky;
  top: 107px;
`;

const Headers = styled.div`
  display: flex;
  height: 28px;
  margin-top: 2px;
  color: ${(props) => props.theme.text.primary};
  max-width: calc(
    100vw - ${(props) => props.subtractWidth}px - ${layoutPadding}px
  );
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  margin-left: 40px;
  max-width: calc(
    100vw - ${(props) => props.subtractWidth}px - ${layoutPadding}px
  );
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  ${scrollbarConfig({
    thumbBorder: false,
    size: ".4em",
  })}
`;

const light = (props) =>
  lightenDarkenColor(props.theme.notification.infoLight, 60);
const dark = (props) =>
  lightenDarkenColor(props.theme.notification.infoDark, -30);

const FakeScrollContent = styled.div`
  width: ${(props) => props.width}px;
  background: linear-gradient(to left, ${light}, ${dark});
  height: 3px;
  pointer-events: none;
`;

export default function NavigationTable(props) {
  const {
    columns,
    data,
    activeKey,
    actions,
    subtractWidth,
    showActive,
    emptyColumn,
    loading,
    showOptions,
    setShowOptions,
    setAddColumn,
    setBuilderPosition,
    orders,
    setRowOrder,
    columnWidths,
    setColumnWidths,
  } = props;

  const [tableWidth, setTableWidth] = useState(0);

  const handleShowOptions = (position, dimensions) => {
    if (position === null) {
      return setShowOptions(null);
    } else {
      const newVal = {
        dimensions,
        position,
        name: columns[position].name,
      };
      setShowOptions(newVal);
    }
  };

  const triggerShowOptions = useShowOptions(handleShowOptions);

  useEffect(() => {
    setTableWidth(tableRef.current.scrollWidth);
  }, [columnWidths, setTableWidth]);

  useEffect(() => {
    setTableWidth(tableRef.current.scrollWidth);
  }, [columns]);

  const tableRef = useRef(null);
  const fakeRef = useRef(null);
  const headerRef = useRef(null);

  const ref = useRef(null);
  const prevSubtractWidth = usePrevious(subtractWidth);

  useEffect(() => {
    if (subtractWidth > prevSubtractWidth) {
      ref.current.scrollLeft = 10000;
    }
  }, [subtractWidth, prevSubtractWidth]);

  useEffect(() => {
    fakeRef.current.addEventListener("scroll", horizontalScroll("from-fake"));
    tableRef.current.addEventListener("scroll", horizontalScroll("from-table"));
    setTableWidth(tableRef.current.scrollWidth);
    const fakeCurrent = fakeRef.current;
    const tableCurrent = tableRef.current;
    return () => {
      fakeCurrent.removeEventListener("scroll", horizontalScroll("from-fake"));
      tableCurrent.removeEventListener(
        "scroll",
        horizontalScroll("from-table")
      );
    };
    // eslint-disable-next-line
  }, []);

  const horizontalScroll = (source) => () => {
    setShowOptions(null);
    if (source === "from-fake") {
      tableRef.current.scrollLeft = fakeRef.current.scrollLeft;
      headerRef.current.scrollLeft = fakeRef.current.scrollLeft;
    } else {
      fakeRef.current.scrollLeft = tableRef.current.scrollLeft;
    }
  };

  const handleSetColumnWidth = (width, i) => {
    if (i === undefined) return;
    const draftState = produce(columnWidths, (nextState) => {
      nextState[i] = width;
    });
    setColumnWidths(draftState);
    setShowOptions(null);
  };

  const handleForceHideOptions = () => {
    setShowOptions(null);
  };

  const tableScroll =
    ref.current && ref.current.clientWidth < ref.current.scrollWidth;

  return (
    <div data-cy="navigation-table-wrapper" style={{ position: "relative" }}>
      {showOptions ? (
        <Portal isDataExplorer>
          <HeaderTooltip
            actions={actions}
            showOptions={showOptions}
            handleLeave={triggerShowOptions.triggerHide}
            handleEnter={triggerShowOptions.triggerStay}
            handleForceHideOptions={handleForceHideOptions}
            columns={columns}
          />
        </Portal>
      ) : null}

      <NavigationTableContainer
        ref={ref}
        subtractWidth={subtractWidth}
        data-cy="table-outer-wrapper"
      >
        <HeadersContainer>
          <AddOverrideColumn
            handleClick={() => {
              setAddColumn(true);
              setBuilderPosition(columns.length);
            }}
          />
          {emptyColumn}
          <Headers ref={headerRef} subtractWidth={subtractWidth}>
            {loading ? <TableLoader /> : null}
            {columns.map((column, i) => (
              <NavigationTableHeader
                hasScrollbar={tableScroll}
                column={column}
                key={column.name}
                columnWidth={columnWidths[i] || 140}
                handleSetColumnWidth={(width) => handleSetColumnWidth(width, i)}
                active={showActive && activeKey === i}
                activeOptions={i === showOptions?.position}
                setShowOptions={(dims) =>
                  triggerShowOptions.triggerShow(i, dims)
                }
                hideOptions={triggerShowOptions.triggerHide}
                orders={orders}
                setRowOrder={setRowOrder}
              />
            ))}
          </Headers>
        </HeadersContainer>

        <NavigationTableBody
          ref={tableRef}
          data={data}
          columns={columns}
          columnWidths={columnWidths}
          showActive={showActive}
          activeKey={activeKey}
        />
      </NavigationTableContainer>

      <BottomScrollSection>
        <ScrollContainer ref={fakeRef} subtractWidth={subtractWidth}>
          <FakeScrollContent width={tableWidth} />
        </ScrollContainer>
      </BottomScrollSection>
    </div>
  );
}

NavigationTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
};
