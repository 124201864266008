import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTheme } from "emotion-theming";

export default function TableMainCellLink(props) {
  const {
    linkColumns = [],
    rawValue,
    value,
    cellKey,
    dataRow,
    pinIcon,
    noTotalCalcKeys,
    rowMainKey,
  } = props;
  const theme = useTheme();
  const isMatch = linkColumns.find((col) => col.key === cellKey);

  const isLink = isMatch && dataRow[isMatch.urlKey];

  const pinnedStyles = {
    color: theme.primary,
    marginRight: 10,
    marginTop: 1,
    transform: "rotate(45deg)",
  };

  const attributes = {
    "data-value": Number.isNaN(rawValue)
      ? null
      : rawValue
      ? rawValue.toString().replace(">", "")
      : null,
  };

  const applySpecialReplacement = () => {
    const isCellKeyInIgnoreList = (noTotalCalcKeys ?? []).includes(cellKey);
    const isTotalRow = dataRow[rowMainKey] === "Total";

    return isCellKeyInIgnoreList && isTotalRow ? "--" : value;
  };

  return isLink ? (
    <a href={dataRow[isMatch.urlKey]} target="_new" {...attributes}>
      {value}
    </a>
  ) : (
    <span style={{ width: "100%" }} {...attributes}>
      {pinIcon ? (
        <FontAwesomeIcon icon={["fa", "thumbtack"]} style={pinnedStyles} />
      ) : null}
      {applySpecialReplacement()}
    </span>
  );
}
