import React from "react";
import PropTypes from "prop-types";
import words from "../../styles/words";
import { useMediaQuery } from "react-responsive";
import VerticalDivider from "../Dividers/VerticalDivider";
import { chartTypeMap } from "../../utils/constants/chartConstants";
import { ChartProvider } from "../ChartContext";
import AppBoundary from "../../AppBoundary";
import ChartContainer from "../BaseChart/ChartContainer";
import { normalizeError } from "../../utils/errorHandling";
import Loading from "../../UI/Loading/Loading";

const ChartFactory = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { type, zipMode } = props;

  if (type === "Vertical Divider") {
    return isMobile ? null : <VerticalDivider {...props} />;
  }

  const chartType = chartTypeMap[type];

  if (zipMode && (props.chart.refreshing || props.chart.loading)) {
    return (
      <div
        style={{ height: 20, width: "100%", position: "relative", top: -20 }}
      >
        <Loading />
      </div>
    );
  }

  if (chartType) {
    return (
      <ChartProvider value={props}>
        <AppBoundary
          fallbackRender={(error) => (
            <ChartContainer
              hasData={false}
              loading={false}
              error={normalizeError(error)}
              noResultsMessageOverride={props.chart.noResultsMessageOverride}
            />
          )}
        >
          <chartType.Component {...props} />
        </AppBoundary>
      </ChartProvider>
    );
  }

  return <div>{words.chart_configuration_error}</div>;
};

ChartFactory.propTypes = {
  type: PropTypes.string, // @todo make this required
  filters: PropTypes.array,
  chart: PropTypes.object,
};

export default React.memo(ChartFactory);
