import React from "react";
import styled from "@emotion/styled";

const configs = {
  1: { left: 8, animation: 1 },
  2: { left: 8, animation: 2 },
  3: { left: 32, animation: 2 },
  4: { left: 56, animation: 3 },
};

const Container = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  align-self: center;
  zoom: ${(props) => (props.size === "small" ? 0.5 : 1)};

  div {
    position: absolute;
    top: ${(props) => props.top}px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.divider ?? "#2196f3"};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
`;

const Circle = styled.div(
  ({ order }) => `
  left: ${configs[order].left}px;
  animation: lds-ellipsis${configs[order].animation} 0.6s infinite;
  animation-timing-function: linear;

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`
);

export default function Loading(props) {
  return (
    <Container data-cy="loading-spinner" {...props}>
      <Circle order={1} />
      <Circle order={2} />
      <Circle order={3} />
      <Circle order={4} />
    </Container>
  );
}
Loading.defaultProps = {
  top: 33,
};
