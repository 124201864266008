import styled from "@emotion/styled";
import Switcher from "../../../../../UI/Switcher/Switcher";
import Limit from "./Limit";
import DisplayRows from "./DisplayRows";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  hr {
    width: 100%;
  }
`;

export const Note = styled.div(
  ({ theme, color }) => `
  margin: 10px 0px;
  color: ${theme.notification[color]};
  font-weight: 500;
  font-size: 12px;
  svg {
    margin-left: 5px;
  }
`
);

export default function LimitRows({ setChartState, queryType, chart, theme }) {
  const { limit } = chart;

  const addOrRemoveLimit = () => {
    setChartState({
      ...chart,
      limit: limit ? null : 20,
      enablePagination: false,
      queryLevelPaginatedSorting: false,
    });
  };

  return (
    <Container>
      <Switcher
        rightLabel="Limit (number of rows)"
        checked={!!limit || limit === ""}
        handleSwitchChange={addOrRemoveLimit}
      />

      <Limit
        chart={chart}
        setChartState={setChartState}
        queryType={queryType}
        Note={Note}
      />

      <DisplayRows
        chart={chart}
        setChartState={setChartState}
        queryType={queryType}
        Note={Note}
      />
      <hr color={theme.background.secondary} />
    </Container>
  );
}
