import React, { useCallback } from "react";
import styled from "@emotion/styled";
import formatter from "../../utils/formatters/formatter";
const Values = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: ${(props) => props.theme.text.secondary};
`;

export default function SliderValues(props) {
  const { minValue, maxValue, format } = props;
  // if at least one filter value is string then we need to show string label else we show number value
  // ?
  const getLabel = useCallback(
    (val) => {
      // unclear what this is for
      // if (values.some((v) => isNaN(+v))) {
      //   return values.find((v) => castToNumber(v.value) === val)?.value;
      // }

      return formatter(val, format);
    },
    [format]
  );
  return (
    <Values>
      <span>{getLabel(minValue)}</span>
      <span>{getLabel(maxValue)}</span>
    </Values>
  );
}
