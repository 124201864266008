import { cloneDeep, omit, isEmpty } from "lodash-es";
import { v4 as uuid } from "uuid";
import { buildDynamicFiltersQuery } from "../../../charts/TableView/functions/dynamicDrillDown";
import { getRidOfAggregation } from "../../../charts/TableView/Elements/EditableMenu";
import { removeDuplicates } from "../../../Editors/BlockEdit/GuiEditorSections/helper";

/**
 * you can click only one level deep from current (first time only)
 * when all levels exists then you can click any level in any order
 * @param {*} levelData object with first rows of each drilldown level
 * @param {*} level clicked drilldown level
 * @param {*} levelIndicator active drilldown level
 * @param {*} drilldownKeys selected level => drilldown key object; {1: "Product Name"}
 * @param {*} path drilldown path showing where in tree we are right now ex. "/"
 * @returns true or false
 */
export function canClickDrilldownItem(
  levelData = {},
  level,
  levelIndicator,
  drilldownParent = {},
  path,
  drilldownKeys = []
) {
  const lvl = level - levelIndicator;

  const generalCondition = !!(
    levelData[level] ||
    (levelData[level - 1] && lvl === 1)
  );

  const empty = isEmpty(drilldownParent);

  const isRegularParent = drilldownKeys.includes(drilldownParent[level + 1]);

  if (empty || level === 0 || isRegularParent) {
    return generalCondition;
  }

  let dynamicCondition = false;
  let count = level;

  while (count) {
    dynamicCondition = path.includes(drilldownParent[count]);
    count--;

    if (dynamicCondition) {
      break;
    }
  }

  return dynamicCondition && generalCondition;
}

export function canAddDrilldownItem(
  level,
  levelIndicator,
  drilldownParent,
  indicatorParent
) {
  const generalCondition = level - levelIndicator === 1;

  if (isEmpty(drilldownParent)) {
    return generalCondition;
  }

  const dynamicCondition =
    Object.values(drilldownParent).includes(indicatorParent);

  return generalCondition && dynamicCondition;
}

export const getFilteredByOptionValues = (options, dynamicFilters = []) => {
  return options.filter((field) => dynamicFilters.includes(field.name));
};

export const setNullIfNone = (value) => {
  return value === "None" ? "" : value;
};

export const getFilteredByValueOption = (options, dynamicFilterValue = "") => {
  return options.filter((field) => field.name === dynamicFilterValue);
};

const clearFieldFromParameterizedPrefix = (parameters, prefix) => {
  if (!parameters) {
    return;
  }

  return parameters.map((parameter) => ({
    ...parameter,
    name: parameter.name.replace(prefix, ""),
  }));
};

export const getFilteredByOptions = (query, prefix = "") => {
  if (!query) {
    return [];
  }

  return (
    clearFieldFromParameterizedPrefix(query.parameters, prefix) ??
    query.dataSources.map((ds) => ds.fields).flat()
  );
};

/**
 *
 * @param {*} chart summary chart config with all drilldowns inside
 * @param {*} level active drilldown level
 * @param {*} drilldownKeys selected level => drilldown key object; {1: "Product Name"}
 * @returns config object with overridden parameters from inner drilldown level
 */
export const getNestedDrilldownConfig = (chart, level, drilldownKeys = {}) => {
  if (!chart) {
    return;
  }

  let clonedChart = cloneDeep(chart);
  let depth = 0;

  while (depth < level) {
    depth++;
    clonedChart = {
      ...clonedChart,
      ...getDynamicRowExpandParams(clonedChart, drilldownKeys[depth]),
    };
  }

  if (!clonedChart.hasRowExpand) {
    delete clonedChart.rowExpandVisualizationParams;
  }

  return clonedChart;
};

// Function to set non-existing overrides in the parent of currentChart object
export function setNonExistingOverrides(dynamicFilters, currentChart) {
  // Iterate over each value in the 'values' array
  (dynamicFilters ?? []).forEach((dynamicFilter) => {
    // Check if there's already an override with the same 'name' in 'currentChart.overrides'
    if (
      !(currentChart.overrides ?? []).find(
        (override) => override.name === dynamicFilter
      )
    ) {
      // If no existing override with the same 'name' is found, add a new object to 'overrides'
      (currentChart.overrides ?? []).push({ name: dynamicFilter });
    }
  });
}

/**
 *
 * @param {*} param0
 * @param {*} drilldownKeys selected level => drilldown key object; {1: "Product Name"}
 * @returns updated config with new query uuid or dynamicFilters array
 */
export const updateNestedDrilldownProperty = ({
  chart,
  propertyName,
  values,
  level,
  drilldownKeys = {},
}) => {
  const clonedChart = cloneDeep(chart);
  let currentChart = clonedChart;
  let depth = 0;
  let queryId = currentChart.queryId;

  while (depth < level) {
    if (depth === level - 1 && propertyName === "dynamicFilters") {
      setNonExistingOverrides(values, currentChart);
    }

    // get last querty uuid for next comparing
    if (currentChart.queryId) {
      queryId = currentChart.queryId;
    }

    depth++;
    currentChart = getDynamicRowExpandParams(
      currentChart,
      drilldownKeys[depth]
    );
  }

  // if user selected different query we need to drop all columns values related to old query
  if (propertyName === "queryId" && queryId !== values) {
    currentChart.overrides = [];
    currentChart.subTitles = [];
    currentChart.staticGroupingKeys = [];
    currentChart.rowGroupKey = null;
    currentChart.uniqueValueKey = null;
    currentChart.rawRows = true;
  }

  currentChart[propertyName] = values;

  return clonedChart;
};

/**
 * deletes all nested configs in a chain from the selected level
 * @param {*} chart summary chart config with all drilldowns inside
 * @param {*} level drilldown level selected to delete
 * @param {*} drilldownKeys selected level => drilldown key object; {1: "Product Name"}
 * @returns updated config
 */
export const deleteNestedDrilldownConfig = (
  chart,
  level,
  drilldownKeys = {}
) => {
  const clonedChart = cloneDeep(chart);
  let currentChart = clonedChart;
  let depth = 0;

  while (depth < level - 1) {
    depth++;
    currentChart = getDynamicRowExpandParams(
      currentChart,
      drilldownKeys[depth]
    );
  }

  if (currentChart.dynamicDrilldowns) {
    delete currentChart.rowExpandVisualizationParams[drilldownKeys[level]];

    const rowExpandParams = currentChart.rowExpandVisualizationParams;
    const keys = Object.keys(rowExpandParams);
    const isSingleItem = keys.length === 1;

    if (isSingleItem) {
      const restKey = keys.find((key) => key !== drilldownKeys[level]);

      currentChart.dynamicDrilldowns = false;

      currentChart.rowExpandVisualizationParams = rowExpandParams[restKey];

      delete rowExpandParams[restKey];
    }
  } else {
    delete currentChart.rowExpandVisualizationParams;
  }

  const hasMore =
    Object.keys(currentChart.rowExpandVisualizationParams ?? {}).length > 0;

  currentChart.hasRowExpand = hasMore;

  return clonedChart;
};

/**
 * the new level will be added to the very end of the config chain
 * @param {*} chart summary chart config with all drilldowns inside
 * @param {*} uuid default uuid for test only
 * @param {*} drilldownKeys selected level => drilldown key object; {1: "Product Name"}
 * @param {*} setDrilldownParent will set parent on levels without parents when you add new level of drilldown
 * @param {*} setParent need to set parent input value because on add you have temporary config not real
 * @returns updated config with new drilldown level
 */
export const addNewDrilldownLevel = (
  chart,
  uuid,
  level,
  drilldownKeys = {},
  setDrilldownParent,
  setParent
) => {
  const clonedChart = cloneDeep(chart);
  let currentChart = clonedChart;
  let depth = 0;

  while (depth < level - 1) {
    depth++;

    currentChart = getDynamicRowExpandParams(
      currentChart,
      drilldownKeys[depth]
    );
  }

  const emptyLevel = {
    ...omit(currentChart, "rowExpandVisualizationParams"),
    dynamicFilters: [],
    hasRowExpand: false,
    overflowX: "unset",
    dynamicDrilldowns: false,
    visualizationId: uuid ?? createNewVisualizationId(),
  };

  const newKey = createNewDynamicDrilldownKey();

  // anchor new created drilldown key to help editor to know where we need add changes
  if (setDrilldownParent && setParent) {
    setDrilldownParent({ ...drilldownKeys, [level]: newKey });
    setParent(newKey);
  }

  if (currentChart.dynamicDrilldowns) {
    // case when our drilldown have dynamic keys
    currentChart.rowExpandVisualizationParams[newKey] = emptyLevel;
  } else if (currentChart.hasRowExpand) {
    // case when our drilldown have not dynamic keys but have one more drilldown level
    const clonedRowExpandVisualizationParams = cloneDeep(
      currentChart.rowExpandVisualizationParams
    );

    currentChart.rowExpandVisualizationParams = {
      [createNewDynamicDrilldownKey()]: clonedRowExpandVisualizationParams,
      [newKey]: emptyLevel,
    };
    currentChart.dynamicDrilldowns = true;
  } else {
    // case when our drilldown have not dynamic keys and current level is last
    currentChart.rowExpandVisualizationParams = emptyLevel;
    currentChart.hasRowExpand = true;
  }

  return clonedChart;
};

// visualization key
const createNewVisualizationId = () => {
  return "row-visualization-" + uuid();
};

// dynamic drilldown key
const createNewDynamicDrilldownKey = () => {
  return uuid();
};

/**
 * filters using first row of each nested table
 * @param {*} param0
 * @param {*} drilldownKeys selected level => drilldown key object; {1: "Product Name"}
 * @returns updated config with selected dynamicFilters as filters array
 */
export const setDynamicFiltersWithValues = ({
  row,
  chart,
  level,
  drilldownKeys = {},
}) => {
  const clonedChart = cloneDeep(chart);
  let currentChart = clonedChart;
  let depth = 0;

  while (depth < level) {
    depth++;
    currentChart = getDynamicRowExpandParams(
      currentChart,
      drilldownKeys[depth]
    );
  }

  const prefix =
    currentChart.parameterizedFilterPrefix ??
    clonedChart.parameterizedFilterPrefix;

  const filters = buildDynamicFiltersQuery(
    row,
    currentChart.dynamicFilters,
    currentChart.dynamicFilterValue,
    prefix
  );

  currentChart.filters = [
    ...(currentChart.filters ?? []),
    ...filters.map((filter) => ({
      ...filter,
      removeOnSave: true,
    })),
  ];

  return clonedChart;
};

/**
 * this function updating inner chart props like: subTitles, sections, mappings and etc
 * @param {*} chart summary chart config with all drilldowns inside
 * @param {*} innerChart current chart configuration with updated properties
 * @param {*} level selected drilldown level
 * @param {*} drilldownKeys selected level => drilldown key object; {1: "Product Name"}
 * @returns updated chart config
 */
export const updateNestedDrilldownConfig = (
  chart,
  innerChart,
  level,
  drilldownKeys = {}
) => {
  if (!level) {
    return innerChart;
  }

  const clonedChart = cloneDeep(chart);
  let currentChart = clonedChart;
  let depth = 0;

  while (depth < level) {
    depth++;
    currentChart = getDynamicRowExpandParams(
      currentChart,
      drilldownKeys[depth]
    );
  }

  const innerKeys = Object.keys(innerChart);
  const currentKeys = Object.keys(currentChart);

  currentKeys.forEach((key) => {
    if (!innerKeys.includes(key)) {
      delete currentChart[key];
    }
  });

  // we need to do deep copy
  innerKeys.forEach((key) => {
    currentChart[key] = innerChart[key];
  });

  return clonedChart;
};

export const confirmation = {
  title: "Are you sure",
  message:
    "Do you really want to delete this drilldown? This process will remove all nested drilldowns ",
  rounded: true,
  zIndexOverride: 999999,
};

export const submitButtonStyles = {
  top: 22,
  position: "absolute",
  right: 20,
  zIndex: 1000000,
};

export const getColumnStyles = (hasAnyGroupings, theme, freezeWidth) => ({
  alignSelf: hasAnyGroupings ? "flex-end" : "center",
  marginRight: 30,
  background: theme.blueGray.blueGray700,
  minWidth: freezeWidth + "px",
  marginBottom: hasAnyGroupings ? 15 : 0,
});

export const inUse = (columns, singleSubtitleArray, overrides) => {
  return overrides.filter(
    (o) =>
      !columns.find(
        (c) =>
          getRidOfAggregation(c) === o.name &&
          !singleSubtitleArray.includes(o.name)
      )
  );
};

export const navigation = [
  {
    name: "Drilldown",
    hasDivider: true,
    icon: ["far", "folder-tree"],
    cy: "navigation-drill-down",
  },
  {
    name: "Layout",
    icon: ["fas", "th-list"],
    cy: "navigation-layout",
  },
  {
    name: "Settings",
    icon: ["fas", "cog"],
    cy: "navigation-settings",
  },
  {
    name: "Filtering",
    icon: ["fas", "filter"],
    cy: "navigation-filtering",
  },
  {
    name: "Column",
    icon: ["fad", "pencil-ruler"],
    cy: "navigation-column",
  },
  {
    name: "MenuFilters",
    icon: ["fal", "list-alt"],
  },
  {
    name: "Rows",
    icon: ["fad", "tasks-alt"],
  },
];

const getDynamicRowExpandParams = (chart, drilldownKey) => {
  return (
    (chart.rowExpandVisualizationParams ?? {})[drilldownKey] ??
    chart.rowExpandVisualizationParams ?? { ...chart, hasRowExpand: false }
  );
};

export const getDynamicDrilldownParent = (drilldownParent, level, parent) => {
  if (!drilldownParent[level]) {
    return { ...drilldownParent, [level]: parent };
  }

  const keys = Object.keys(drilldownParent).filter((key) => +key > level);

  return { ...omit(drilldownParent, keys), [level]: parent };
};

export const getLevelIndicator = (
  levelIndicator,
  level,
  drilldownParent,
  indicatorParent
) => {
  const notInCurrentLevel = levelIndicator !== level;
  const isTopLevel = levelIndicator === 0 && level === 0;

  const empty = isEmpty(drilldownParent);

  const notInCurrentTreeNode =
    !empty && !Object.values(drilldownParent).includes(indicatorParent);

  return (notInCurrentLevel || notInCurrentTreeNode) && !isTopLevel;
};

export const getPath = (key, path) => {
  if (!path.includes(key)) {
    return path + "/" + key;
  }

  return path;
};

/**
 * this function updating dynamic drilldown parent key
 * @param {*} chart summary chart config with all drilldowns inside
 * @param {*} value new parent key
 * @param {*} drilldownParent object with leve: "parent name" => {1: "Product Name"}
 * @param {*} editLevel integer depth level number
 * @returns updated chart config
 */
export const updateParentKey = ({ chart, value, drilldownParent, level }) => {
  const clonedChart = cloneDeep(chart);
  let currentChart = clonedChart;
  let depth = 0;

  while (depth < level) {
    depth++;

    const parent = drilldownParent[level]; // selected parent key

    if (currentChart.rowExpandVisualizationParams[parent]) {
      // change the internals by link
      currentChart.rowExpandVisualizationParams = replaceObjectAndKeepOrder(
        currentChart.rowExpandVisualizationParams,
        parent,
        value
      );
      break;
    }

    // if not found key then go deeper
    currentChart = getDynamicRowExpandParams(
      currentChart,
      drilldownParent[depth]
    );
  }

  return clonedChart;
};

// this complex structure I using to make a hack and keep old order of object key/values
// The iteration order for objects follows a certain set of rules since ES2015, but it does not (always) follow the insertion order
const replaceObjectAndKeepOrder = (params, parent, value) => {
  const keysMap = { [parent]: value };

  return Object.keys(params).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: params[key] },
    }),
    {}
  );
};

export const getIsDisabled = (
  filteredByOptionValues,
  currentQuery,
  parent,
  showParentKey
) => {
  const filteredBy = filteredByOptionValues?.length > 0;
  return !filteredBy || !currentQuery || (showParentKey && !parent);
};

/**
 * Cleans the drilldown parent object by removing properties with keys greater than or equal to the specified level.
 *
 * @param {Object} drilldownParent - The drilldown parent object to be cleaned.
 * @param {number} level - The level used as a threshold to retain properties with keys less than this level.
 * @returns {Object} - The cleaned drilldown parent object.
 */
export const cleanDrilldownParent = (drilldownParent, level) => {
  // Use Object.entries to convert the object to an array of [key, value] pairs,
  // then use filter to retain only pairs where the key is less than the specified level.
  const filteredEntries = Object.entries(drilldownParent).filter(
    ([key]) => +key < level
  );

  // Convert the filtered array of [key, value] pairs back to an object using Object.fromEntries.
  return Object.fromEntries(filteredEntries);
};

export function getUniqueFormatOverridePairs(formatOverrides) {
  const uniqueMap = new Map();

  // Filter the array based on the specified key
  const uniqueArray = formatOverrides.filter((item) => {
    const keyValue = item.conditionValue;

    // If the key value is not already in the map, add it and return true (keep the item)
    if (!uniqueMap.has(keyValue)) {
      uniqueMap.set(keyValue, true);
      return true;
    }

    // If the key value is already in the map, return false (filter out the duplicate)
    return false;
  });

  return uniqueArray.map((pair) => ({
    ...pair,
    value: pair.conditionValue,
    label: `${pair.conditionKey || "..."} | ${pair.conditionValue || "..."}`,
  }));
}

/**
 * Filters formatOverrides to get a list of overrides matching the given pair of conditionKey and conditionValue.
 * @param {Array} formatOverrides - Array of format overrides.
 * @param {Object} pair - Object containing conditionKey and conditionValue.
 * @returns {Array} - Filtered list of format overrides.
 */
export function getGroupedFormatOverrides(formatOverrides = [], pair = {}) {
  return formatOverrides.filter(
    (item) =>
      item.conditionKey === pair.conditionKey &&
      item.conditionValue === pair.conditionValue
  );
}

/**
 * Generates an array of condition key options from the keys of a dataItem.
 * @param {Object} dataItem - Object containing data.
 * @returns {Array} - Array of condition key options.
 */
export function getConditionKeyOptions(dataItem) {
  return Object.keys(dataItem).map((key) => ({
    value: key,
    label: key,
  }));
}

/**
 * Finds the condition key or value option matching the given pair in the options array.
 * @param {Array} options - Array of condition key or value options.
 * @param {Object} pair - Object containing conditionKey or conditionValue.
 * @param {string} key - Key indicating whether to match conditionKey or conditionValue.
 * @returns {Object} - Matching condition key or value option.
 */
export function getConditionKeyValueOption(options, pair, key) {
  return options.find((option) => option.value === pair[key]);
}

/**
 * Generates an array of condition value options from the data using the condition key from the pair.
 * @param {Array} data - Array of data items.
 * @param {Object} pair - Object containing conditionKey.
 * @returns {Array} - Array of condition value options.
 */
export function getConditionValueOptions(data, pair) {
  const allValues = data.map((item) => item[pair.conditionKey]?.trim());
  return removeDuplicates(allValues);
}

/**
 * Updates the specified key in the condition pair with the new value based on the selected option.
 * @param {Object} override - Format override object.
 * @param {Object} option - Selected option to update the condition key or value.
 * @param {Object} pair - Condition pair object.
 * @param {string} key - Key to update (either 'conditionKey' or 'conditionValue').
 * @returns {Object} - Updated format override object.
 */
export function updateConditionKeyValue(override, option, pair, key) {
  const { conditionKey, conditionValue } = pair;

  return override.conditionKey === conditionKey &&
    override.conditionValue.trim() === conditionValue
    ? { ...override, [key]: option.value }
    : override;
}

/**
 * Finds and returns the updated condition pair state based on the provided format overrides and option.
 * @param {Array} formatOverrides - Array of format overrides.
 * @param {Object} option - Selected option containing conditionKey and conditionValue.
 * @returns {Object} - Updated condition pair state.
 */
export function getUpdatedPairState(formatOverrides, option) {
  const newPairs = getUniqueFormatOverridePairs(formatOverrides ?? []);

  return (
    newPairs.find(
      (pair) =>
        pair.conditionKey === option.conditionKey &&
        pair.conditionValue === option.conditionValue
    ) ?? {}
  );
}

/**
 * Checks if the override conditionKey and conditionValue match the provided pair.
 * @param {Object} override - Format override object.
 * @param {Object} pair - Object containing conditionKey and conditionValue.
 * @returns {boolean} - Returns true if the override conditionKey and conditionValue do not match the pair.
 */
export function filterFormatOverrideGroup(override, pair) {
  const { conditionKey, conditionValue } = pair;

  return !(
    override.conditionKey === conditionKey &&
    override.conditionValue === conditionValue
  );
}
