import ColorMode from "./ColorMode";
import FixedColumnsWidth from "./FixedColumnsWidth";
import ProgressBars from "./ProgressBars";
import CustomTextColor from "./CustomTextColor";
import PlainText from "./PlainText";

export default function Styles(props) {
  return (
    <>
      <ColorMode {...props} />
      <hr
        color={props.theme.background.secondary}
        style={{ margin: "10px 0px" }}
      />
      <FixedColumnsWidth {...props} />
      <hr
        color={props.theme.background.secondary}
        style={{ margin: "10px 0px" }}
      />
      <PlainText {...props} />
      <br />
      <ProgressBars {...props} />
      <br />
      <CustomTextColor {...props} />
      <br />
    </>
  );
}
