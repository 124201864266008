import React from "react";
import { matchOne, unique } from "../../utils/func";
import { useTheme } from "emotion-theming";
import TableTd from "./Elements/TableTd";
import { doOverride } from "./tableCellFormatter";
import CellValueObject from "./CellValueObject";
import { useWriteBacksContext } from "./WriteBacksContext";

const TableMainCells = (props) => {
  const {
    rowValues,
    subTitles,
    staticGroupingKeys,
    hasNumeration,
    groupingKey,
    headers,
    meta,
    formatOverrides,
    calculateNAColumns,
    rollup,
    regularFormatting,
    renderAsImageKeys,
    performRounding,
    zebraShadeFromLastIndex,
    totalsConfig,
    rowGroupKey,
    limitPercentAmount,
    dynamicCellFormat,
    boldColumns,
    coloredColumns,
    coloredColumnEdges,
    offsetZebra,
    clearBackground,
    boldRows,
    colors,
    tableRowIndex,
    groupingExtraColumns,
    ...restProps
  } = props;

  const theme = useTheme();

  const writeBacks = useWriteBacksContext();

  const formatsByKey = mapMetaFieldTypes();

  function mapMetaFieldTypes() {
    return meta?.fields ? meta.fields.reduce(getFormatType, {}) : [];
  }

  function getFormatType(acc, curr) {
    return { ...acc, [curr.alias || curr.name]: curr.type };
  }

  const makeCol = (dataRow, dataRowIndex) => (subKey, i) => {
    // const isChartCell = !!dataRow.breakdown;
    // if (isChartCell) {
    //   const isFunction = subKey.includes("fn::");
    //   let merged = {};
    //
    //   if (isFunction) {
    //     const parts = subKey.split("::");
    //     const partA = parts[2];
    //     const partB = parts[3];
    //     // Grab first key to create
    //     const entries = dataRow.breakdown[partA].values.map((v) => v.key);
    //
    //     merged = entries.reduce((acc, curr) => {
    //       const key = {
    //         values: {
    //           [partA]: dataRow.breakdown[partA].values.find(
    //             (v) => v.key === curr
    //           ).value,
    //           [partB]: dataRow.breakdown[partB].values.find(
    //             (v) => v.key === curr
    //           ).value,
    //         },
    //       };
    //       return { ...acc, [curr]: key };
    //     }, {});
    //
    //     entries.forEach((entry) => {
    //       merged[entry].values = {
    //         ...merged[entry].values,
    //         computed: tableFunctionConvertor(subKey, merged[entry].values)
    //           .value,
    //       };
    //     });
    //   }
    //
    //   return (
    //     <td key={i}>
    //       <TableChartCell
    //         type={isFunction ? "line" : dataRow.breakdown.type}
    //         values={
    //           isFunction
    //             ? merged
    //             : dataRow.breakdown[subKey] && dataRow.breakdown[subKey].values
    //         }
    //         subKey={subKey}
    //         show={subKey !== rowGroupKey}
    //         colors={colors}
    //       />
    //     </td>
    //   );
    // }
    if (subKey === "--" || subKey === "n/a") return <td key={i}>{subKey}</td>;

    function hasMatchingKey() {
      const availableKeys = unique(rowValues.reduce(spreadKeys, []));
      function spreadKeys(acc, curr) {
        return [...acc, ...Object.keys(curr)];
      }
      return !!matchOne(subKey)(availableKeys);
    }

    function matchWithOverrides() {
      return doOverride(formatOverrides, dataRow, subKey, formatsByKey);
    }

    function matchByKey() {
      if (groupingExtraColumns?.column === subKey) {
        return groupingExtraColumns.format;
      }

      const keyMatchedFromMeta = formatsByKey[subKey];
      const rollupMatch = rollup && rollup["dynamicColumnFormat"];
      return keyMatchedFromMeta || rollupMatch;
    }

    function setPercentLimit(cell) {
      const { formatted = "" } = cell;
      const stringed = formatted.toString();

      if (stringed.includes("%")) {
        const limit = parseInt(limitPercentAmount);
        const value = parseInt(stringed.replaceAll(",", ""));

        if (value > limit) {
          return limitPercentAmount;
        }

        if (value < -limit) {
          return `-${limitPercentAmount}`;
        }
      }

      return formatted;
    }

    const format = formatOverrides ? matchWithOverrides() : matchByKey();
    const {
      overrideFormat,
      overridePrecision,
      reverseColor,
      overrideColor,
      overrideDynamicColor,
    } = format ?? {};

    // this settings need for grouping tables
    // where our calculation looks like: Current Month Revenue - Prev Month Revenue
    // calculation using formula so it can be any period (quarter, year...) and any math operation
    const periodCalculationSettings = {
      groupingKey,
      rowValues,
    };

    const cellValueObj = new CellValueObject(
      writeBacks,
      dataRow,
      subKey,
      overrideFormat ?? format,
      overridePrecision,
      performRounding,
      totalsConfig,
      dynamicCellFormat,
      !!overrideFormat,
      restProps.progressBarsSettings,
      restProps.groupingKeyMapping,
      periodCalculationSettings
    );

    cellValueObj.setShadeConfigurations(
      clearBackground,
      staticGroupingKeys,
      headers,
      zebraShadeFromLastIndex,
      subTitles,
      groupingKey,
      hasNumeration,
      offsetZebra
    );

    const shouldRenderAsFunction = subKey.includes("fn::");

    if (shouldRenderAsFunction) {
      cellValueObj.enableFunctionConversion(
        calculateNAColumns,
        regularFormatting
      );
    }

    const shouldRenderAsOpenMathFunction = subKey.includes("omf::");

    if (shouldRenderAsOpenMathFunction) {
      cellValueObj.enableOpenMathFormulaConversion();
    }

    const shouldRenderAsImage =
      renderAsImageKeys && renderAsImageKeys.find((image) => image === subKey);
    if (shouldRenderAsImage) {
      cellValueObj.setRenderAsImage();
    }

    const cellValueObject = cellValueObj.getValue();

    const colored = !(coloredColumns ?? []).length
      ? ["NOTHING_COLORED"]
      : coloredColumns;

    const useDynamicColor = cellValueObj.useDynamicColor(colored);
    const badDebt = subKey.includes("BAD_DEBT");
    const conditionalReversed = badDebt && cellValueObject.value === 1;

    // Break out into colorizer utility
    function setColorValue() {
      if (overrideDynamicColor) {
        return reverseColor ? -cellValueObject.value : cellValueObject.value;
      }

      if (overrideColor) {
        return overrideColor;
      }

      if (!useDynamicColor && !reverseColor) {
        return null;
      }

      if (conditionalReversed) {
        return cellValueObject.value;
      }

      if (
        useDynamicColor === "reverse" ||
        reverseColor ||
        groupingExtraColumns?.reverseColor ||
        cellValueObj.isReverse
      ) {
        return -cellValueObject.value;
      }

      return cellValueObject.value;
    }

    function getColoredEdge() {
      if (useDynamicColor) {
        const coloredColumnEdge = coloredColumnEdges?.find(
          (edge) => edge.columnName === subKey
        );

        return {
          positive: coloredColumnEdge?.positive || 0,
          negative: coloredColumnEdge?.negative || 0,
        };
      }

      return { positive: 0, negative: 0 };
    }

    if (limitPercentAmount) {
      cellValueObject.formatted = setPercentLimit(cellValueObject);
    }

    // for subtitles case [["1", "2", "3"...]]
    const oneLineSubtitles = subTitles?.length === 1 && subTitles[0].length > 1;
    const tdProps = {
      hasZebraBackground: cellValueObj.shade(
        oneLineSubtitles ? i : dataRowIndex,
        oneLineSubtitles
      ),
      cellValueObject,
      color: setColorValue(),
      theme,
      dataRow,
      cellKey: subKey,
      i,
      boldColumns,
      boldRows,
      overrideColor: formatOverrides?.overrideColor,
      coloredEdge: getColoredEdge(),
    };

    const shouldRender =
      shouldRenderAsFunction ||
      shouldRenderAsOpenMathFunction ||
      hasMatchingKey();

    const groupingIndex =
      groupingKey &&
      headers.findIndex((v) => (v || "").includes(dataRow[groupingKey]));

    return (
      shouldRender && (
        <TableTd
          key={i + subKey}
          {...tdProps}
          {...restProps}
          borderRight={subKey.includes("BORDERED")}
          tableRowIndex={tableRowIndex}
          colors={colors}
          groupingKey={groupingKey}
          groupingIndex={groupingIndex}
        />
      )
    );
  };

  const subs = subTitles.reduce((acc, curr) => [...acc, ...curr], []);

  const cluster = (dataRow, dataRowIndex) =>
    groupingKey
      ? subTitles[dataRowIndex + 1]?.map(makeCol(dataRow, dataRowIndex))
      : subs.map(makeCol(dataRow, dataRowIndex));

  return rowValues.map(cluster);
};

export default React.memo(TableMainCells);
