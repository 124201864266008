import { useState } from "react";
import isEqual from "react-fast-compare";

export default function (initial = []) {
  const [arr, setArr] = useState(initial);

  init();
  function init() {
    if (!Array.isArray(initial))
      throw new Error("Must be initialized with array or left empty");
  }

  function handleToggle(el) {
    const currentlyIncluded = arr.find((m) => isEqual(m, el));
    if (currentlyIncluded !== undefined) {
      return setArr(arr.filter((m) => !isEqual(m, el)));
    } else {
      return setArr([...arr, el]);
    }
  }

  return [arr, handleToggle];
}
