import { createContext, useContext, useState, useMemo } from "react";
import axios from "../../axios";
import produce from "immer";
import { useDispatch } from "react-redux";
import { showToastWithTimeout } from "../../store/actions/message";
import { handleError } from "../../utils/errorHandling";
import { initLoadCharts } from "../../store/actions";

export const defaultConfig = {
  canCreate: false,
  canDelete: false,
  instructions: "",
  type: null,
  writtenBacks: {},
};
const Context = createContext(defaultConfig);

export function WriteBacksProvider({ configInput, queryUuid, children }) {
  const config = useMemo(() => {
    return typeof configInput === "object"
      ? configInput
      : { type: configInput };
  }, [configInput]);
  const type = config.type;
  const dispatch = useDispatch();
  // We store rows that were already written back, so we would display the
  // updated values everywhere even if we don't refresh the table data.
  const [writtenBacks, setWrittenBacks] = useState({});

  return (
    <Context.Provider
      value={{
        ...defaultConfig,
        ...config,
        type,
        writtenBacks,
        writeBackField,
        writeBackRow,
        writeBackDrillDownRows,
        doWriteBackRow,
        refreshCharts,
        deleteWriteBackRow,
      }}
    >
      {children}
    </Context.Provider>
  );

  async function writeBackField(uuid, field, value) {
    const ret = await writeBackRow(uuid, { [field]: value });
    showToastWithTimeout(dispatch, "Field saved successfully.", "success");
    return ret;
  }

  async function writeBackRow(uuid, dataRow, dbtConnection) {
    const ret = await doWriteBackRow(uuid, dataRow, dbtConnection);
    if (!uuid) {
      return ret;
    }

    showToastWithTimeout(dispatch, "Record was saved successfully.", "success");
    return ret;
  }

  async function writeBackDrillDownRows(drillDownQueryUuid, dataRows) {
    for (let dataRow of dataRows) {
      const uuid = dataRow.uuid;
      delete dataRow["uuid"];

      try {
        await axios.request({
          method: uuid ? "PUT" : "POST",
          url: `/api/v1/queries/${drillDownQueryUuid}/write_backs`,
          data: {
            uuid,
            updatedValues: JSON.stringify([dataRow]),
          },
        });
      } catch (e) {
        handleError(dispatch, e, {
          toastMessagePrefix: "Failed to save record",
        });
        throw e;
      }
    }
  }

  async function doWriteBackRow(uuid, dataRow, dbtConnection, visualizationId) {
    const updatedRow = dataRow;
    try {
      let newUuid = null;

      const payload = {
        uuid,
        updatedValues: JSON.stringify([updatedRow]),
      };

      if (dbtConnection) {
        payload["dbtConnection"] = { ...dbtConnection };
      }

      await axios
        .request({
          method: uuid ? "PUT" : "POST",
          url: `/api/v1/queries/${queryUuid}/write_backs`,
          data: payload,
        })
        .then((res) => {
          newUuid = res.data.data.IOUUID;
        });
      setWrittenBacks((writtenBacks) =>
        produce(writtenBacks, (draft) => {
          if (!(uuid in draft)) {
            draft[uuid] = {};
          }
          Object.assign(draft[uuid], updatedRow);
        })
      );

      if (!uuid && visualizationId) {
        // reload charts after adding new record without global page reload
        dispatch(initLoadCharts(visualizationId));
      }

      return newUuid;
    } catch (e) {
      handleError(dispatch, e, {
        toastMessagePrefix: "Failed to save record",
      });
      throw e;
    }
  }

  async function deleteWriteBackRow(uuid, visualizationId) {
    if (uuid && visualizationId) {
      await axios.request({
        url: `api/v1/queries/${queryUuid}/write_backs`,
        method: "DELETE",
        data: {
          uuid: uuid,
        },
      });

      dispatch(initLoadCharts(visualizationId));
    }
  }

  function refreshCharts() {
    dispatch(initLoadCharts());
  }
}

const IO_UUID_FIELD = "io_uuid";
const IO_UUID_SQLSRV_FIELD = "IOUUID";
export const IO_UUID_FIELDS = [IO_UUID_FIELD, IO_UUID_SQLSRV_FIELD];

export function getRowIoUuid(row) {
  return row && (row[IO_UUID_FIELD] ?? row[IO_UUID_SQLSRV_FIELD]);
}

export function useWriteBacksContext() {
  return useContext(Context);
}

export const WRITE_BACKS_TYPES = {
  crm: "crm",
  inline: "inline",
  survey: "survey",
};
