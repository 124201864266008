import React from "react";
import PropTypes, { func } from "prop-types";
import { FilterEditorRow } from "./FilterEditorRow";
import axios from "../../../../axios";

import styled from "@emotion/styled";
import AddFilter from "./AddFilter";

const FilterEditorWrapper = styled.div`
  box-sizing: border-box;
  padding-top: 32px;
  position: relative;
`;

export default function FilterEditor(props) {
  const { filters, setShowFilters, ...restProps } = props;

  return (
    <FilterEditorWrapper>
      {filters.map((filter, index) => (
        <FilterEditorRow
          key={filter + "-" + index}
          index={index}
          filter={filter}
          axios={axios}
          {...restProps}
        />
      ))}

      <div style={{ display: "flex", alignItems: "center", marginBottom: 36 }}>
        <AddFilter onClick={props.filterApi.addFilter} />
      </div>
    </FilterEditorWrapper>
  );
}

FilterEditor.defaultProps = {
  filters: [],
};

FilterEditor.propTypes = {
  filters: PropTypes.array,
  fields: PropTypes.array,
  handleSetField: func,
  setOperator: func,
  setValues: func,
};
