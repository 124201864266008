import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import getVisualizationLabel from "../../utils/getVisualizationLabel";
import Th from "./Elements/Th";

const termSelectorMemoFn = (state) => state.layout.currentDateTerm;

export default React.memo(function ParentHeaders(props) {
  const term = useSelector(termSelectorMemoFn);

  const {
    headers,
    hasNumeration,
    subTitles,
    meta,
    freezeLeft,
    freezeWidth,
    allowGroupsRightBorder,
    staticGroupingKeys,
    stickyHeaders,
    parentHeaderPrefix = "",
    postfixes,
    hiddenGroupings,
    staticPostfixColumn,
    rows,
    isGroupingSortable,
    sortDynamicGrouping,
    groupingKeysAlign,
    maxColumnsWidth = {},
    parentHeaderSort,
    setParentHeaderSort,
  } = props;

  function generatePostfixes() {
    const { values } = rows[0];
    return ["", ...values.map((val) => val[staticPostfixColumn])];
  }

  const postfixOverrides = staticPostfixColumn
    ? generatePostfixes()
    : postfixes;

  const headersMemo = useMemo(() => {
    if (!hiddenGroupings?.length) {
      return headers;
    }

    return headers.filter((header) => !hiddenGroupings.includes(header));
  }, [hiddenGroupings, headers]);

  const makeSort = useCallback(
    (header) => {
      if (!isGroupingSortable) {
        return;
      }

      setParentHeaderSort((prev) => {
        const direction = prev.direction === "ASC" ? "DESC" : "ASC";

        sortDynamicGrouping(header, direction);
        return {
          header,
          direction,
        };
      });
    },
    [isGroupingSortable, setParentHeaderSort, sortDynamicGrouping]
  );

  const setTextAlign = useCallback(
    (header) => {
      if (!groupingKeysAlign) {
        return null;
      }

      return groupingKeysAlign[header] ?? groupingKeysAlign.others;
    },
    [groupingKeysAlign]
  );

  if (!headersMemo) {
    return null;
  }

  return (
    <tr>
      {headersMemo.map((item, index) => {
        const prefix = setPrefix(index, parentHeaderPrefix);

        const header = getDynamicHeader(
          item?.replace("::BORDERED", ""),
          term,
          meta,
          staticGroupingKeys
        );

        const postfix = setPostfix(postfixOverrides, index);

        return (
          <Th
            freeze={freezeLeft && index === 0}
            freezeWidth={freezeWidth}
            colSpan={getColSpan(subTitles, index)}
            key={item + index}
            background={getBackground(hasNumeration, index)}
            borderRight={allowGroupsRightBorder || item?.includes("::BORDERED")}
            stickyHeaders={stickyHeaders}
            onClick={() => makeSort(item)}
            pointer={isGroupingSortable}
            sorting={parentHeaderSort?.header === item}
            sortOrder={parentHeaderSort?.direction}
            textAlign={setTextAlign(item)}
            maxColumnsWidth={maxColumnsWidth[item]}
          >
            {prefix && <div>{prefix}</div>}
            {header && <div>{header}</div>}
            {postfix && <div>{postfix}</div>}
          </Th>
        );
      })}
    </tr>
  );
});

function setPrefix(index, parentHeaderPrefix) {
  return index === 0 ? "" : parentHeaderPrefix;
}

function setPostfix(postfixes, index) {
  if (!postfixes || !postfixes[index]) {
    return null;
  }

  return postfixes[index];
}

function getColSpan(subTitles, index) {
  return subTitles && subTitles[index] ? subTitles[index].length : 1;
}

function getBackground(hasNumeration, index) {
  return hasNumeration ? index % 2 === 0 && index !== 0 : index % 2;
}

function getDynamicHeader(header, term, meta, staticGroupingKeys) {
  if (header === "[DYNAMIC_DATE_TERM]") {
    return term;
  }

  return !staticGroupingKeys
    ? getVisualizationLabel(meta?.fields, header)
    : header;
}
