import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { inputBorders } from "../../../../styles/styledSnippets";
import SampleNumberPreview from "./NumberInput/SampleNumberPreview";
import FilterInstruction from "./FilterInstruction";

const entryWidth = 226;

const Wrapper = styled.div`
  font-size: 12px;
  height: 10px;
  margin-right: 12px;
  position: relative;
  margin-left: 4px;
  width: ${entryWidth}px;
`;

const Container = styled.div`
  position: absolute;
  width: ${entryWidth}px;
`;

const Input = styled.input`
  font-size: 12px;
  ${(props) => inputBorders(props)}
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border: 2px solid #2684ff;
    outline: none;
  }
`;

export default function ExplorerNumberInput(props) {
  const { sampleResults, operator, handleAddValue } = props;
  const [firstValue, setFirstValue] = useState("");
  const [isFocus, setIsFocus] = useState(false);

  const handleChangeValue = (e) => {
    setFirstValue(e.target.value);
  };

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      sendUpdate();
    }, 500);

    function sendUpdate() {
      if (!isNaN(+firstValue)) {
        handleAddValue(+firstValue);
      }
    }
    return () => clearTimeout(typingTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstValue]);

  return (
    <Wrapper isFocus={isFocus}>
      <SampleNumberPreview sampleResults={sampleResults} show={isFocus} />
      <Container data-cy="explorer-number-input">
        <FilterInstruction
          show={!operator}
          message="Select filtering mode"
          cy="explorer-number-message"
        />

        {operator ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              value={firstValue}
              onChange={handleChangeValue}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            />
          </div>
        ) : null}
      </Container>
    </Wrapper>
  );
}
