import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../../UI/Loading/Loading";
import PropTypes from "prop-types";

const Container = styled.div`
  border-radius: 2px;
  background: ${(props) => props.theme.gray.gray900};
  z-index: 200;
  width: 440px;
  height: calc(100vh - 16px);
  color: rgba(255, 255, 255, 0.6);
  padding: 32px 12px 32px 24px;
  box-sizing: border-box;
  text-align: left;
`;

const CloseArrow = styled.div`
  position: absolute;
  top: 10px;
  right: 18px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;
const Title = styled.div`
  font-size: 14px;

  svg {
    font-size: 24px;
    margin-right: 10px;
  }
`;

export default function ExplorerLeftTray(props) {
  const { cy, title, handleClose, children, loading, list } = props;
  return (
    <Container data-cy={cy}>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: 12 }}>
          <CloseArrow onClick={handleClose} data-cy="close-tray">
            <FontAwesomeIcon icon={["fas", "arrow-left"]} />
          </CloseArrow>

          <Title>
            {list && <FontAwesomeIcon icon={["fal", "folder"]} />}
            {title}
          </Title>
          <div style={{ marginTop: 24 }}>{children}</div>
        </div>
      )}
    </Container>
  );
}

ExplorerLeftTray.propTypes = {
  cy: PropTypes.string,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  loading: PropTypes.bool,
};
