import { customFilterOptionsByValue } from "../../../utils/constants/constants";
import { useShallowEqualSelector } from "../../../store";
import ToDateFilters from "../../LeftMenu/ToDateFilters";
import { useEffect, useState } from "react";
import { setDateFilters } from "../../../utils/formatters/dateFormatter";
import { useDispatch } from "react-redux";
import { saveSelectedDatePresets } from "../../../store/actions/layout";

export default function DatePresets({ type, select, name }) {
  const { dateFiltersConfig, domain, customDatesFilterTypeMap } =
    useShallowEqualSelector((state) => state.layout);

  const [dateType, setDateType] = useState({
    value: customDatesFilterTypeMap[name],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (dateType) {
      dispatch(saveSelectedDatePresets({ [name]: dateType.value }));
    }
  }, [dateType, dispatch, name]);

  function getHasParameterProperty(type) {
    return customFilterOptionsByValue[type.value]?.hasParameter;
  }

  function recentMonthsChange({ from, to }) {
    const filters = buildFilters(from, to, name);
    select(filters, type);
  }

  function setSelectedDateType(dateType) {
    setDateType(dateType);
    const hasParameter = getHasParameterProperty(dateType);

    // if has not additional parameter its mean that we selecting regular dates
    // YTD, MTD, QTD and etc.
    if (!hasParameter) {
      if (!dateType.value) {
        return select([{ key: name }], type); // handle "None..." option
      }

      const { from, to } = setDateFilters(domain, dateType.value);
      const filters = buildFilters(from, to, name);
      select(filters, type);
    }
  }

  function setCustomDateRange(from, to) {
    const filters = buildFilters(from, to, name);
    select(filters, type);
  }

  return (
    <div>
      <ToDateFilters
        setSelectedDateType={setSelectedDateType}
        selectedDateType={dateType}
        recentMonthsChange={recentMonthsChange}
        dateFiltersConfig={dateFiltersConfig}
        domain={domain}
        dateConfig={{ setCustomDateRange }}
        dropDateGroup
      />
    </div>
  );
}

function buildFilters(from, to, name) {
  const createFilter = (value) => ({
    key: name,
    type: name,
    value,
    checked: true,
    label: value,
  });

  return [createFilter(from), createFilter(to)];
}
