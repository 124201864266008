import produce from "immer";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  data: null,
  loading: false,
  errors: null,
  activeSettings: null,
  saveQuery: {},
  explorerMode: null,
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_QUERY_EXEC_SETTINGS_START: {
        draft.loading = true;
        break;
      }
      case actionTypes.GET_QUERY_EXEC_SETTINGS_SUCCESS: {
        draft.loading = false;
        draft.data = action.results.data;
        draft.errors = null;
        break;
      }

      case actionTypes.GET_QUERY_EXEC_SETTINGS_FAIL: {
        draft.loading = false;
        draft.errors = action.errors;
        draft.data = false;
        break;
      }

      case actionTypes.DELETE_QUERY_EXEC_START: {
        draft.deleting = action.uuid;
        break;
      }

      case actionTypes.DELETE_QUERY_EXEC_SUCCESS: {
        draft.data = draft.data.filter((d) => d.uuid !== action.uuid);
        draft.deleting = null;
        break;
      }

      case actionTypes.GET_QUERY_EXEC_SETTINGS_RESET:
        return initialState;

      case actionTypes.SET_ACTIVE_EXEC_SETTINGS: {
        draft.activeSettings = { ...action.result, id: action.result.uuid };
        break;
      }

      case actionTypes.UPDATE_QUERY_EXEC_START:
      case actionTypes.SAVE_QUERY_EXEC_START: {
        draft.saveQuery.loading = true;
        draft.saveQuery.error = null;
        break;
      }

      case actionTypes.SAVE_QUERY_EXEC_SUCCESS:
      case actionTypes.UPDATE_QUERY_EXEC_SUCCESS: {
        const { name, settings, queryUuid } = action.results.data;
        draft.saveQuery = {
          loading: false,
          name,
          settings,
          queryUuid,
        };
        break;
      }
      case actionTypes.SAVE_QUERY_EXEC_FAIL:
      case actionTypes.UPDATE_QUERY_EXEC_FAIL:
        draft.saveQuery.loading = false;
        draft.saveQuery.error = action.error;
        break;

      case actionTypes.SET_EXPLORER_MODE: {
        draft.explorerMode = getMode();
        if (action.mode !== "settings") {
          draft.activeSettings = null;
        }
        function getMode() {
          return action.mode === "settings" ? "report" : "query";
        }
        break;
      }

      case actionTypes.CLEAR_EXPLORER_MODE: {
        draft.explorerMode = null;
        break;
      }

      default:
        return draft;
    }
  });
