import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import Switcher from "../../../../UI/Switcher/Switcher";
import Input from "../../../../UI/Form/Input/Input";
import Checkbox from "../../../../UI/Form/Checkbox/Checkbox";
import { FieldWrapper } from "./GlobalSettings";

export const RowStyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  span {
    font-size: 12px !important;
  }
  hr {
    width: 100%;
  }
`;

export default function RowStyles({ setChartState, chart, theme, fields }) {
  const {
    overrides,
    uniqueValueKey,
    freezeLeft,
    freezeWidth,
    emptyFreezeLeft,
    freezeNextColumn,
    rowGroupKey,
  } = chart;

  function getOption(fields) {
    return fields.find(
      (field) => field.name === uniqueValueKey || field.name === rowGroupKey
    );
  }
  const option = getOption(overrides) ?? getOption(fields);

  const uniqueValueKeyChange = ({ name }) => {
    const key = uniqueValueKey ? "uniqueValueKey" : "rowGroupKey";
    setChartState({ ...chart, [key]: name });
  };

  const rowStyleChange = () => {
    setChartState({
      ...chart,
      rawRows: !!uniqueValueKey,
      uniqueValueKey: uniqueValueKey
        ? null
        : chart.overrides[0]?.name ?? fields[0]?.name,
    });
  };

  const frozenColumnChange = () => {
    setChartState({
      ...chart,
      freezeWidth: freezeLeft ? null : 0,
      freezeLeft: !freezeLeft,
      emptyFreezeLeft: freezeLeft ? null : emptyFreezeLeft,
    });
  };

  const freezeWidthChange = (e) => {
    setChartState({ ...chart, freezeWidth: e.target.value });
  };

  const freezeCheckboxChange = (name) => {
    setChartState({
      ...chart,
      [name]: !chart[name],
    });
  };

  return (
    <RowStyleContainer>
      <span>Row Style</span>
      <br />
      <Switcher
        leftLabel="auto"
        rightLabel="manually set row key"
        checked={!!uniqueValueKey || !!rowGroupKey}
        handleSwitchChange={rowStyleChange}
      />
      <br />
      {(uniqueValueKey || rowGroupKey) && (
        <FieldWrapper>
          <Select
            options={overrides.length ? overrides : fields}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            value={option}
            onChange={uniqueValueKeyChange}
          />
        </FieldWrapper>
      )}
      <br />
      <hr color={theme.background.secondary} />
      <br />
      <span>First column lock</span>
      <br />
      <div style={{ display: "flex" }}>
        <Switcher
          leftLabel="default"
          rightLabel="frozen"
          checked={!!freezeLeft}
          handleSwitchChange={frozenColumnChange}
        />
        {freezeLeft && (
          <Input
            value={freezeWidth}
            type="number"
            style={{ width: "50%" }}
            onChange={freezeWidthChange}
          />
        )}
      </div>
      <br />
      {freezeLeft && (
        <>
          <Checkbox
            checked={!!emptyFreezeLeft}
            label="Add frozen empty column"
            onChange={() => freezeCheckboxChange("emptyFreezeLeft")}
          />
          <br />
          <Checkbox
            checked={!!freezeNextColumn}
            label="Freeze second column"
            onChange={() => freezeCheckboxChange("freezeNextColumn")}
          />
        </>
      )}
      <br />
      <hr color={theme.background.secondary} />
      <br />
    </RowStyleContainer>
  );
}
