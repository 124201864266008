import * as actions from "../actions/actionTypes";
import produce from "immer";

export const initialState = {
  loading: false,
  etlConfigs: [],
  uploading: false,
  uploadSuccess: false,
  uploadErrors: null,
  processings: {
    loading: false,
    links: null,
    meta: null,
    list: [],
  },
  scheduleLoading: false,
  approveLoading: false,
  rejectLoading: false,
  downloadFileLoading: false,
  previewData: {
    loading: false,
    rows: null,
    error: null,
  },
};

function convertApiItemToStoreItem(c) {
  return {
    id: c.uuid,
    config: c.etlConfig ? c.etlConfig.name : "",
    etlConfig: c.etlConfig,
    createdAt: c.createdAt,
    status: c.status,
    filename: c.originalFilename || null,
    recordsProcessed: +c.recordsProcessed,
    notes: c.notes,
    messages: c.messages,
    user: c.user,
    isApproved: c.isApproved,
    needsApproval: c.etlConfig?.needsApproval,
    etlConfigUuid: c.etlConfig?.uuid,
    userName: c.user?.name,
    userEmail: c.user?.email,
    configuration: c.etlConfig?.name,
  };
}

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case actions.GET_ETL_CONFIG_LIST_START:
    case actions.ADD_APPROVER_USER_START:
    case actions.REMOVE_APPROVER_USER_START: {
      draft.loading = true;
      draft.uploadErrors = null;
      break;
    }

    case actions.GET_ETL_CONFIG_LIST_SUCCESS: {
      draft.loading = false;
      draft.etlConfigs = action.results.data;
      break;
    }

    case actions.GET_ETL_PROCESSINGS_START: {
      draft.processings = {
        loading: true,
        links: null,
        meta: null,
        list: [],
      };
      break;
    }

    case actions.GET_ETL_PROCESSINGS_FAIL: {
      draft.processings = {
        loading: false,
        links: null,
        meta: null,
        list: [],
      };
      break;
    }

    case actions.RELOAD_ETL_PROCESSINGS_SUCCESS:
    case actions.GET_ETL_PROCESSINGS_SUCCESS: {
      draft.processings.loading = false;
      draft.processings.list = action.results.data.map(
        convertApiItemToStoreItem
      );
      draft.processings.links = action.results.links;
      draft.processings.meta = action.results.meta;
      break;
    }

    // Updates a single processing item.
    case actions.UPDATE_ETL_PROCESSING:
      const apiItem = action.payload;
      const itemToUpdate = draft.processings.list.find(
        (item) => item.id === apiItem.uuid
      );
      if (!itemToUpdate) {
        break;
      }

      Object.assign(itemToUpdate, convertApiItemToStoreItem(apiItem));
      break;

    case actions.RESET_ETL_FILE_UPLOAD:
    case actions.UPLOAD_ETL_FILE_INIT: {
      draft.uploadSuccess = false;
      draft.uploading = false;
      draft.uploadErrors = null;
      break;
    }

    case actions.UPLOAD_ETL_FILE_START: {
      draft.uploading = true;
      draft.uploadSuccess = false;
      break;
    }

    case actions.UPLOAD_ETL_FILE_FAIL: {
      draft.uploading = false;
      draft.uploadSuccess = false;
      draft.uploadErrors = action.errors;
      break;
    }

    case actions.UPLOAD_ETL_FILE_SUCCESS: {
      const newProcessing = {
        id: action.results.data.uuid,
        config: action.results.data.etlConfig
          ? action.results.data.etlConfig.name
          : "",
        createdAt: action.results.data.createdAt,
        status: action.results.data.status,
        filename: action.results.data.originalFilename,
        recordsProcessed: action.results.data.recordsProcessed,
        notes: action.results.data.notes,
        messages: action.results.data.messages,
        user: action.results.data.user,
        userName: action.results.data.user.name,
      };

      draft.uploading = false;
      draft.processings.list.unshift(newProcessing);
      draft.uploadErrors = false;
      draft.uploadSuccess = true;
      break;
    }

    case actions.SCHEDULE_EXPORT_START: {
      draft.scheduleLoading = true;
      break;
    }

    case actions.SCHEDULE_EXPORT_SUCCESS:
    case actions.SCHEDULE_EXPORT_FAIL: {
      draft.scheduleLoading = false;
      break;
    }

    case actions.APPROVE_PROCESSING_STATUS_START: {
      draft.approveLoading = true;
      break;
    }

    case actions.REJECT_PROCESSING_STATUS_START: {
      draft.rejectLoading = true;
      break;
    }

    case actions.APPROVE_PROCESSING_STATUS_SUCCESS: {
      updateApprovalState(draft, "pending", action);
      break;
    }

    case actions.REJECT_PROCESSING_STATUS_SUCCESS: {
      updateApprovalState(draft, "processing_failed", action);
      break;
    }

    case actions.APPROVE_PROCESSING_STATUS_FAIL:
    case actions.REJECT_PROCESSING_STATUS_FAIL: {
      draft.rejectLoading = false;
      draft.approveLoading = false;
      break;
    }

    case actions.PREVIEW_PROCESSING_FILE_START: {
      draft.previewData.loading = true;
      draft.previewData.rows = null;
      draft.previewData.error = null;
      break;
    }

    case actions.PREVIEW_PROCESSING_FILE_SUCCESS: {
      draft.previewData.loading = false;
      draft.previewData.rows = splitAtLineBreaks();

      function splitAtLineBreaks() {
        return action.results.data.content.split("\r\n");
      }
      break;
    }

    case actions.PREVIEW_PROCESSING_FILE_FAIL: {
      draft.previewData.error = action.error.message;
      draft.previewData.loading = false;
      break;
    }

    case actions.DOWNLOAD_PROCESSING_FILE_SUCCESS:
    case actions.DOWNLOAD_PROCESSING_FILE_FAIL: {
      draft.downloadFileLoading = false;
      break;
    }

    case actions.DOWNLOAD_PROCESSING_FILE_START: {
      draft.downloadFileLoading = true;
      break;
    }

    case actions.ADD_APPROVER_USER_FAIL:
    case actions.REMOVE_APPROVER_USER_FAIL: {
      draft.loading = false;
      break;
    }

    default:
      return draft;
  }
});

function updateApprovalState(draft, status, action) {
  draft.rejectLoading = false;
  draft.approveLoading = false;

  const processingId = action.results.data.uuid;
  const draftList = [...draft.processings.list];
  draft.processings.list = draftList.map((p) =>
    p.id === processingId ? { ...p, status } : p
  );
  return draft;
}
