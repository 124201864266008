const isExpandableRow = (isDrillable, row) => {
  if (!row || !row.values) {
    return;
  }
  return row[isDrillable] || row.values.some((val) => val[isDrillable]);
};

export const getFilterPrefix = (chart) => {
  if (chart.rowExpandVisualizationParams?.isParameterized === false) {
    return "";
  }

  return (
    chart.rowExpandVisualizationParams?.dynamicFilterPrefix ||
    chart.parameterizedFilterPrefix
  );
};

export function getExpandableBoolean({
  index,
  hasCommonTotalRow,
  row = {},
  isDrillable,
  hasRowExpand,
  expandedTitle,
  expandIndexes,
}) {
  if (isDrillable) {
    return isExpandableRow(isDrillable, row);
  }

  if (hasCommonTotalRow || !hasRowExpand || expandedTitle) {
    return false;
  }

  if (Array.isArray(expandIndexes)) {
    return expandIndexes.includes(index);
  }

  return true;
}

const buildDynamicFilter = (type, value, prefix) => {
  return {
    type: (prefix ?? "") + type,
    value,
  };
};

export const getDynamicFilterValue = (
  row = {},
  dynamicFilter,
  dynamicFilterValue
) => {
  const dynamic = dynamicFilterValue || dynamicFilter;
  return (
    row[dynamic] ||
    (row.values && row.values[0] && row.values[0][dynamic]) ||
    null
  );
};

export const buildRowIndexKey = (row, dynamicFilters, dynamicFilterValue) => {
  return (dynamicFilters || []).reduce((acc, curr) => {
    const value = getDynamicFilterValue(row, curr, dynamicFilterValue);
    acc += value;
    return acc;
  }, "");
};

export const buildDynamicFiltersQuery = (
  row,
  dynamicFilters,
  dynamicFilterValue,
  prefix
) => {
  return (dynamicFilters || []).reduce((acc, curr) => {
    const value = getDynamicFilterValue(row, dynamicFilterValue || curr);
    const filter = buildDynamicFilter(curr, value, prefix);
    acc.push(filter);

    return acc;
  }, []);
};

export const removeRowIndex = (rowIndexes, expandedRow) => {
  return rowIndexes.filter((rowIndex) => rowIndex !== expandedRow);
};

export const postMessageFromIframe = (parameter, value) => {
  const message = {
    type: "IO-message",
    [parameter]: value || null,
  };

  window.parent.postMessage(message, "*");
};
