import * as actions from "../actions/actionTypes";

const initialState = {
  dataFreshness: [],
  insights: [],
  loading: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_DATA_FRESHNESS_SUCCESS:
      return {
        ...state,
        dataFreshness: action.results.data,
      };
    case actions.GET_INSIGHTS_START:
      return {
        ...state,
        loading: [...state.loading, "insights"].filter(
          (v, i, a) => a.indexOf(v) === i
        ),
      };
    case actions.GET_INSIGHTS_SUCCESS:
      return {
        ...state,
        loading: state.loading.filter((l) => l !== "insights"),
        insights: action.results.data.map((datum) => datum.metadata),
      };
    default:
      return { ...state };
  }
};
