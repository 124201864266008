import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuery } from "../../../../store/actions";
import Select from "../../../../UI/Form/Select/Select";
import { getRidOfAggregation } from "../EditableMenu";

function SourceField({ visualization, column, setVisualization }) {
  const dispatch = useDispatch();
  const queryId = visualization.query.uuid;
  const clearColumn = getRidOfAggregation(column);

  useEffect(() => {
    dispatch(getQuery(queryId));
  }, [dispatch, queryId]);

  const [option, setOption] = useState(null);
  const activeQuery = useSelector((state) => state.dataSettings.activeQuery);

  const options = useMemo(() => {
    if (activeQuery) {
      return activeQuery.dataSources.map((ds) => ds.fields).flat();
    }

    return [];
  }, [activeQuery]);

  useEffect(() => {
    if (options) {
      setOption(options.find((o) => o.name === clearColumn));
    }
  }, [clearColumn, options]);

  const changeSourceField = useCallback(
    (o) => {
      setVisualization((prev) => {
        return {
          ...prev,
          overrides: prev.overrides.map((override) =>
            override.name === option.name
              ? {
                  ...override,
                  name: o.name,
                }
              : override
          ),
        };
      });
      setOption(o);
    },
    [option?.name, setVisualization]
  );

  return (
    <Select
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.name}
      value={option}
      menuHeight={130}
      onChange={changeSourceField}
      label="Column"
    />
  );
}

export default SourceField;
