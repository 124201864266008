import Switcher from "../../../../UI/Switcher/Switcher";

export default function ExportType({ setChartState, chart }) {
  const { exportType, exportFormat } = chart;

  function setExportType() {
    setChartState({
      ...chart,
      exportType: exportType ? null : "API",
      exportFormat: exportType ? null : exportFormat,
    });
  }

  function setExportFormat() {
    setChartState({
      ...chart,
      exportFormat: exportFormat ? null : "fancy-excel",
    });
  }

  return (
    <div>
      <div>Export Type Settings</div>
      <br />
      <Switcher
        rightLabel="API export type"
        checked={!!exportType}
        handleSwitchChange={setExportType}
      />
      <br />
      {exportType === "API" && (
        <Switcher
          rightLabel="Embed Image"
          checked={exportFormat === "fancy-excel"}
          handleSwitchChange={setExportFormat}
        />
      )}
      <br />
    </div>
  );
}
