import { orderBy } from "lodash-es";
import tableFunctionConvertor from "../../charts/TableView/functions/tableFunctionConvertor";
import formatter from "../formatters/formatter";

const sortHourOfDay = ({ key, sortType }, data, direction) => {
  const formatted = data.map((item) => ({
    ...item,
    formatted: formatter(item[key], sortType),
    sortedKey: parseInt(item[key]),
  }));

  const am = formatted.filter((f) => f.formatted.toLowerCase().includes("am"));
  const pm = formatted.filter((f) => f.formatted.toLowerCase().includes("pm"));

  const result = [
    ...orderBy(am, ["sortedKey"], ["asc"]),
    ...orderBy(pm, ["sortedKey"], ["asc"]),
  ];

  return direction.toLowerCase() === "asc" ? result : result.reverse();
};

const sortDates = ({ key }, data, direction) => {
  function stringToDateObject(date) {
    if (date && !isNaN(Date.parse(date))) {
      return new Date(date);
    }

    return date;
  }

  const clonnedArray = data.map((d) => ({
    ...d,
    sortedKey: stringToDateObject(d[key]),
  }));

  const sorted = clonnedArray.sort((a, b) => a.sortedKey - b.sortedKey);
  return direction.toLowerCase() === "asc" ? sorted : sorted.reverse();
};

const specialSorting = (special = {}, data, direction) => {
  if (special.sortType === "hourOfDay") {
    return sortHourOfDay(special, data, direction);
  }

  if (special.sortType === "date") {
    return sortDates(special, data, direction);
  }

  return data;
};

export const sortByKey =
  (key, direction = "ASC", regularFormatting, specialSortingTypes = []) =>
  (data) => {
    if (!data.filter((d) => d ?? d === 0).length) {
      return data;
    }

    const special = specialSortingTypes.find((sst) => sst.key === key);

    if (special) {
      return specialSorting(special, data, direction);
    }

    if (key.includes("::")) {
      const precalculatePercents = data.map((d) => ({
        ...d,
        sortedKey: getValue(d, key, regularFormatting),
      }));

      return orderBy(
        precalculatePercents,
        ["sortedKey"],
        [direction.toLowerCase()]
      );
    }

    const prepareForSortData = data.map((item) =>
      typeof item !== "string"
        ? {
            ...item,
            sortedKey: getValueForSort(item[key]),
          }
        : item
    );

    return orderBy(
      prepareForSortData,
      ["sortedKey"],
      [direction.toLowerCase()]
    );
  };

const getValueForSort = (value) => {
  if (["--", "n/a"].some((x) => x === value) || (!value && value !== 0)) {
    return -Infinity;
  }

  return isNaN(+value) ? value : +value;
};

const getValue = (d, key, regularFormatting) => {
  const { value } = tableFunctionConvertor(key, d, null, regularFormatting);
  return getValueForSort(value);
};
