import { getRidOfAggregation } from "../../../charts/TableView/Elements/EditableMenu";
import { cloneDeep, orderBy } from "lodash-es";
import { setNonExistingOverrides } from "./tableEditorHelper";

function checkMathOperations(overrides) {
  const incorrect = overrides
    .filter((o) => o.ops)
    .map((o) => o.ops)
    .some((o) => !o.alias || !((o.fields && o.fields[0].name) || o.value));

  return incorrect ? "Math operations is missing" : null;
}

function checkDates(chart) {
  if (chart.allDates) {
    return null;
  }

  if (chart.dateKey) {
    return null;
  }

  if (chart.dateKeys?.start && chart.dateKeys?.end) {
    return null;
  }

  return `Date ${chart.isParameterized ? "keys" : "key"} is wrong`;
}

function checkGroupings(staticGroupingKeys) {
  if (staticGroupingKeys?.length === 0) {
    return "Please add at least one grouping";
  }

  return null;
}

export default function tableValidation(chart, page, block) {
  return {
    ops: checkMathOperations(chart.overrides),
    dates: checkDates(chart),
    pageUuid: !page?.uuid ? "Page uuid is missing" : null,
    blockUuid: !block?.uuid ? "Block uuid is missing" : null,
    staticGroupingKeys: checkGroupings(chart.staticGroupingKeys),
  };
}

function stringToArray(str) {
  return str.includes("fn::") ? str.split("::") : str;
}

export function getRestOverrides(newSubTitles) {
  return newSubTitles
    .flat()
    .reduce((acc, curr) => {
      acc.push(stringToArray(curr));
      return acc;
    }, [])
    .flat(Number.MAX_SAFE_INTEGER)
    .map((f) => getRidOfAggregation(f));
}

// get rid of some unnecessary properties
// because we sending them separately
const needlessPropertyList = [
  "uuid",
  "displayName",
  "sortOrder",
  "name",
  "visualizationId",
  "loading",
  "error",
  "data",
  "editable",
  "addAction",
  "refreshing",
];

const removableFalsyProperties = [
  "hide",
  "limit",
  "totals",
  "totalsOnly",
  "uniqueValueKey",
  "rawRows",
  "ignoreMenuFilters",
  "showHideColumns",
  "limitPercentAmount",
  "allDates",
  "dateKeys",
  "displayRows",
  "freezeLeft",
  "freezeWidth",
  "emptyFreezeLeft",
  "freezeNextColumn",
  "staticGroupingKeys",
  "usePagination",
  "dynamicFilterValue",
  "exportFormat",
  "exportType",
];

// for drilldowns we should not remove visualizationId
// they are unique uuid, we using them as keys for drilldown data in redux
function mapPropertiesToRemovable(chart) {
  return needlessPropertyList.filter((prop) => {
    if (prop === "visualizationId") {
      return !(chart.visualizationId || "").includes("row-visualization");
    }
    return true;
  });
}

function deleteAll(chart) {
  mapPropertiesToRemovable(chart).forEach((key) => {
    delete chart[key];
  });
}

function deleteFalsies(chart) {
  removableFalsyProperties.forEach((key) => {
    const canDelete =
      chart[key] === null || chart[key] === undefined || chart[key] === false;

    if (canDelete) {
      delete chart[key];
    }
  });
}

function deleteFormatOverridesTempData(chart) {
  if (chart.formatOverrides) {
    chart.formatOverrides.forEach((formatOverride) => {
      delete formatOverride.index;
      delete formatOverride.value;
      delete formatOverride.label;
    });
  }
}

/**
 * Gets the index of the override in non-aggregated keys.
 * If the override is not found, returns the length of overrides array.
 * @param {Array} nonAggregatedKeys - Array of non-aggregated keys.
 * @param {Object} override - Override object.
 * @param {Array} overrides - Array of overrides.
 * @returns {number} - Index of the override.
 */
function getIndex(nonAggregatedKeys, override, overrides) {
  const index = nonAggregatedKeys.findIndex((key) => key === override.name);

  if (index === -1) {
    return overrides.length;
  }

  return index;
}

/**
 * Removes the 'sortIndex' property from the override object.
 * @param {Object} override - Override object.
 * @returns {Object} - Override object without 'sortIndex'.
 */
function removeSortIndexFromOverrides(override) {
  const { sortIndex, ...rest } = override;
  return rest;
}

/**
 * Sorts overrides in the chart based on the order of subTitles.
 * @param {Object} chart - Chart object containing 'overrides' and 'subTitles'.
 */
function sortOverridesBySubTitlesOrder(chart) {
  const { overrides, subTitles } = chart;

  // Flatten subTitles array
  const keys = (subTitles ?? []).flat();

  // Check if overrides or keys are not available
  if (!overrides || !overrides.length || !keys.length) {
    return;
  }

  // Get non-aggregated keys
  const nonAggregatedKeys = keys.map((key) => getRidOfAggregation(key));

  // Add 'sortIndex' property to each override
  const indexedOverrides = overrides.map((override) => ({
    ...override,
    sortIndex: getIndex(nonAggregatedKeys, override, overrides),
  }));

  // Order overrides by 'sortIndex' and remove 'sortIndex' property
  const ordered = orderBy(indexedOverrides, "sortIndex", "asc").map(
    removeSortIndexFromOverrides
  );

  // Update chart with ordered overrides
  chart.overrides = ordered;
}

function recursiveUpdate(chart) {
  if (chart) {
    // remove properties in any case
    deleteAll(chart);

    // remove properties when they falsy
    deleteFalsies(chart);

    // remove temporary properties
    deleteFormatOverridesTempData(chart);

    // overrides order should match with columns order for correct export
    sortOverridesBySubTitlesOrder(chart);

    if (chart.filters) {
      chart.filters = chart.filters.filter((filter) => !filter.removeOnSave);
    }

    if (chart.dynamicDrilldowns) {
      const keys = Object.keys(chart.rowExpandVisualizationParams);

      keys.forEach((key) => {
        const drilldownParams = chart.rowExpandVisualizationParams[key];
        const { dynamicFilters } = drilldownParams;

        setNonExistingOverrides(dynamicFilters, chart);

        recursiveUpdate(drilldownParams);
      });
    } else if (chart.hasRowExpand) {
      const drilldownParams = chart.rowExpandVisualizationParams;
      const { dynamicFilters } = drilldownParams;
      setNonExistingOverrides(dynamicFilters, chart);
      recursiveUpdate(drilldownParams);
    }
  }
}

export function clearChartConfig(config) {
  const clonedChart = cloneDeep(config);
  recursiveUpdate(clonedChart);

  return clonedChart;
}

export function checkIsRegularColor(chart, column, isReversed, isBadDebt) {
  return (
    !(chart.coloredColumns ?? []).includes(column) &&
    !isReversed &&
    !isBadDebt &&
    !(chart.dynamicShadeBackgrounds ?? []).includes(column)
  );
}

export function checkIsColored(chart, column, isBadDebt) {
  return (
    ((chart.coloredColumns ?? []).includes(column) || isBadDebt) &&
    !column.includes("ReverseColor")
  );
}

export function checkIsSpecialColored(chart, column) {
  return chart.enumColorsSettings?.find((setting) => setting.column === column);
}
