import { currentEnv, ROLE_TENANT_OWNER } from "./constants/constants";

export function hasAccessToNavEntry(
  entryObject,
  currentUserRole,
  currentUserPermissions = []
) {
  const { requireAllPermissions } = entryObject;

  return (
    (!entryObject.role || entryObject.role.includes(currentUserRole)) &&
    (!entryObject.permissions ||
      currentUserRole === ROLE_TENANT_OWNER ||
      entryObject.permissions[requireAllPermissions ? "every" : "some"](
        (permission) => currentUserPermissions.includes(permission)
      )) &&
    (!entryObject.access || entryObject.access.includes(currentEnv))
  );
}
