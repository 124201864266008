import { unique } from "../../../utils/func";

export default function (filters) {
  const uniqueFilters = unique(filters.map((f) => f.type));
  return uniqueFilters.map((f) => {
    const firstMatch = filters.find((fil) => fil.type === f);
    return {
      name: f,
      operator: firstMatch.operator || "==",
      values: filters.filter((fil) => fil.type === f).map((fil) => fil.value),
    };
  });
}

export function convertFiltersToConfigFormat(filters) {
  return filters.reduce((acc, curr) => {
    return [
      ...acc,
      ...curr.values.map((v) => {
        const configFilter = { type: curr.name, value: v };
        if (curr.operator && curr.operator !== "==") {
          configFilter.operator = curr.operator;
        }
        return configFilter;
      }),
    ];
  }, []);
}
