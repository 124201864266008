import React from "react";
import ValueSlider from "./ValueSlider";
import { extent } from "d3-array";
import { FILTER_TYPE_SLIDER } from "../../utils/constants/constants";

export default function ValueSliderFilter(props) {
  const { onChange, filter, additionalTheme } = props;
  const { formats = {}, colors = {} } = additionalTheme ?? {};

  const [min, max] = extent(filter.values.map((v) => Number(v.value)));

  function handleChange(val) {
    onChange(
      {
        selectedValue: val,
        type: filter.type,
        name: filter.name,
        number: true,
      },
      FILTER_TYPE_SLIDER
    );
  }

  return (
    <div style={{ paddingTop: 34 }}>
      <ValueSlider
        onChange={handleChange}
        min={min}
        max={max}
        value={filter.selectedValue ?? filter.defaultValues[0]}
        format={formats[filter.uuid]}
        background={colors[filter.uuid]}
      />
    </div>
  );
}
