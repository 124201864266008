import React from "react";
import { createRoot } from "react-dom/client";
// import markerSDK from "@marker.io/browser";
import { Router } from "react-router";
import history from "./utils/history";
import { Provider } from "react-redux";
import AppBoundary from "./AppBoundary";

import * as serviceWorker from "./serviceWorker";

import { init as sentryInit } from "@sentry/browser";
import { store } from "./store";

import { registerAllModules } from "handsontable/registry";

import App from "./App";
import "./styles/fontLibrary";
import "./index.css";

registerAllModules();

if (process.env.REACT_APP_SENTRY_DSN && !window.Cypress) {
  sentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_GIT_SHA1,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

if (window.Cypress) {
  window.store = store;
}

// Promises that need to be resolved before we render React.
const promises = [];

// IO2-1649 warning: why-did-you-render causes crashes in Vite.
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_WDYR) {
  promises.push(
    new Promise(async (resolve) => {
      const whyDidYouRender = await import(
        "@welldone-software/why-did-you-render"
      );
      whyDidYouRender.default(React, {
        trackAllPureComponents: true,
        exclude: excludeForWhyDidYouRender,
      });
      resolve();
    })
  );
}

Promise.all(promises).then(() =>
  createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <Router history={history}>
        <AppBoundary>
          <App />
        </AppBoundary>
      </Router>
    </Provider>
  )
);

serviceWorker.unregister();

const excludeForWhyDidYouRender = [
  /ResizeDetector/,
  /ChartFactory/,
  /ChildWrapper/,
  /AutoSizer/,
  /Table/,
  /Grid/,
  /ScrollBlock/,
  /AnimateInOut/,
  /Droppable/,
  /Draggable/,
  /HexColorPicker/,
  /ColorSelector/,
];
