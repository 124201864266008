export const pageFormatConverter = {
  response: {
    toForm(input) {
      return {
        displayName: input.displayName,
        template: input?.template.split(".")[0] || "undefined",
        ttg: !!input.ttg,
        hasParentTab: !!input.parentName,
        parentName: input.parentName,
        isDefaultForParent: input.isDefaultForParent,
        mobile: input.mobile,
      };
    },
  },
  form: {
    toRequest(input, isNew) {
      const { hasParentTab, ...restInput } = input;

      return {
        ...restInput,
        parentName: hasParentTab ? input.parentName : "",
        template: setTemplateName(isNew ? input.displayName : input.template),
      };
    },
  },
};

export function assembleRootPages(pages) {
  const rootPages = [];
  const pagesByParentName = {};

  for (const page of pages) {
    if (!page.parent) {
      rootPages.push(page);
      continue;
    }

    if (!(page.parent in pagesByParentName)) {
      const parentPage = { displayName: page.parent, slug: page.slug };
      rootPages.push(parentPage);
      pagesByParentName[page.parent] = parentPage;

      continue;
    }

    if (page.default) {
      pagesByParentName[page.parent].slug = page.slug;
    }
  }

  return rootPages;
}

/**
 * @todo update this to work correctly (Gary)
 *
 * @param name {string}
 * @returns {string|string}
 */
function setTemplateName(name) {
  return name.trim().split(" ").join("-").concat(".json").toLowerCase() || "--";
}
