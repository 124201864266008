import * as actionTypes from "../../actions/actionTypes";
import produce from "immer";

const initialState = {
  loading: false,
  dimensions: [],
  currentDimension: {
    id: null,
    name: null,
    attributes: [],
    data: [],
    loading: false,
    nextUrl: null,
    currentPage: 0,
    lastPage: 0,
    saving: false,
    newRow: null,
  },
  errors: [],
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DIMENSION_LIST_START:
      draft.loading = true;
      draft.dimensions = [];
      draft.currentDimension = initialState.currentDimension;
      break;

    case actionTypes.GET_DIMENSION_LIST_SUCCESS:
      draft.loading = false;
      draft.dimensions = action.results.data.map((d) => ({
        id: d.uuid,
        name: d.name,
      }));
      break;

    case actionTypes.ADD_DIMENSION_SUCCESS:
      draft.dimensions = [
        ...draft.dimensions,
        { id: action.result.uuid, name: action.result.name },
      ];
      draft.currentDimension = {
        ...initialState.currentDimension,
        id: action.result.uuid,
        name: action.result.name,
      };
      break;

    case actionTypes.REMOVE_DIMENSION_SUCCESS:
      draft.dimensions = draft.dimensions.filter((d) => d.uuid !== action.id);
      break;

    case actionTypes.ADD_ATTRIBUTE_TO_DIMENSION_SUCCESS:
      const attribute = {
        uuid: action.result.uuid,
        name: action.result.name,
        type: action.result.dataType,
        dataType: action.result.dataType,
        isPrimary: false,
        sync: false,
        options:
          action.result.dataType === "Attribute"
            ? action.result.attribute.options
                .map((o) => ({
                  ...o,
                  value: o.standardizedValue ? o.standardizedValue : o.value,
                }))
                .reduce(
                  (acc, curr) =>
                    acc.find((i) => i.value === curr.value)
                      ? acc
                      : [...acc, curr],
                  []
                )
            : [],
      };
      draft.currentDimension.attributes = [
        ...draft.currentDimension.attributes,
        attribute,
      ];

      const newData = draft.currentDimension.data.map((d) => ({
        ...d,
        data: [
          ...d.data,
          {
            id: action.result.uuid,
            key: action.result.name,
            type: action.result.dataType,
            isPrimary: false,
            value: "",
          },
        ],
      }));
      draft.currentDimension.data = newData;
      break;

    case actionTypes.CREATE_NEW_ATTRIBUTE_IN_DIMENSION:
      draft.currentDimension.attributes = [
        ...draft.currentDimension.attributes,
        {
          ...action.result,
          type: "Attribute",
        },
      ];

      draft.currentDimension.data = draft.currentDimension.data.map((d) => [
        ...d,
        {
          id: action.result.uuid,
          key: action.result.name,
          type: action.result.dataType,
          isPrimary: false,
          value: "",
        },
      ]);
      break;

    case actionTypes.SET_PRIMARY_KEY_ATTRIBUTE_TO_DIMENSION_SUCCESS:
      draft.currentDimension.attributes = draft.currentDimension.attributes.map(
        (d) =>
          d.uuid === action.payload.uuid
            ? {
                ...d,
                isPrimary: action.payload.isPrimary,
                sync: action.payload.sync,
              }
            : { ...d, isPrimary: false }
      );
      break;

    case actionTypes.REMOVE_ATTRIBUTE_TO_DIMENSION_SUCCESS:
      draft.currentDimension.attributes =
        draft.currentDimension.attributes.filter((a) => a.uuid !== action.uuid);
      draft.currentDimension.data = draft.currentDimension.data.map((d) => ({
        ...d,
        data: d.data.filter((i) => i.id !== action.uuid),
      }));
      break;

    case actionTypes.LOAD_DIMENSION_START:
      draft.currentDimension = initialState.currentDimension;
      break;

    case actionTypes.LOAD_DIMENSION_SUCCESS:
      const attributes = action.result.attributes;

      draft.currentDimension = {
        ...draft.currentDimension,
        id: action.result.uuid,
        name: action.result.name,
        description: action.result.description,
        attributes: attributes.map((a) => ({
          ...a,
          type: a.dataType,
          options: a.dataType === "Attribute" ? a.attribute.options : [],
        })),
      };
      break;

    case actionTypes.GET_DIMENSION_DATA_START:
      draft.currentDimension = {
        ...draft.currentDimension,
        loading: true,
        nextUrl: null,
      };
      break;

    case actionTypes.GET_DIMENSION_DATA_SUCCESS:
      draft.currentDimension = {
        ...draft.currentDimension,
        data: draft.currentDimension.data
          ? [...draft.currentDimension.data, ...action.dimData]
          : action.dimData,
        loading: !!action.nextUrl,
        nextUrl: action.nextUrl,
        currentPage: action.currentPage,
        totalPages: action.totalPages,
      };
      break;

    case actionTypes.GET_DIMENSION_DATA_FAIL:
      draft.currentDimension = {
        ...draft.currentDimension,
        loading: false,
        nextUrl: null,
      };
      break;

    case actionTypes.ADD_DIMENSION_DATA_START:
      draft.currentDimension.newRow = null;
      break;

    case actionTypes.ADD_DIMENSION_DATA_SUCCESS:
      draft.currentDimension.newRow = {
        ...action.result,
        data: action.result.data.map((d) => ({ ...d })),
      };
      // draft.currentDimension.data = [...draft.currentDimension.data, action.result];
      break;

    case actionTypes.ADD_DIMENSION_DATA_FAIL:
      draft.currentDimension.newRow = null;
      // draft.currentDimension.data = action.result ? action.result : null;
      break;

    case actionTypes.UPDATE_DIMENSION_DATA_SUCCESS:
      draft.currentDimension.data = draft.currentDimension.data.map((d) =>
        d.uuid === action.dataId ? action.data : d
      );
      break;

    case actionTypes.DELETE_DIMENSION_DATA_SUCCESS:
      draft.currentDimension.data = draft.currentDimension.data.filter(
        (r) => r.uuid !== action.dataId
      );
      break;

    case actionTypes.SAVE_DIMENSION_DATA_START:
      draft.currentDimension.saving = true;
      break;

    case actionTypes.SAVE_DIMENSION_DATA_FAIL:
    case actionTypes.SAVE_DIMENSION_DATA_SUCCESS:
      draft.currentDimension.saving = false;
      break;

    default:
      return draft;
  }
});
