import { captureException, captureMessage } from "@sentry/browser";
import axios from "axios";
import { showToastWithTimeout } from "../store/actions/message";
import { addServerErrors } from "./form";

const genericErrorMessage = "An error has occurred.";

export const VALIDATION_FAILURE = "validation_failure";
export const FILE_TOO_LARGE = "file_too_large";
export const NOT_FOUND = "not_found";
export const REQUEST_CANCELLED = "request_cancelled";

/**
 * Normalizes (ajax) errors to a normalized format that's also serializable by Redux.
 */
export function normalizeError(error) {
  if (error && error.normalizedError) {
    // Already normalized.
    return error;
  }

  const ret = { message: genericErrorMessage, normalizedError: true };
  let response, status, data;
  if (axios.isCancel(error)) {
    ret.type = REQUEST_CANCELLED;
    ret.message = error.message;
    return ret;
  }
  // No response or response status.
  if (
    typeof error !== "object" ||
    !(response = error.response) ||
    !(status = response.status)
  ) {
    ret.message = "Something went wrong, please update your page...";
    return ret;
  }

  if (status === 413) {
    ret.type = FILE_TOO_LARGE;
    ret.message = "The uploaded file was too large.";
  }

  if (!(data = response.data)) {
    return ret;
  }

  if (data.message) {
    ret.message = data.message;
  }

  if (status === 400) {
    const { message, code } = error.response.data;
    return {
      normalizedError: true,
      message,
      code,
    };
  }

  if (status === 404) {
    ret.type = NOT_FOUND;
  }

  if (status === 422 && data.errors) {
    return {
      normalizedError: true,
      message: ret.message,
      type: VALIDATION_FAILURE,
      data: data.errors,
    };
  }

  return ret;
}

export function reportError(error) {
  if (process.env.NODE_ENV === "development") {
    //eslint-disable-next-line no-console
    console.error(error);
  }
  captureException(error);
}

export function reportDeprecated(message) {
  const fullMessage = `Deprecation notice: ${message}`;
  if (process.env.NODE_ENV === "development") {
    //eslint-disable-next-line no-console
    console.warn(fullMessage);
  }
  captureMessage(fullMessage);
}

/**
 * Handles an error and then returns it. This function should be used with most new ajax requests.
 *
 * An error message is displayed, is logged to Sentry (unless it's a common, expected error),
 * and validation errors are set if the react-hook-form `setError` function is provided.
 *
 * @param dispatch The redux dispatch function.
 * @param error The (axios) error.
 * @param options
 * @param options.customHandler A custom handler of the error. If provided and it returns TRUE, then
 *  the default error handling does not happen.
 * @param options.setError An optional `setError()` function provided by the useForm hook to
 *  set validation errors automatically.
 * @param options.showToast True by default.
 * @param options.toastMessagePrefix {string=}
 * @param options.reportAllErrors If TRUE, even 404 and 422 errors get reported.
 */
export function handleError(dispatch, error, options = {}) {
  const { customHandler, setError, toastMessagePrefix } = options;
  const normalizedError = normalizeError(error);

  if (customHandler) {
    if (customHandler(normalizedError, error)) {
      return normalizedError;
    }
  }

  // Never handle these errors.
  if (normalizedError.type === REQUEST_CANCELLED) {
    return normalizedError;
  }

  const message =
    normalizedError.type === VALIDATION_FAILURE && !setError
      ? // Validation failure with no validation error handling callback?
        // Then include the validation errors in the toast message.
        [
          normalizedError.message,
          ...Object.values(normalizedError.data).flat(),
        ].join("\n")
      : normalizedError.message;

  if (options.showToast ?? true) {
    const toastMessage = `${
      toastMessagePrefix ? `${toastMessagePrefix.trim()}: ` : ""
    }${message}`;
    showToastWithTimeout(dispatch, toastMessage, "danger");
  }
  if (normalizedError.type === VALIDATION_FAILURE && setError) {
    addServerErrors(normalizedError.data, setError);
    if (!options.reportAllErrors) {
      // Consider the validation error as handled and do not report it.
      return normalizedError;
    }
  }
  if (!options.reportAllErrors && normalizedError.type === NOT_FOUND) {
    // Don't report these kinds of errors.
    return normalizedError;
  }
  reportError(error);

  return normalizedError;
}
