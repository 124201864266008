import { rightAligns } from "../../utils/constants/constants";
import formatter from "../../utils/formatters/formatter";
import tableFunctionConvertor from "./functions/tableFunctionConvertor";
import renderAsImage from "./RenderAsImage/renderAsImage";
import { matchOne } from "../../utils/func";
import { WRITE_BACKS_TYPES, getRowIoUuid } from "./WriteBacksContext";
import { useState } from "react";
import format from "date-fns/format";
import { absoluteUSDate } from "../../utils/dates/dateFunc";
import { isDate } from "../../utils/formatters/dateFormatter";
import tableOpenMathFunctionConvertor from "./functions/tableOpenMathFunctionConvertor";

export default class CellValueObject {
  constructor(
    writeBacks,
    dataRow,
    subKey,
    format,
    precision,
    performRounding,
    totalsConfig,
    dynamicCellFormat,
    hasOverrideFormat,
    progressBarsSettings,
    groupingKeyMapping,
    periodCalculationSettings
  ) {
    this.writeBacks = writeBacks;
    this.dataRow = dataRow;
    this.subKey = subKey;
    this.format = format;
    this.precision = precision;
    this.performRounding = performRounding;
    this.totalsConfig = totalsConfig;
    this.dynamicCellFormat = dynamicCellFormat;
    this.hasOverrideFormat = hasOverrideFormat;
    this.progressBarsSettings = progressBarsSettings ?? {};
    this.groupingKeyMapping = groupingKeyMapping;
    this.periodCalculationSettings = periodCalculationSettings;
  }

  enableFunctionConversion(calculateNA, regularFormatting) {
    this.functionConvertor = true;
    this.calculateNA = calculateNA;
    this.regularFormatting = regularFormatting;
  }

  enableOpenMathFormulaConversion() {
    this.openMathFormulaConvertor = true;
  }

  setRenderAsImage() {
    this.renderAsImage = true;
  }

  useDynamicColor(coloredColumns) {
    const self = this;
    if (coloredColumns) {
      return checkReverse() ? "reverse" : coloredColumns.includes(self.subKey);
    }

    const reverse = self.subKey.includes("fn::") ? checkReverse() : null;
    function checkReverse() {
      const bits = self.subKey.split("::");
      const checkReverse = bits[bits.length - 2];
      return checkReverse === "ReverseColor";
    }
    return reverse ? "reverse" : this.dynamicColor || this.functionConvertor;
  }

  getValue() {
    const self = this;
    const draftValue =
      this.writeBacks.writtenBacks[getRowIoUuid(this.dataRow)]?.[this.subKey] ??
      this.dataRow[this.subKey];

    if (this.renderAsImage) {
      return renderAsImage(draftValue, this.subKey);
    }

    if (this.openMathFormulaConvertor) {
      return getOpenMathFuncctionValue();
    }

    return this.functionConvertor ? getFunctionValue() : getStandardValue();

    function getOpenMathFuncctionValue() {
      const { subKey, dataRow, hasOverrideFormat, format, precision } = self;
      const openMath = tableOpenMathFunctionConvertor(subKey, dataRow);

      return {
        ...openMath,
        formatted: hasOverrideFormat
          ? formatter(openMath.value, format, precision)
          : openMath.formatted,
      };
    }

    function getFunctionValue() {
      const { subKey, dataRow, calculateNA, totalsConfig } = self;
      const functional = tableFunctionConvertor(
        subKey,
        dataRow,
        calculateNA,
        totalsConfig,
        null,
        self.periodCalculationSettings
      );
      return {
        ...functional,
        formatted: self.hasOverrideFormat
          ? formatter(functional.value, self.format, self.precision)
          : functional.formatted,
      };
    }

    function setDynamicColumnFormat() {
      const { dynamicCellFormat, subKey, dataRow } = self;
      const dynamic = dataRow[dynamicCellFormat[subKey]];

      if (dynamic) {
        const format = dynamic.toLowerCase();
        return format;
        // .replace(/^percent-whole$/g, "percent-round")
        // .replace(/^percent$/g, "percent-round")
        // .replace("currency", "currency-whole")
      }

      return self.format;
    }

    function getHiddenProgressBar() {
      const { progressBarsSettings, dataRow } = self;
      const { columnKey, hiddeOnTotals } = progressBarsSettings;

      if (!hiddeOnTotals || !dataRow) {
        return false;
      }

      return hiddeOnTotals && dataRow[columnKey] === "Total";
    }

    function getStandardValue() {
      const format = self.dynamicCellFormat
        ? setDynamicColumnFormat()
        : self.format;
      const isD3Format = format && format.includes(".");

      return {
        value: draftValue,
        formatted: self.formatWithWriteBacks(
          (value) =>
            self.writeBacks.writeBackField(
              getRowIoUuid(self.dataRow),
              self.subKey,
              value
            ),
          draftValue,
          format,
          self.precision,
          null,
          self.performRounding ? "M" : null
        ),
        align:
          rightAligns.find((f) => f === format) || isD3Format
            ? "right"
            : self.writeBacks.type === WRITE_BACKS_TYPES.inline &&
              format === "boolean"
            ? "center"
            : "left",
        isProgressBarHidden: getHiddenProgressBar(),
      };
    }
  }

  setShadeConfigurations(
    hasNoShadedBackground,
    staticGroupingKeys,
    headers,
    zebraShadeFromLastIndex,
    subTitles,
    groupingKey,
    hasNumeration,
    offsetZebra
  ) {
    this.hasNoShadedBackground = hasNoShadedBackground;
    this.staticGroupingKeys = staticGroupingKeys;
    this.headers = headers;
    this.zebraShadeFromLastIndex = zebraShadeFromLastIndex;
    this.subTitles = subTitles;
    this.groupingKey = groupingKey;
    this.hasNumeration = hasNumeration;
    this.offsetZebra = offsetZebra;
  }

  shade(dataRowIndex, oneLineSubtitles) {
    const {
      hasNoShadedBackground,
      staticGroupingKeys,
      headers,
      subTitles,
      dataRow,
      groupingKey,
      zebraShadeFromLastIndex,
      subKey,
      groupingKeyMapping,
    } = this;

    if (hasNoShadedBackground) {
      return 0;
    }

    let position = 0;

    if (staticGroupingKeys || !headers) {
      position = zebraShadeFromLastIndex
        ? subTitles.lastIndexOf(matchOne(subKey))
        : subTitles.findIndex(matchOne(subKey));
    } else {
      position = headers.findIndex((header, index) => {
        const originalHeader = groupingKeyMapping
          ? getOriginalNonConvertedHeader(header, groupingKeyMapping)
          : header;
        // for duplicate headers we should compare positions also
        return (
          index > dataRowIndex &&
          (dataRow[groupingKey] || "").includes(originalHeader)
        );
      });
    }

    if (this.hasNumeration) {
      return position % 2 === 0 && position !== 0;
    }

    if (oneLineSubtitles) {
      position = dataRowIndex;
    }

    return (position + (this.offsetZebra ? 1 : 0)) % 2;
  }

  formatWithWriteBacks(updateField, draftValue, format, ...restArgs) {
    const formattedValue = formatter(draftValue, format, ...restArgs);

    if (this.writeBacks.type !== WRITE_BACKS_TYPES.inline) {
      return formattedValue;
    }

    return (
      <WriteBackInline
        format={format}
        rawValue={draftValue}
        value={formattedValue}
        uuid={getRowIoUuid(this.dataRow)}
        writeBacks={this.writeBacks}
        cellKey={this.subKey}
      />
    );
  }
}

function WriteBackInline({
  format,
  value,
  rawValue,
  writeBacks,
  uuid,
  cellKey,
}) {
  const [savingWriteBack, setSavingWriteBack] = useState(false);

  function updateField(value) {
    return writeBacks.writeBackField(uuid, cellKey, value);
  }

  if (format === "boolean") {
    const checkedValue = isTrue(rawValue);
    return (
      <input
        type="checkbox"
        checked={checkedValue}
        disabled={savingWriteBack}
        onChange={async () => {
          if (savingWriteBack) {
            return;
          }
          try {
            setSavingWriteBack(true);
            await updateField(!checkedValue ? 1 : 0);
          } finally {
            setSavingWriteBack(false);
          }
        }}
      />
    );
  }

  return value;
}

function isTrue(value) {
  return value && value !== "0";
}

function getOriginalNonConvertedHeader(header, groupingKeyMapping) {
  const clearedKey = (header || "").replace(/[A-z]/g, "");
  if (groupingKeyMapping === "[MONTH_RANGE]" && isDate(clearedKey)) {
    return format(absoluteUSDate(clearedKey), "yyyy-MM-dd");
  }

  if (groupingKeyMapping === "[MONTH_YEAR]" && isDate(clearedKey)) {
    const stringToDateObject = new Date(header);
    return format(stringToDateObject, "yyyy-MM-dd");
  }

  if (groupingKeyMapping === "[MONTHLY]" && isDate(header)) {
    const stringToDateObject = new Date(header);
    const year = stringToDateObject.getFullYear();
    const month = ("0" + (stringToDateObject.getMonth() + 1)).slice(-2);

    return `${year} M${month}`;
  }

  return header;
}
