import styled from "@emotion/styled";
import Select from "../../../../../UI/Form/Select/Select";
import Input from "../../../../../UI/Form/Input/Input";
import Switcher from "../../../../../UI/Switcher/Switcher";
import { useState } from "react";
import useDebounceChange from "../../../../../hooks/useDebounceChange";

const Example = styled.div(
  ({ theme }) => `
  display: flex;
  color: ${theme.notification.warningMain};
`
);

const PinRowContainer = styled.div(
  ({ theme }) => `
  padding: 7px;
  border: 1px solid ${theme.divider};
  border-radius: 5px;
  margin-bottom: 20px;
`
);

export default function PinnedRowItem(props) {
  const [value, setValue] = useState("");
  const { pinnedRow, overrides, onPinItemChange, index } = props;

  const { debounce } = useDebounceChange();

  function onValueChange(e) {
    const val = e.target.value;
    setValue(val);
    debounce(() => onPinItemChange(val, index, "value"), 1000);
  }

  return (
    <PinRowContainer key={pinnedRow.value}>
      <Select
        options={overrides}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        label="Column"
        value={overrides.find((override) => override.name === pinnedRow.column)}
        onChange={(o) => onPinItemChange(o.name, index, "column")}
      />
      <br />
      <Input
        value={value || pinnedRow?.value || ""}
        label="Value"
        onChange={onValueChange}
      />
      <br />
      {!index && <Example>Example: GeneralManager = "Corporate"</Example>}
      <br />
      <Switcher
        rightLabel="Top"
        leftLabel="Position: Bottom"
        checked={pinnedRow.position === "top"}
        handleSwitchChange={() =>
          onPinItemChange(
            pinnedRow.position === "top" ? "bottom" : "top",
            index,
            "position"
          )
        }
      />
      <br />
    </PinRowContainer>
  );
}
