import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { normalizeResponse } from "../../store/actions/dashboard/dashboard";
import { useTheme } from "emotion-theming";
import { executeCharts } from "../../store/actions/monitoring";
import { buildDrilldownInnerChart } from "./DrilldownItem";
import IOButton from "../../UI/Form/Button/IOButton";
import SummaryByCharts from "./SummaryByCharts";
import ButtonGroup from "../../UI/ButtonGroup/ButtonGroup";
import SummaryByPages from "./SummaryByPages";
import Flex from "../../UI/Flex/Flex";
import Details from "./Details";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const Title = styled.span`
  font-size: 16px;
  margin: 10px;
`;

const WarningMeassage = styled.div`
  color: ${(props) => props.theme.notification.warningMain};
  margin: 10px;
`;

const execStatuses = ["FAST", "OK", "SLOW", "FAILED"];

export default function Monitoring() {
  const pages = useSelector((state) => state.pageManagement.pages);
  const {
    charts: executedCharts,
    summaryLoading,
    drilldownLoading,
  } = useSelector((state) => state.monitoring);

  const theme = useTheme();
  const dispatch = useDispatch();

  // filter by charts response statuses
  const [filters, setFilters] = useState(execStatuses);

  const mapSettingsToConfigurations = useMemo(
    () =>
      pages.map((page) => ({
        ...page,
        blocks: normalizeResponse({ data: { blocks: page.blocks } }).blocks,
      })),
    [pages]
  );

  // exclude pages that should not send requests
  const [filteredPages, setFilteredPages] = useState(
    mapSettingsToConfigurations.map((page) => page.uuid)
  );

  const visualizations = useMemo(
    () =>
      mapSettingsToConfigurations
        .filter((page) => filteredPages.includes(page.uuid))
        .map((page) => page.blocks.map((block) => block.charts))
        .flat(2),
    [filteredPages, mapSettingsToConfigurations]
  );

  const runQueries = (visualizations, type) => {
    dispatch(executeCharts(visualizations, type));
  };

  const getLoadedConfirmation = (charts) => {
    if (!charts.length) {
      return false;
    }

    return charts.every((vis) => {
      return executedCharts[vis.visualizationId];
    });
  };

  const getLoadingStartConfirmation = (charts) => {
    return charts.some((vis) => {
      return executedCharts[vis.visualizationId];
    });
  };

  const scrollToDetails = () => {
    document
      .getElementById("details")
      .scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const onFilterChange = (name) => {
    if (Array.isArray(name)) {
      // if filter selected from Table
      setFilters(name);
    } else {
      // if filter selected from checkbox
      const newFilters = filters.includes(name)
        ? filters.filter((filter) => filter !== name)
        : [...filters, name];
      setFilters(newFilters);
    }

    scrollToDetails();
  };

  const allLoaded = getLoadedConfirmation(visualizations);
  const availableDrilldowns = useMemo(
    () =>
      buildDrilldownInnerChart({ visualizations, allLoaded, executedCharts }),
    [visualizations, allLoaded, executedCharts]
  );

  const drilldownsLoaded = getLoadedConfirmation(availableDrilldowns);
  const isStarted = getLoadingStartConfirmation(availableDrilldowns);
  const showWarning = !drilldownsLoaded && isStarted && !drilldownLoading;
  const queriesRequired = visualizations.length === 0;

  return (
    <Container>
      <Title>Summary</Title>

      <Flex justifyContent="space-between">
        <div style={{ width: "40%" }}>
          <SummaryByCharts
            executedCharts={executedCharts}
            visualizations={visualizations}
            theme={theme}
            onFilterChange={onFilterChange}
          />

          {queriesRequired && (
            <WarningMeassage>
              Please select at least one page to run queries.
            </WarningMeassage>
          )}
          <ButtonGroup position="flex-end">
            <IOButton
              onClick={() => runQueries(visualizations, "summaryLoading")}
              processing={summaryLoading}
              disabled={summaryLoading || queriesRequired}
            >
              {summaryLoading ? "Runing" : "Run"}
            </IOButton>
          </ButtonGroup>

          <SummaryByCharts
            executedCharts={executedCharts}
            visualizations={availableDrilldowns}
            theme={theme}
            label="Drildowns"
            onFilterChange={onFilterChange}
          />

          <ButtonGroup position="flex-end">
            <IOButton
              onClick={() =>
                runQueries(availableDrilldowns, "drilldownLoading")
              }
              processing={drilldownLoading}
              disabled={drilldownLoading || !allLoaded}
            >
              {drilldownLoading ? "Runing" : "Run Drilldowns"}
            </IOButton>
          </ButtonGroup>

          {showWarning && (
            <WarningMeassage>
              Nested drilldowns are available now, please click "Run Drilldowns"
              one more time.
            </WarningMeassage>
          )}
        </div>

        <SummaryByPages
          mapSettingsToConfigurations={mapSettingsToConfigurations}
          executedCharts={executedCharts}
          visualizationQuantity={visualizations.length}
          setFilteredPages={setFilteredPages}
          filteredPages={filteredPages}
        />
      </Flex>

      <Details
        mapSettingsToConfigurations={mapSettingsToConfigurations}
        executedCharts={executedCharts}
        theme={theme}
        Title={Title}
        filters={filters}
        execStatuses={execStatuses}
        onFilterChange={onFilterChange}
      />
    </Container>
  );
}
