import styled from "@emotion/styled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useShallowEqualSelector } from "../../store";
import { isEqual } from "lodash-es";
import { showToast } from "../../store/actions/message";
import IOButton from "../../UI/Form/Button/IOButton";
import Loading from "../../UI/Loading/Loading";
import Layout from "./Layout/Layout";
import NavigationMenu from "./Navigation/NavigationMenu";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import { getRidOfAggregation } from "../../charts/TableView/Elements/EditableMenu";

import {
  createVisualization,
  executeQuery,
  getPages,
  getQuery,
  loadDashboardConfiguration,
  setActiveTab,
  listQueries,
} from "../../store/actions";

import tableValidation, {
  clearChartConfig,
  getRestOverrides,
} from "./utils/tableValidation";

import {
  getNestedDrilldownConfig,
  inUse,
  submitButtonStyles,
  updateNestedDrilldownConfig,
} from "./utils/tableEditorHelper";
import { setSelectedComparisonModeFilterName } from "../../store/actions/layout";
import { clearPowerEditorPreview } from "../../store/actions/dashboard/dashboard";
// import { loadVisualizationPreview } from "../../store/actions/charts";

const Container = styled.div(
  ({ theme }) => `
  display: flex;
  background: ${theme.background.primary};
  color: ${theme.text.secondary};
  height: 100vh;
  width: 100%;
`
);

export default function TableEditor(props) {
  const [navigationState, setNavigationState] = useState("Layout");
  const [hideMenu, setHideMenu] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [levelIndicator, setlevelIndicator] = useState(0);
  const [editLevel, setEditLevel] = useState(0);
  const [levelData, setLevelData] = useState({});

  // level => drilldown key object; {1: "Product Name"}
  const [drilldownParent, setDrilldownParent] = useState({});

  const { visualizationUuid } = props.match.params;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listQueries());
  }, [dispatch]);

  const queries = useSelector((state) => state.dataSettings.queries);

  const { pages, blocks, filters, query, previewChart } =
    useShallowEqualSelector((state) => ({
      pages: state.pageManagement.pages,
      blocks: state.dashboard.blocks,
      filters: state.layout.menuFilters,
      query: state.dataSettings.activeQuery,
      previewChart: state.dashboard.tableEditorPreviewChart,
    }));

  const queriesReady = !!queries[0];

  useEffect(() => {
    if (!pages.length) {
      dispatch(getPages());
    }
  }, [dispatch, pages.length]);

  const page = pages.find((page) =>
    page.blocks.find((block) =>
      block.visualizations.find((vis) => vis.uuid === visualizationUuid)
    )
  );

  // this need to load blocks and visualizations from page & set active tab to this page to make default filters work
  // ex. we have TTM on ccg which have month year default filter
  useEffect(() => {
    if (page) {
      dispatch(setActiveTab(page));
      dispatch(loadDashboardConfiguration(page.slug, null, true));
    }

    // clear comparisons when power editor unmounted
    return () => {
      dispatch(setSelectedComparisonModeFilterName(null));
      dispatch(setActiveTab(page));
      dispatch(clearPowerEditorPreview());
    };
  }, [dispatch, page]);

  const block = useMemo(
    () =>
      blocks.find((block) =>
        block.charts.find((chart) => chart.uuid === visualizationUuid)
      ),
    [blocks, visualizationUuid]
  );

  const chart = useMemo(
    () => block?.charts.find((chart) => chart.uuid === visualizationUuid),
    [block?.charts, visualizationUuid]
  );

  const [chartState, setChartState] = useState(chart);

  const drilldownSetChartStateOverride = useCallback(
    (innerChart, levelChange) => {
      if (levelChange) {
        return setChartState(innerChart);
      }

      const res = updateNestedDrilldownConfig(
        chartState,
        innerChart,
        levelIndicator,
        drilldownParent
      );
      setChartState(res);
    },
    [chartState, drilldownParent, levelIndicator]
  );

  const currentChart = useMemo(() => {
    return (
      getNestedDrilldownConfig(
        chartState || chart,
        levelIndicator,
        drilldownParent
      ) ?? {}
    );
  }, [chart, chartState, drilldownParent, levelIndicator]);

  const currentImmutableChart = useMemo(() => {
    return getNestedDrilldownConfig(chart, levelIndicator, drilldownParent);
  }, [chart, drilldownParent, levelIndicator]);

  const queryId = currentChart?.queryId;
  useEffect(() => {
    if (queryId) {
      dispatch(getQuery(queryId));
    }
  }, [queryId, dispatch]);

  const showPreview = useCallback(
    (chart, forPreview) => {
      dispatch(executeQuery(chart, [], null, undefined, null, forPreview));
    },
    [dispatch]
  );

  useEffect(() => {
    if (chart && !chartState) {
      setChartState(chart);
    }
  }, [chart, chartState]);

  useEffect(() => {
    if (chart && !previewChart && queriesReady) {
      showPreview(chart, true);
    }
  }, [chart, previewChart, queriesReady, showPreview]);

  const fields = useMemo(() => {
    if (!query) {
      return [];
    }

    if (query.type === "parameterized") {
      return query.parameterizedFields ?? [];
    }

    return query.dataSources.reduce((acc, curr) => {
      curr.fields.forEach((field) => {
        const { name, mapping } = field;
        acc.push({ name, ...(mapping && { mapping }) });
      });

      return acc;
    }, []);
  }, [query]);

  const removeAnyColumnType = useCallback(
    (innerChart, field, subTitles, clearFromRowGroup) => {
      const singleSubtitleArray = getRestOverrides(subTitles);

      // for regular columns
      const isInUse = singleSubtitleArray.some((s) => s === field);
      // in use in rowGroupKey
      const isInRowGroupKey =
        field === innerChart.rowGroupKey && !clearFromRowGroup;

      // if you remove column, remove override as well
      // first we need to check that we do not using this column in other places
      if (isInUse || isInRowGroupKey) {
        return innerChart.overrides;
      }

      // in use for formula column
      if (field.includes("fn::")) {
        const columns = field.split("::");
        return inUse(columns, singleSubtitleArray, innerChart.overrides);
      }

      return innerChart.overrides.filter(
        (o) => o.name !== getRidOfAggregation(field)
      );
    },
    []
  );

  const removeColumn = useCallback(
    (innerChart, position, index) => {
      const subTitle = [...innerChart.subTitles[position]].filter(
        (_, i) => i !== index
      );

      const field = [...innerChart.subTitles[position]][index];
      const newSubTitles = innerChart.subTitles
        .map((sub, i) => (i === position ? subTitle : sub))
        .filter((sub) => {
          // if you removed last column but grouping is exist we need
          // to keep empty subTitles array
          if (innerChart.staticGroupingKeys) {
            return (
              innerChart.subTitles.length <=
              innerChart.staticGroupingKeys.length
            );
          }
          return sub.length;
        });

      const newOverrides = removeAnyColumnType(innerChart, field, newSubTitles);

      drilldownSetChartStateOverride({
        ...innerChart,
        subTitles: newSubTitles,
        overrides: newOverrides,
      });

      if (selectedColumn) {
        setSelectedColumn(null);
        setNavigationState("Layout");
      }
    },
    [drilldownSetChartStateOverride, removeAnyColumnType, selectedColumn]
  );

  const removeDynamicSubTitleColumn = useCallback(
    (column) => {
      const dynamicSubTitleKeys = currentChart.dynamicSubTitleKeys.filter(
        (key) => key !== column
      );

      const newOverrides = removeAnyColumnType(
        currentChart,
        column,
        dynamicSubTitleKeys
      );

      drilldownSetChartStateOverride({
        ...currentChart,
        dynamicSubTitleKeys: dynamicSubTitleKeys,
        overrides: newOverrides,
      });

      if (selectedColumn) {
        setSelectedColumn(null);
        setNavigationState("Layout");
      }
    },
    [
      currentChart,
      drilldownSetChartStateOverride,
      removeAnyColumnType,
      selectedColumn,
    ]
  );

  const onVisualizationSave = () => {
    const messages = tableValidation(chartState, page, block);
    const values = Object.values(messages).filter((mess) => mess);

    if (values.length) {
      return showToast("Validation", dispatch, values, "danger");
    }

    const clearedSettings = clearChartConfig(chartState);

    const constantOverrides = {
      ...clearedSettings,
      convertGetToPost: true,
    };

    dispatch(
      createVisualization(
        page?.uuid,
        block?.uuid,
        chartState.queryId,
        chartState.overrides,
        chartState.filters,
        chartState.orders,
        constantOverrides,
        visualizationUuid,
        chart.sortOrder
      )
    );
  };

  const isParameterized = query?.type === "parameterized";

  const innerProps = {
    chart: { ...currentChart, overrides: currentChart.overrides ?? [] },
    chartState,
    setChartState: drilldownSetChartStateOverride,
    selectedColumn,
    setSelectedColumn,
    removeColumn,
    removeDynamicSubTitleColumn,
    setHideMenu,
    setNavigationState,
    fields,
    hideMenu,
    navigationState,
    query,
    showPreview,
    previewChart,
    levelIndicator,
    setlevelIndicator,
    editLevel,
    setEditLevel,
    levelData,
    setLevelData,
    isFiltering: navigationState === "Filtering" || navigationState === "Rows",
    drilldownParent,
    setDrilldownParent,
    pageUuid: page?.uuid,
    blockUuid: block?.uuid,
    visualizationUuid,
    removeAnyColumnType,
    isParameterized,
  };

  return (
    <Container id="select-menu-portal">
      {!chartState || !queriesReady ? (
        <Loading />
      ) : (
        <>
          <NavigationMenu {...innerProps} />
          <SettingsMenu
            {...innerProps}
            immutableChart={currentImmutableChart}
            filters={filters}
            query={query}
          />

          <IOButton
            style={submitButtonStyles}
            success
            smallPadding
            onClick={onVisualizationSave}
            disabled={isEqual(chart, chartState)}
          >
            Save Settings
          </IOButton>

          <Layout {...innerProps} previewChart={previewChart} />
        </>
      )}
    </Container>
  );
}
