import axios from "axios";
import { getCookie, originalQueryParsed } from "./utils/browser";
import { store } from "./store";
import { showActionToast } from "./store/actions/message";
import { scheduleMessage } from "./utils/constants/constants";
import { getToken } from "./utils/getToken";
import { isRunningInTest } from "./utils/env";
import { getTenantDomainFromURL } from "./utils/tenant";

// The FE subdomain must be set to "cypress_easy_tests" or just "domain" for this to work.
const isCypressEasyTestsFrontend =
  getTenantDomainFromURL(window.location.href) === "cypress_easy_tests";

const base =
  process.env.NODE_ENV === "test"
    ? "http://test.com/"
    : isCypressEasyTestsFrontend
    ? "https://faked-domain-for-cypress-to-prevent-stray-requests.com"
    : process.env.REACT_APP_API_ENDPOINT;

export const apiUrlObject = new URL(base);

const shouldUseLocalResponseCaching =
  process.env.REACT_APP_LOCAL_API_CACHE &&
  !isRunningInTest &&
  !isCypressEasyTestsFrontend;

const axiosAdapter = shouldUseLocalResponseCaching
  ? // Code-split lazy-load the cache adapter to not include its code into the production bundle.
    async (config) =>
      (await import("./utils/axiosLocalCacheAdapter")).default.adapter(config)
  : // Just use the default axios adapter.
    undefined;

const instance = axios.create({
  baseURL: base,
  adapter: axiosAdapter,
});

instance.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }
  if (!config.headers.Authorization && getToken()) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  if (originalQueryParsed.activeGroupAlias) {
    config.headers["X-Active-Group-Alias"] =
      originalQueryParsed.activeGroupAlias;
  }
  // Forward xdebug/clockwork cookies to the API.
  const xdebugSessionValue = getCookie("XDEBUG_SESSION");
  const clockworkProfileValue = getCookie("clockwork-profile");
  if (!config.params) {
    config.params = {};
  }
  if (xdebugSessionValue) {
    config.params.XDEBUG_SESSION_START = xdebugSessionValue;
  }
  if (clockworkProfileValue != null) {
    config.params["clockwork-profile"] = clockworkProfileValue;
  }

  config.startTime = new Date();
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.status === 202) {
      const settings = response.config.data && JSON.parse(response.config.data);
      store.dispatch(
        showActionToast(scheduleMessage, response.config.url, settings)
      );
    }

    response.config.duration = new Date() - response.config?.startTime;
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    error.duration = new Date() - error.config?.startTime;

    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }

    // 401 errors.
    localStorage.clear();
    window.location = "/";
    return Promise.reject(error);
  }
);

export default instance;
