import { useState } from "react";
import produce from "immer";
import useToggleArray from "../../../../../hooks/useToggleArray";

export function useFilters(initialFilters = []) {
  const [draftFilters, setDraftFilters] = useState(initialFilters);
  const [muted, setMuted] = useToggleArray();

  const addFilter = () => {
    return setDraftFilters((filters) => [
      ...filters,
      { name: null, values: [] },
    ]);
  };

  const setField = (position, field) => {
    const nextState = produce(draftFilters, (draftState) => {
      draftState[position] = {
        name: field,
        operator: null,
        values: [],
      };
    });
    return setDraftFilters(nextState);
  };

  const setOperator = (position, operator) => {
    const nextState = produce(draftFilters, (draftState) => {
      draftState[position].operator = operator;
      if (operator === "range") {
        draftState[position].values = [null, null];
      }
    });
    return setDraftFilters(nextState);
  };

  const setValues = (position, values) => {
    const nextState = produce(draftFilters, (draftState) => {
      draftState[position].values = values;
    });
    return setDraftFilters(nextState);
  };

  const removeRow = (position) => {
    return setDraftFilters(draftFilters.filter((s, i) => i !== position));
  };

  function isMuted(index) {
    return muted.find((m) => m === index) !== undefined;
  }

  return {
    draftFilters,
    setDraftFilters,
    addFilter,
    setField,
    setOperator,
    setValues,
    removeRow,
    muted,
    setMuted,
    isMuted,
  };
}
