import { passwordMinLength } from "./constants/constants";

/**
 * @returns {true|string}
 */
export function validateJson(v) {
  try {
    const parsed = JSON.parse(v);
    return (
      (typeof parsed === "object" && !Array.isArray(parsed)) ||
      "Must be a JSON object."
    );
  } catch (e) {
    return "Must be valid JSON.";
  }
}

// At least 1 number, 1 lowercase character, one uppercase character.
const passwordCharactersRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

export function validateUserPassword(password, confirmPassword) {
  if (!password) {
    return "Please enter a password.";
  }
  if (!passwordCharactersRegex.test(password)) {
    return "Password must contain at least 1 number, 1 uppercase letter, and 1 lowercase letter.";
  }
  if (password.length < passwordMinLength) {
    return `Password must be at least ${passwordMinLength} characters long.`;
  }
  if (!confirmPassword) {
    return "Please confirm your password.";
  }
  if (password !== confirmPassword) {
    return "Password fields do not match.";
  }
  return null;
}

export const VALIDATION_MESSAGES = {
  required: "This field is required.",
};
