import Input from "../../../../UI/Form/Input/Input";
import Switcher from "../../../../UI/Switcher/Switcher";
import { FieldWrapper } from "./GlobalSettings";
import { RowStyleContainer } from "./RowStyles";

export default function ParameterizedQuery({
  chart,
  setChartState,
  theme,
  isParameterized,
}) {
  const { parameterizedFilterPrefix } = chart;

  return (
    <RowStyleContainer>
      <div>Query type</div>
      <br />
      <Switcher
        leftLabel="Regular"
        rightLabel="Parameterized"
        checked={!!isParameterized}
        handleSwitchChange={() =>
          setChartState({
            ...chart,
            isParameterized: !isParameterized,
          })
        }
      />
      <br />
      {isParameterized && (
        <FieldWrapper>
          <Input
            label="Parameterized Prefix"
            value={parameterizedFilterPrefix ?? ""}
            onChange={(e) =>
              setChartState({
                ...chart,
                parameterizedFilterPrefix: e.target.value,
              })
            }
          />
        </FieldWrapper>
      )}
      <hr color={theme.background.secondary} />
    </RowStyleContainer>
  );
}
