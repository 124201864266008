import { isEmpty } from "lodash-es";

export default {
  local: {
    toRequest(input) {
      const menuFilters = [];
      for (const [uuid, values] of Object.entries(input ?? {})) {
        menuFilters.push({ menuFilterUuid: uuid, values });
      }
      return { menuFilters };
    },
    isEmpty(input) {
      return !input || isEmpty(input);
    },
  },
  response: {
    toLocal(response) {
      const ret = {};
      for (const menuFilterSetting of response ?? []) {
        ret[menuFilterSetting.menuFilter.uuid] = menuFilterSetting.values;
      }
      return ret;
    },
    isEmpty(response) {
      return !response || !response.length;
    },
  },
};
