import {
  customFilterOptions,
  DATE_TERM_MONTH_TO_MONTH,
} from "../../../utils/constants/constants";
import { keyBy } from "lodash-es";

export function setCustomDateHandler(
  isFilterModeCustom,
  dateFiltersConfig,
  setSelectedDateType,
  pageOrientedDateTypes
) {
  let nextType = null;
  if (isFilterModeCustom && dateFiltersConfig.filterCustomTerm) {
    const options = toDateOptions(dateFiltersConfig.filterCustomValues);
    const optionsByValue = keyBy(options, "value");

    setSelectedDateType((selected) => {
      const selectedDateType = selected?.value || selected;

      const selectedDateTypeOption =
        selectedDateType && optionsByValue[selectedDateType];

      if (pageOrientedDateTypes) {
        const defaultTerm = pageOrientedDateTypes.find(
          (type) => type === dateFiltersConfig?.filterCustomTerm
        );
        const matchedPageOrientedDateType = pageOrientedDateTypes.find(
          (t) => t === selectedDateType
        );
        nextType =
          optionsByValue[matchedPageOrientedDateType || defaultTerm] ??
          optionsByValue[pageOrientedDateTypes[0]];
      } else if (selectedDateTypeOption) {
        // The currently selected date type is supported by this configuration, so keep it.
        nextType = selectedDateType;
      } else {
        nextType =
          optionsByValue[dateFiltersConfig.filterCustomTerm] ?? options[0];
      }
      return nextType;
    });
  }
}

export const toDateOptions = (
  filterCustomValues = [],
  domainFormats,
  pageOriented,
  activeTab
) => {
  function setOptionBasedOnDomain(option) {
    if (domainFormats && domainFormats[option.alias]) {
      return {
        value: option.value,
        label: domainFormats[option.alias].label ?? option.label,
      };
    }

    return { value: option.value, label: option.label };
  }

  // special feature for pages
  // now we have opportunity to set different date filters on each page
  function areInPageOrientedArray(option, pageDates) {
    return pageDates.dateTypes.find((date) => option.value === date);
  }

  const pageDates = (pageOriented ?? []).find(
    (po) => po.pageUuid === activeTab?.uuid
  );

  const orderedCustomFilterOptions = filterCustomValues.reduce((acc, curr) => {
    const option = customFilterOptions.find((option) => option.value === curr);
    if (option) {
      acc.push(option);
    }
    return acc;
  }, []);

  return orderedCustomFilterOptions
    .filter((dateOption) => filterCustomValues.includes(dateOption.value))
    .filter((dateOption) => {
      if (pageDates) {
        return areInPageOrientedArray(dateOption, pageDates);
      }

      return dateOption;
    })
    .map(setOptionBasedOnDomain)
    .filter((o) => o.value !== DATE_TERM_MONTH_TO_MONTH);
};
