import {
  getDaysOfWeek,
  getLatestCompleteTenTwoWeeks,
  getRecentMonths,
  getRecentQuarters,
  getRecentWeeks,
  getRecentYears,
  getSingleFiscalQuarters,
  getSingleQuarters,
} from "../../../utils/formatters/dateFormatter";

export default function toDateFiltersOptions(
  recentMonths,
  recentQuarters,
  dateFiltersConfig,
  quantity,
  hardcodedPinnacle // remove this when pinnacle Q2 2022 will be ready
) {
  return {
    rw: {
      options: getRecentWeeks(
        quantity,
        undefined,
        dateFiltersConfig.startOfWeek
      ),
      placeholder: "Select Week...",
      quantity: 52, // max week quantity in select
    },
    rm: {
      options: recentMonths
        ? getRecentMonths({
            valueFormat: recentMonths.valueFormat,
            labelFormat: recentMonths.labelFormat,
            isReverse: recentMonths.isReverse,
            count: recentMonths.count,
            hardcodedPinnacle,
          })
        : [],
      placeholder: "Select Month...",
    },
    rq: {
      options: recentQuarters
        ? getRecentQuarters(
            recentQuarters.valueFormat,
            recentQuarters.labelFormat,
            recentQuarters.isReverse,
            recentQuarters.count
          )
        : [],
      placeholder: "Select Quarter...",
    },
    ry: {
      options: getRecentYears(),
      placeholder: "Select Year...",
    },
    dayOfWeek: {
      options: getDaysOfWeek(),
      placeholder: "Select Day...",
    },
    singleQuarter: {
      options: getSingleQuarters(dateFiltersConfig),
      placeholder: "Select Quarter...",
    },
    singleFiscalQuarter: {
      options: getSingleFiscalQuarters(dateFiltersConfig, hardcodedPinnacle), // remove this when pinnacle Q2 2022 will be ready
      placeholder: "Select Quarter...",
    },

    biweeklySchedule: {
      options: getLatestCompleteTenTwoWeeks(dateFiltersConfig.approved),
      placeholder: "Select Two Weeks Period...",
    },
  };
}
