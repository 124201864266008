import { orderBy } from "lodash-es";

export default function (lineGroups, yearOverYear) {
  return lineGroups.reduce(
    (acc, curr) => {
      return {
        ...acc,
        dates: dates(acc, curr, yearOverYear),
        values: [...acc.values, ...curr.values.map((v) => v.value)],
        rightAxisValues: [
          acc.rightAxisValues,
          ...(curr.rightAxisValues || []).map((v) => v.value),
        ],
      };
    },
    { dates: [], values: [], rightAxisValues: [] }
  );
}

function dates(acc, curr, yearOverYear) {
  return [
    ...acc.dates,
    ...curr.values.map((v) => v[yearOverYear ? "scaleDate" : "xValue"]),
  ];
}

export function months(xKeyDomain) {
  return (
    xKeyDomain && [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
  );
}

export function getSortedXAxisValues(
  xSortSettings,
  xAxisItems,
  initialData,
  xKey
) {
  if (xSortSettings) {
    const { key, direction } = xSortSettings;

    const xKeys = xAxisItems.map((xAxisItem) => ({
      [xKey]: xAxisItem,
      [key]: getOrderNumber(initialData, xAxisItem, key, xKey),
    }));

    return orderBy(xKeys, key, direction).map((item) => item[xKey]);
  }

  return xAxisItems;
}

function getOrderNumber(initialData, xAxisItem, key, xKey) {
  return initialData.find(
    (initialDataItem) => initialDataItem[xKey] === xAxisItem
  )[key];
}
