import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export default function Portal({ children, isDataExplorer }) {
  const [container] = useState(() => document.createElement("div"));
  useEffect(() => {
    if (!isDataExplorer) {
      container.style.position = "relative";
      container.style.zIndex = "2000";
    }
  }, [container.style, isDataExplorer]);

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(children, container);
}
