import React, { useState, useEffect } from "react";
import { useTheme } from "emotion-theming";
import Select from "react-select";
import { useHistory } from "react-router";
import { customStyles } from "../../styles/customSelectStyles";

export default function ResponsiveTabs({ options, baseUrl }) {
  const theme = useTheme();
  const history = useHistory();

  useEffect(() => {
    const route = history.location.pathname.split("/");
    const item = route[route.length - 1];

    setValue(options.find((option) => item === option.slug));
  }, [history.location.pathname, options]);

  const [value, setValue] = useState();
  const styles = customStyles(theme);

  const handleChange = (value) => {
    history.push(value.link || "/" + baseUrl + "/" + value.slug);
    setValue(value);
  };

  return (
    <Select
      options={options}
      styles={styles}
      value={value}
      getOptionLabel={(option) => option.displayName}
      onChange={(value) => handleChange(value)}
      getOptionValue={(option) => option.uuid}
    />
  );
}
