import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExplorerLeftTray from "../DataExplorerUI/ExplorerLeftTray";
import { flexCenter, scrollbarConfig } from "../../../../styles/styledSnippets";
import Flex from "../../../../UI/Flex/Flex";
import SimpleTooltip from "../../../../UI/SimpleTooltip/SimpleTooltip";

const Column = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 12px;

  svg {
    opacity: 0.8;
    margin-right: 12px;
    font-size: 18px;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const Instructions = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
  ${(props) =>
    props.hr
      ? `
          border-top: 1px solid ${props.theme.gray.gray800};
          padding-top: 24px;
          `
      : ""}
`;

const ListContainer = styled.div`
  height: calc(100vh - 250px);
  overflow: auto;
  ${scrollbarConfig({ size: ".4em" })};
`;

const Blank = styled.div`
  cursor: pointer;
  margin-bottom: 18px;
  color: ${(props) => props.theme.notification.infoMain};
  ${flexCenter}

  svg {
    font-size: 36px;
    margin-right: 8px;
  }
`;

export default function ColumnSelection(props) {
  const { columns, close, filterApi, clearAll, reset } = props;

  const visibleColumns = filterApi?.overrides.map((o) => o.name) || [];

  const handleChange = (col) => {
    const position = overridePosition();

    if (position === -1) {
      filterApi.addOverride(col.name);
    } else {
      filterApi.removeOverride(position);
    }

    function overridePosition() {
      return visibleColumns.findIndex((v) => v === col.name);
    }
  };

  return (
    <ExplorerLeftTray
      cy="column-visibility-list"
      title="Show / Hide"
      handleClose={close}
    >
      <Flex>
        <SimpleTooltip
          label={
            <span>
              <span style={{ lineHeight: "2em" }}>
                <b>Start fresh!</b>
              </span>{" "}
              <br />
              Build your explore your data <br />
              from the ground up.
            </span>
          }
        >
          <Blank onClick={clearAll} style={{ marginRight: 30 }}>
            <FontAwesomeIcon icon={["fal", "cube"]} />
            <span>Clear Columns</span>
          </Blank>
        </SimpleTooltip>
        <SimpleTooltip
          label={
            <span>
              <span style={{ lineHeight: "2em" }}>
                <b>See it all</b>
              </span>{" "}
              <br />
              Start with your complete
              <br />
              data source and refine
            </span>
          }
        >
          <Blank onClick={reset}>
            <FontAwesomeIcon icon={["fal", "cubes"]} />
            Full Query
          </Blank>
        </SimpleTooltip>
      </Flex>
      <Instructions hr>Check columns to show</Instructions>
      <ListContainer>
        {columns.map((column) => (
          <Column
            data-cy="selection-column-item"
            key={column.name}
            onClick={() => handleChange(column)}
          >
            <FontAwesomeIcon
              icon={
                visibleColumns.find((col) => col === column.name)
                  ? ["fas", "eye"]
                  : ["fal", "eye-slash"]
              }
            />
            <span>{column.mapping.displayName}</span>
          </Column>
        ))}
      </ListContainer>
    </ExplorerLeftTray>
  );
}

ColumnSelection.propTypes = {
  columns: PropTypes.array,
};
