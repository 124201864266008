import * as actionTypes from "./actionTypes";
import queryBuilder from "./queryBuilder/queryBuilder";
import * as queryString from "qs";

export const loadQueryMonitoringList = () => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_LIST_START,
    meta: {
      api: {
        method: "GET",
        endpoint: "api/v1/monitoring/reports",
      },
    },
  });
};

export const createQueryMonitoring = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_CREATE_START,
    meta: {
      api: {
        method: "POST",
        endpoint: "api/v1/monitoring/reports",
        payload: data,
      },
    },
  });
};

export const deleteQueryMonitoring = (uuid) => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_DELETE_SALESFORCE_REPORT_START,
    meta: {
      api: {
        method: "DELETE",
        endpoint: "api/v1/monitoring/reports/" + uuid,
        payload: {
          uuid: uuid,
        },
      },
    },
  });
};

export const getSalesforceQueryReports = (connectionUuid) => (dispatch) => {
  dispatch({
    type: actionTypes.MONITORING_QUERY_GET_SALESFORCE_REPORTS_START,
    meta: {
      api: {
        method: "GET",
        endpoint:
          "api/v1/monitoring/reports/get_salesforce_reports/" + connectionUuid,
      },
    },
  });
};

export const getSalesforceReportColumns =
  (connectionUuid, reportId) => (dispatch) => {
    dispatch({
      type: actionTypes.MONITORING_QUERY_GET_SALESFORCE_REPORT_COLUMNS_START,
      meta: {
        api: {
          method: "GET",
          endpoint:
            "api/v1/monitoring/reports/get_salesforce_report_columns/" +
            connectionUuid +
            "/" +
            reportId,
        },
      },
    });
  };

export const executeCharts =
  (charts, loadingType) => async (dispatch, getState) => {
    const [query, ...remaining] = charts;
    const qs = queryBuilder(query, getState, false, "chart");

    const api = {
      public: false,
      method: query.convertGetToPost ? "POST" : "GET",
      endpoint: `api/v1/queries/${query["queryId"]}/exec?${
        query.convertGetToPost ? "" : qs
      }`,
      ...(query.convertGetToPost && {
        payload: queryString.parse(qs, { depth: 10 }), // for the nested array parsing we need set depth as option
      }),
    };

    await dispatch({
      type: actionTypes.CHARTS_MONITORING_START,
      meta: {
        api: api,
      },
      visualizationId: query.visualizationId,
      isLast: !remaining.length,
      loadingType,
    });

    if (remaining.length) {
      dispatch(executeCharts(remaining, loadingType));
    }
  };
