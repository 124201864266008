import React from "react";
import { scaleLinear, scaleTime, scaleBand, scaleOrdinal } from "d3-scale";
import { extent } from "d3-array";
import PositionCirclesPlaced from "../PositionCircle/PositionCirclesPlaced";
import XAxis from "../Axes/XAxis";
import YAxis from "../Axes/YAxis";
import { useTheme } from "emotion-theming";
import SpringLine from "./SpringLine";
import { linear } from "regression";
import { schemeTableau10 } from "d3-scale-chromatic";
import { getMappingType } from "../../utils/getVisualizationLabel";
import { unique } from "../../utils/func";
import QuarterShade from "./QuarterShade";
import FancyWeekScale from "../Axes/FancyWeekScale";
import timeSeriesValues, {
  getSortedXAxisValues,
  months,
} from "./timeSeriesValues";
import numberTicks from "./numberTicks";
import { percentFormats } from "../../utils/constants/constants";

export const TREND_LINE_COLOR = "orange";

export default function MultipleLineChart({
  width,
  height,
  margin,
  setTooltip,
  colors,
  xKeyFormat,
  lineGroups,
  meta,
  legendItems,
  trendLine,
  xKey,
  valueKeys,
  yAxisFormat,
  yAxisPad = 0,
  showCircles,
  defaultColors,
  lineLabelFormat,
  lineLabelPrecision,
  lineLabelUnit,
  relativeY,
  xAxisDate,
  bands,
  yRightAxisFormat,
  yRightAxis,
  showLabel,
  yearOverYear,
  yAxisTypeLabel,
  yRightAxisTypeLabel,
  showQuarterShade,
  activeValueKey,
  xKeyDomain,
  formatBands,
  dateParserStyle,
  tooltipForceType,
  QO,
  tooltipDateFormat,
  fiscalQuarterStartOffset,
  fixedYTickStep,
  labelFormat,
  forceRotateLabels,
  showFancyWeeks,
  term,
  xAxisTypeLabel,
  xSortSettings,
  data,
  xAxisHeightOffset,
  yClampedRange,
}) {
  const theme = useTheme();
  const allValues = timeSeriesValues(lineGroups, yearOverYear);

  const customDomain = months(xKeyDomain);
  const dateExtent = extent(allValues.dates);

  const adjustedTickCount = numberTicks(width, allValues.dates, bands);
  const isFancyWeeks = showFancyWeeks ?? term === "weekly";
  const parserStyle = dateParserStyle ?? term;
  const format = () =>
    yAxisFormat || getMappingType(meta?.fields, activeValueKey || valueKeys[0]);
  const isPercentFormat = () =>
    percentFormats.includes(format()) || yAxisFormat?.includes("%");

  const sorted = getSortedXAxisValues(
    xSortSettings,
    allValues.dates,
    data,
    xKey
  );

  const x = bands
    ? scaleBand()
        .domain(customDomain || unique(sorted))
        .range([0, width])
    : scaleTime()
        .domain(dateExtent)
        .range([0, width - 3]);

  function setFullRange(values) {
    const boundaryExpander = isPercentFormat() ? 0.02 : 2;
    const rangeSpread = values[1] - values[0];
    const padAmount = rangeSpread * yAxisPad;
    values[0] = values[0] - padAmount;
    values[1] = values[1] + padAmount;

    return [
      values[0] < 0 || relativeY ? values[0] - boundaryExpander : 0,
      values[1] + (relativeY ? boundaryExpander : 0),
    ];
  }

  const yDomain = setFullRange(extent(allValues.values));
  const yRightDomain = setFullRange(
    extent(allValues.rightAxisValues.flat(Infinity))
  );

  const ry = scaleLinear()
    .domain(yRightDomain)
    .range([height - 20, 0]);

  const clampedDomain = () => {
    if (!yClampedRange) {
      return yDomain;
    }

    return [
      Math.min(...allValues.values) - yClampedRange.min,
      Math.max(...allValues.values) - yClampedRange.max,
    ];
  };

  const y = scaleLinear()
    .domain(clampedDomain())
    .range([height - 20, 0]);

  const allPositions = lineGroups.length
    ? lineGroups[0].values.map((v) => {
        return [x(yearOverYear ? v.scaleDate : v.xValue), y(v.value)];
      })
    : [];

  const coefficients = linear(allPositions);

  const dColors = scaleOrdinal(schemeTableau10);
  const getColor = (section, i) => {
    const matched = legendItems
      ? legendItems.find((li) => li.name === section.key)
      : null;
    return matched ? matched.color : defaultColors ? dColors(i) : colors[i];
  };

  const isRightAxis = (section) => {
    return section.rightAxisValues?.length;
  };

  // now we start making the lines
  return (
    <g data-cy="multiple-lines">
      {showQuarterShade ? (
        <QuarterShade height={height - 20} width={width} />
      ) : null}
      <line x1={0} x2={width} y1={0} y2={0} style={{ stroke: theme.divider }} />
      {isFancyWeeks ? (
        <FancyWeekScale height={height} width={width} margin={margin} />
      ) : (
        <XAxis
          xScale={x}
          xKeyFormat={xKeyFormat}
          margin={margin}
          height={height - 20}
          width={width}
          isLinear={!bands}
          numTicks={adjustedTickCount}
          hideXAxisLine
          xAxisDate={xAxisDate}
          values={allValues.values}
          formatBands={formatBands}
          bands={bands}
          dateParserStyle={parserStyle}
          fiscalQuarterStartOffset={fiscalQuarterStartOffset}
          forceRotateLabels={forceRotateLabels}
          xAxisTypeLabel={xAxisTypeLabel}
          xAxisHeightOffset={xAxisHeightOffset}
        />
      )}
      <YAxis
        yScale={y}
        yAxisGridColor={theme.divider}
        yAxisGrid
        yTicksCount={6}
        width={width}
        hideYAxisLine
        yAxisFormat={
          yAxisFormat ||
          getMappingType(meta?.fields, activeValueKey || valueKeys[0])
        }
        yAxisTypeLabel={yAxisTypeLabel}
        domain={fixedYTickStep ? yDomain : null}
      />
      {yRightAxis && (
        <YAxis
          yScale={ry}
          translateAxis={width + 10}
          width={0}
          yAxisGridColor={theme.divider}
          yAxisGrid
          yTicksCount={6}
          hideYAxisLine
          yAxisFormat={yRightAxisFormat}
          orient="right"
          domain={yRightDomain}
          yAxisTypeLabel={yRightAxisTypeLabel}
          translateRightLabel={width + 40}
        />
      )}
      {lineGroups.map((section, i) => (
        <g data-cy="multiple-lines-line" key={i}>
          <SpringLine
            width={width}
            values={
              isRightAxis(section) ? section.rightAxisValues : section.values
            }
            stroke={getColor(section, i)}
            style={section.dashed ? { strokeDasharray: "5, 8" } : {}}
            curved={section.curved}
            strokeWidth={1.5}
            fill="none"
            x={x}
            y={isRightAxis(section) ? ry : y}
            bandwidth={bands ? x.bandwidth() / 2 : 0}
            xOffset={showLabel ? 15 : 0}
          />
          {showCircles ? (
            <g data-cy="circles">
              <PositionCirclesPlaced
                data={
                  isRightAxis(section)
                    ? section.rightAxisValues
                    : section.values
                }
                markerKey="insight"
                yScale={isRightAxis(section) ? ry : y}
                xScale={x}
                xKey="xValue"
                yKey="value"
                xLabel={xKey}
                yLabel={
                  QO
                    ? QO.sourceKey(valueKeys[i] || valueKeys[0])
                    : valueKeys[i] || valueKeys[0]
                }
                segment={section.key}
                color={getColor(section, i)}
                setTooltip={setTooltip}
                borderSize={0.5}
                radius={3}
                meta={meta}
                lineLabelFormat={lineLabelFormat}
                lineLabelPrecision={lineLabelPrecision}
                lineLabelUnit={lineLabelUnit}
                bandwidth={bands ? x.bandwidth() / 2 : 0}
                showLabel={showLabel}
                lineBands={bands}
                activeValueKey={activeValueKey}
                noDate={!!showFancyWeeks}
                tooltipForceType={tooltipForceType}
                tooltipDateFormat={tooltipDateFormat ?? term}
                fiscalQuarterStartOffset={fiscalQuarterStartOffset}
                xOffset={showLabel ? 15 : 0}
                labelFormat={labelFormat}
              />
            </g>
          ) : null}
        </g>
      ))}
      {trendLine
        ? coefficients.points.map((c, i) => (
            <circle key={i} r={2} cx={c[0]} cy={c[1]} fill={TREND_LINE_COLOR} />
          ))
        : null}
    </g>
  );
}
