import styled from "@emotion/styled";
import Select from "../../../../UI/Form/Select/Select";
import Switcher from "../../../../UI/Switcher/Switcher";

const OrderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.notification.errorMain};
  }
`;

export default function Order({
  overrides,
  changeOrderColumn,
  order,
  index,
  setChartState,
  chart,
}) {
  return (
    <>
      <Select
        options={overrides}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        label="Column"
        value={overrides.find((override) => override.name === order.name)}
        onChange={(o) => changeOrderColumn(o, index, "name")}
      />
      <br />
      <OrderActions>
        <Switcher
          leftLabel="ASC"
          rightLabel="DESC"
          checked={order.sort.toLowerCase() === "desc"}
          handleSwitchChange={() =>
            changeOrderColumn(
              {
                name: order.sort.toLowerCase() === "desc" ? "ASC" : "DESC",
              },
              index,
              "sort"
            )
          }
        />
        <span
          onClick={() =>
            setChartState({
              ...chart,
              orders: chart.orders.filter((_, i) => i !== index),
            })
          }
        >
          Remove order
        </span>
      </OrderActions>
      <br />
    </>
  );
}
